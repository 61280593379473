import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Icon, Select, Text } from '@chakra-ui/react';
import { FiChevronRight } from 'react-icons/fi';
import {
  SETTINGS_PEFERENCES_SELECT_VALUES,
  SETTINGS_PREFERENCES_BUTTON_VALUES,
} from 'assets/constants/dashboard';
import styles from './styles.module.css';

const SettingsPreferences = () => {
  const navigate = useNavigate();
  const handleClickSupport = () => {
    navigate('/dashboard/settings/support');
  };
  return (
    <Flex flexDir="column" gap="1rem">
      <Text marginBottom="4px" color="text.neutral">
        Preferences
      </Text>
      {SETTINGS_PEFERENCES_SELECT_VALUES?.map((item) => (
        <Flex
          key={item?.id}
          className={styles.select_popup}
          background="bg.primary"
        >
          <Icon color="text.logo" as={item?.icon} />
          <Box flex="1" marginLeft="20px" color="text.basic" fontWeight="600">
            {item?.name}
          </Box>
          <Select
            icon={<FiChevronRight />}
            iconSize="16px"
            iconColor="text.basic"
            maxW="max-content"
            border="none"
            _focusVisible={{ border: 'none', boxShadow: 'none' }}
            color="text.neutral"
            cursor="pointer"
          >
            {item?.options?.map((option) => (
              <option key={option?.id} value={option?.value}>
                {option?.text}
              </option>
            ))}
          </Select>
        </Flex>
      ))}
      {SETTINGS_PREFERENCES_BUTTON_VALUES?.map((item) => (
        <Flex
          key={item?.id}
          background="bg.primary"
          className={styles.button_redirect}
          onClick={handleClickSupport}
          cursor="pointer"
        >
          <Icon color="text.logo" as={item?.icon} />
          <Box flex="1" marginLeft="20px" color="text.basic" fontWeight="600">
            {item?.name}
          </Box>
          <Icon color="text.basic" size="16px" as={FiChevronRight} />
        </Flex>
      ))}
    </Flex>
  );
};

export default SettingsPreferences;
