import React, { useContext, useEffect, useRef, useState } from 'react';
import { Wrap } from '@chakra-ui/react';

import Livestock from 'components/atoms/Icons/Livestock';
import ModalContentButton from 'components/Modal/ModalContentButton';
import ModalContentLayout from 'components/Modal/ModalContentLayout';

import { NewCustomizationContext } from 'context/newCustomization';
import { getAllLivestockCategories } from 'services/Livestock';

import type { LivestockCategories } from 'interfaces/livestocks';

const HEADING = 'What type of stock would you like to view?';
const PROGRESS_BAR_VALUE = 45;

const Stocks = () => {
  const [stocks, setStocks] = useState([]);
  const newCustomizationContext = useContext(NewCustomizationContext);
  const { isEditCustomization, editStock } = newCustomizationContext?.state;
  const ref = useRef<HTMLButtonElement>(null);

  const getStockCategories = async () => {
    const categories = await getAllLivestockCategories();
    setStocks(categories);
  };
  useEffect(() => {
    getStockCategories();

    // Useful for validating edit values when the user clicks back button without selecting new value
    // then the previously edit value should still remain and not revert back to original value
    if (isEditCustomization) {
      newCustomizationContext?.dispatch({
        type: 'UPDATE_CURRENT_EDITED_VALUE',
        payload: editStock?.category_name,
      });
    }
  }, []);

  useEffect(() => {
    if (isEditCustomization) {
      ref?.current?.focus();
    }
  }, [isEditCustomization, stocks]);

  return (
    <ModalContentLayout
      heading={HEADING}
      progressValue={PROGRESS_BAR_VALUE}
      icon={Livestock}
    >
      <Wrap
        justify="center"
        alignItems="center"
        spacing="1rem"
        maxW="80%"
        margin="auto"
      >
        {stocks?.map((item: LivestockCategories) => (
          <ModalContentButton
            key={item.id}
            maxWidth="260px"
            ref={item.id === editStock.category ? ref : undefined}
            handleClick={() =>
              newCustomizationContext?.dispatch({
                type: isEditCustomization ? 'EDIT_STOCKS' : 'STOCKS',
                payload: { id: item.id, name: item.name },
              })
            }
          >
            {item.name}
          </ModalContentButton>
        ))}
      </Wrap>
    </ModalContentLayout>
  );
};

export default Stocks;
