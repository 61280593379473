import { Flex, Heading, Box, Text } from '@chakra-ui/react';

const LegalSeverability = () => {
  return (
    <Flex flexDir="column" gap={10}>
      <Box>
        <Heading marginY="32px">Severability and Waiver</Heading>
        <Heading size="lg">Severability</Heading>
        <Text marginTop="16px">
          If any provision of these Terms is held to be unenforceable or
          invalid, such provision will be changed and interpreted to accomplish
          the objectives of such provision to the greatest extent possible under
          applicable law and the remaining provisions will continue in full
          force and effect.
        </Text>
      </Box>
      <Box>
        <Heading size="lg">Waiver</Heading>
        <Text marginY="16px">
          Except as provided herein, the failure to exercise a right or to
          require performance of an obligation under these Terms shall not
          effect a party's ability to exercise such right or require such
          performance at any time thereafter nor shall the waiver of a breach
          constitute a waiver of any subsequent breach.
        </Text>
      </Box>
    </Flex>
  );
};

export default LegalSeverability;
