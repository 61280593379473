import { instance } from 'http/axios';
import { SupportType } from 'interfaces/dashboard';

const SUPPORT_ENDPOINT = '/core/support/';

export const addSupportTicket = async (data: SupportType) => {
  try {
    const res = await instance.post(SUPPORT_ENDPOINT, data);
    return res;
  } catch (error: any) {
    return error;
  }
};
