import React, { useEffect } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Textarea,
  Stack,
  useMediaQuery,
  useBoolean,
} from '@chakra-ui/react';
import { FiChevronLeft } from 'react-icons/fi';
import { SubmitHandler, useForm } from 'react-hook-form';
import { supportSchema } from 'schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { addSupportTicket } from 'services/Support';
import { SupportType } from 'interfaces/dashboard';
import { useNavigate } from 'react-router-dom';

const SUPPORT_DEFAULT_VALUES = {
  email: '',
  body: '',
};

interface SendSupportFormProps {
  handleOpenMessage: () => void;
}

const SendSupportForm = ({ handleOpenMessage }: SendSupportFormProps) => {
  const navigate = useNavigate();
  const [isLargerThan992] = useMediaQuery('(min-width: 992px)');
  const [isLoading, setIsLoading] = useBoolean();
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful },
  } = useForm<typeof SUPPORT_DEFAULT_VALUES>({
    mode: 'onTouched',
    defaultValues: SUPPORT_DEFAULT_VALUES,
    resolver: yupResolver(supportSchema),
    reValidateMode: 'onSubmit',
  });

  // Form Submit Handler
  const onSubmit: SubmitHandler<typeof SUPPORT_DEFAULT_VALUES> = async (
    data: SupportType
  ) => {
    try {
      setIsLoading.on();
      const response = await addSupportTicket(data);
      if (response.status === 200) {
        handleOpenMessage();
      }
      setIsLoading.off();
    } catch (error) {
      setIsLoading.off();
      return error;
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(SUPPORT_DEFAULT_VALUES);
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <>
      <Flex
        flexDir="column"
        bgColor="bg.primary"
        borderRadius="13px"
        padding="32px"
        marginTop="32px"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            <FormControl isRequired>
              <FormLabel color="text.neutral" fontSize="14px">
                Email Address
              </FormLabel>
              <Input
                type="email"
                placeholder="e.g. johndoe@gmail.com"
                {...register('email')}
              />
            </FormControl>
          </Stack>
          <Stack marginTop="16px">
            <FormControl isRequired>
              <FormLabel color="text.neutral" fontSize="14px">
                Ask us a question
              </FormLabel>
              <Textarea
                minH="20vh"
                placeholder="Type here..."
                resize="none"
                {...register('body')}
              />
            </FormControl>
          </Stack>
          <Flex
            flexDirection={['column', null, null, 'row-reverse', null]}
            justifyContent={[null, null, null, 'space-between', null]}
          >
            <Button
              isLoading={isLoading}
              type="submit"
              alignSelf="flex-end"
              color="text.white"
              bgColor="brand.primary"
              height="54px"
              borderRadius="12px"
              marginTop="16px"
              width="100%"
              maxW={isLargerThan992 ? '220px' : undefined}
            >
              Send Message
            </Button>
            <Button
              leftIcon={isLargerThan992 ? <FiChevronLeft /> : undefined}
              variant="ghost"
              type="button"
              alignSelf="flex-end"
              color="#000"
              width="100%"
              maxW={isLargerThan992 ? '100px' : undefined}
              height="54px"
              borderRadius="12px"
              marginTop="16px"
              onClick={handleBack}
            >
              Back
            </Button>
          </Flex>
        </form>
      </Flex>
    </>
  );
};

export default SendSupportForm;
