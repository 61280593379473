import { Heading, Stack, Box, Text } from '@chakra-ui/react';

const LegalChildrensPolicy = () => {
  return (
    <Box>
      <Heading>Children's Privacy</Heading>
      <Stack marginTop="32px" spacing={5}>
        <Text>
          Our Service does not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 13. If You are a parent or guardian and You are aware
          that Your child has provided Us with Personal Data, please contact Us.
          If We become aware that We have collected Personal Data from anyone
          under the age of 13 without verification of parental consent, We take
          steps to remove that information from Our servers.
        </Text>
        <Text>
          If We need to rely on consent as a legal basis for processing Your
          information and Your country requires consent from a parent, We may
          require Your parent's consent before We collect and use that
          information.
        </Text>
      </Stack>
    </Box>
  );
};

export default LegalChildrensPolicy;
