import { useState, useEffect, useContext } from 'react';
import { LivestockAgeClass } from 'interfaces/livestocks';
import { getFilteredLivestockAgeClass } from 'services/Livestock';
import { NewCustomizationContext } from 'context/newCustomization';

const useIsShowAgeClass = (stockTypeId: number) => {
  const customizationContext = useContext(NewCustomizationContext);
  const [ageClassLength, setAgeClassLength] = useState<LivestockAgeClass[]>([]);

  const getFilteredAgeClass = async () => {
    
    const types = await getFilteredLivestockAgeClass(stockTypeId);
    setAgeClassLength(types);
  };

  useEffect(() => {
    getFilteredAgeClass();
  }, [stockTypeId]);

  return ageClassLength?.length !== 1;
};

export default useIsShowAgeClass;
