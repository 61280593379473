import React, { useState, useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  ModalCloseButton,
  FormControl,
  Text,
  FormLabel,
  InputGroup,
  Input,
  InputRightElement,
  FormErrorMessage,
} from '@chakra-ui/react';
import Alert from 'components/atoms/Alert';

import {
  CHANGE_PASSWORD_VALUES,
  CHANGE_PASSWORD_INPUTS,
} from 'assets/constants/changepassword';
import { AuthContext } from 'context/auth';
import { changePasswordSchema } from 'schema';
import { errorMessageChecker } from 'utils';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import type { PasswordModalProps } from 'components/Modal/PasswordModal';

export type PasswordChangeSettingsType = {
  old_password: string;
  new_password1: string;
  new_password2: string;
};

const PasswordChangeForm = ({ setIsOpenPassword }: PasswordModalProps) => {
  const [showPassword, setShowPassword] = useState<{ [key: string]: boolean }>(
    {}
  );
  const authContext = useContext(AuthContext);

  const handleShowPassword = (name: string) => {
    setShowPassword({
      ...showPassword,
      [name]: !showPassword[name],
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<typeof CHANGE_PASSWORD_VALUES>({
    mode: 'onTouched',
    defaultValues: CHANGE_PASSWORD_VALUES,
    resolver: yupResolver(changePasswordSchema),
    reValidateMode: 'onSubmit',
  });

  const handlePasswordChange: SubmitHandler<
    PasswordChangeSettingsType
  > = async (data) => {
    try {
      const response: any = await authContext?.onUpdatePasswordChangeSettings(
        data
      );
      if (response?.status === 200) {
        setIsOpenPassword(false);
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(handlePasswordChange)}>
        <ModalHeader
          fontSize="24px"
          fontWeight="700"
          marginTop="1rem"
          textAlign="center"
          color="text.basic"
        >
          Password
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Only render the Alert if there are non_field_errors */}
          {authContext?.error?.non_field_errors?.length > 0 && (
            <Alert status="error">
              {authContext?.error?.non_field_errors?.map(
                (err: string, i: number) => (
                  <Text key={i} fontSize="sm">
                    {err}
                  </Text>
                )
              )}
            </Alert>
          )}
          {CHANGE_PASSWORD_INPUTS?.map((item) => (
            <FormControl
              key={item?.id}
              isInvalid={
                errorMessageChecker(item?.name, errors)?.title !== undefined ||
                (authContext?.error !== null &&
                  authContext?.error?.[item?.name])
              }
              isRequired
              m="2px"
            >
              <FormLabel
                fontSize="12px"
                color="text.neutral"
                htmlFor={item?.name}
              >
                {item?.label}
              </FormLabel>
              <InputGroup>
                <Input
                  {...register(item?.name)}
                  type={showPassword[item?.name] ? 'text' : item?.type}
                  placeholder={item?.placeholder}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => handleShowPassword(item?.name)}
                    background="transparent"
                    color="#80818B"
                  >
                    {showPassword[item?.name] ? <FiEye /> : <FiEyeOff />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {errorMessageChecker(item?.name, errors)?.title !== undefined && (
                <FormErrorMessage>
                  {errorMessageChecker(item?.name, errors)?.msg}
                </FormErrorMessage>
              )}
              {authContext?.error &&
                authContext?.error?.[item?.name]?.map(
                  (err: string, i: number) => (
                    <FormErrorMessage key={i + 1}>{err}</FormErrorMessage>
                  )
                )}
            </FormControl>
          ))}
        </ModalBody>
        <ModalFooter flexDirection="column">
          {/* Apply Changes Button & Cancel Button here */}
          <Button
            type="submit"
            variant="solid"
            colorScheme="teal"
            size="md"
            w="334px"
            p="24px"
            mr={3}
            mt={6}
            isLoading={authContext?.isLoading}
          >
            Apply Changes
          </Button>
          <Button
            color="text.basic"
            variant="link"
            onClick={() => setIsOpenPassword(false)}
            mt={6}
          >
            Cancel
          </Button>
        </ModalFooter>
      </form>
    </>
  );
};

export default PasswordChangeForm;
