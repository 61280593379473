import React, { PropsWithChildren } from 'react';
import { Flex, Box, Image } from '@chakra-ui/react';
import Background from 'assets/images/auth-bg.png';
import Logo from 'assets/images/logo.png';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@chakra-ui/react';
import HomeFooter from 'components/molecules/HomeFooter';

const AuthLayout = ({ children }: PropsWithChildren) => {
  const [isLargerThan992] = useMediaQuery('(min-width: 992px)');

  return (
    <Flex justifyContent="flex-start" alignItems="center" h="100vh">
      {isLargerThan992 && (
        <Box
          flex="1"
          h="100%"
          backgroundImage={Background}
          bgSize="cover"
        ></Box>
      )}
      <Flex
        flexDirection="column"
        flex="1"
        h="100%"
        alignItems="center"
        justifyContent="center"
        paddingBlock="40px"
        backgroundImage={isLargerThan992 ? '' : Background}
        bgSize="cover"
      >
        <Link to="/">
          <Image src={Logo} maxW="79px" maxH="40px" />
        </Link>

        <Flex
          flex="1"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gap="1.5rem"
        >
          {children}
        </Flex>

        <HomeFooter />
      </Flex>
    </Flex>
  );
};

export default AuthLayout;
