import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import Alert from 'components/atoms/Alert';

import {
  NAME_INPUTS,
  REG_DEFAULT_VALUES,
  REG_INPUTS,
} from 'assets/constants/register';
import { registerSchema } from 'schema';
import { AuthContext } from 'context/auth';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { errorMessageChecker } from 'utils';
import { PRIVACY_ROUTE, TERMS_ROUTE } from 'assets/constants/legals';

const RegisterForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const authContext = useContext(AuthContext);

  const handleShowPassword = () => setShowPassword(!showPassword);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<typeof REG_DEFAULT_VALUES>({
    mode: 'onTouched',
    defaultValues: REG_DEFAULT_VALUES,
    resolver: yupResolver(registerSchema),
    reValidateMode: 'onSubmit',
  });

  // Submit Handler
  const onSubmit: SubmitHandler<typeof REG_DEFAULT_VALUES> = (data) => {
    authContext?.onRegister(data, navigate);
  };

  // Checkbox Handler
  const handleCheckbox = () => setIsChecked(!isChecked);

  const handleClickPrivacy = () => {
    navigate(PRIVACY_ROUTE);
  };
  const handleClickTerms = () => {
    navigate(TERMS_ROUTE);
  };

  return (
    <>
      {authContext?.error?.non_field_errors && (
        <Alert status="error">
          {authContext?.error?.non_field_errors?.map(
            (err: string, i: number) => (
              <Text key={i} fontSize="sm">
                {err}
              </Text>
            )
          )}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex>
          {NAME_INPUTS?.map((item) => (
            <FormControl
              key={item.id}
              isInvalid={
                errorMessageChecker(item.name, errors)?.title !== undefined ||
                (authContext?.error !== null && authContext?.error?.[item.name])
              }
              isRequired
              m="2px"
            >
              <FormLabel
                fontSize="12px"
                color="text.neutral"
                htmlFor={item.name}
              >
                {item.label}
              </FormLabel>
              <Input
                {...register(item.name)}
                type={item.type}
                placeholder={item.placeholder}
              />
              {errorMessageChecker(item.name, errors)?.title !== undefined && (
                <FormErrorMessage>
                  {errorMessageChecker(item.name, errors)?.msg}
                </FormErrorMessage>
              )}
              {authContext?.error &&
                authContext?.error?.[item.name]?.map(
                  (err: string, i: number) => (
                    <FormErrorMessage key={i + 1}>{err}</FormErrorMessage>
                  )
                )}
            </FormControl>
          ))}
        </Flex>
        {REG_INPUTS.map((item) => (
          <FormControl
            key={item.id}
            isInvalid={
              errorMessageChecker(item.name, errors)?.title !== undefined ||
              (authContext?.error !== null && authContext?.error?.[item.name])
            }
            isRequired
            m="2px"
          >
            <FormLabel fontSize="12px" color="text.neutral" htmlFor={item.name}>
              {item.label}
            </FormLabel>
            {item.type === 'password' ? (
              <InputGroup>
                <Input
                  {...register(item.name)}
                  type={showPassword ? 'text' : item.type}
                  placeholder={item.placeholder}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={handleShowPassword}
                    background="transparent"
                    color="#80818B"
                  >
                    {showPassword ? <FiEyeOff /> : <FiEye />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            ) : (
              <Input
                {...register(item.name)}
                type={item.type}
                placeholder={item.placeholder}
              />
            )}
            {errorMessageChecker(item.name, errors)?.title !== undefined && (
              <FormErrorMessage>
                {errorMessageChecker(item.name, errors)?.msg}
              </FormErrorMessage>
            )}
            {authContext?.error &&
              authContext?.error?.[item.name]?.map((err: string, i: number) => (
                <FormErrorMessage key={i + 1}>{err}</FormErrorMessage>
              ))}
          </FormControl>
        ))}
        <Box fontSize="14px">
          <Checkbox
            size="sm"
            color="text.neutral"
            onChange={handleCheckbox}
            isChecked={isChecked}
          >
            By clicking this I accept the{' '}
            <Text
              display="inline"
              fontWeight="bold"
              color="text.basic"
              onClick={handleClickTerms}
            >
              Terms & Conditions
            </Text>{' '}
            and{' '}
            <Text
              display="inline"
              fontWeight="bold"
              color="text.basic"
              onClick={handleClickPrivacy}
            >
              Privacy Policy
            </Text>
          </Checkbox>
        </Box>
        <Button
          type="submit"
          variant="solid"
          colorScheme="teal"
          size="md"
          w="100%"
          mt="40px"
          isLoading={authContext?.isLoading}
          isDisabled={!isChecked}
        >
          Create an account
        </Button>
      </form>
    </>
  );
};

export default RegisterForm;
