import { useState } from 'react';
import { ModalInitialValuesType } from 'interfaces/modal';
import { MODAL_INITIAL_VALUES } from 'assets/constants/modal';

const useModal = (values: ModalInitialValuesType = MODAL_INITIAL_VALUES) => {
  const [modalValues, setModalValues] =
    useState<ModalInitialValuesType>(values);

  const updateModalValues = (values: ModalInitialValuesType) => {
    setModalValues(values);
  };

  return { modalValues, setModalValues, updateModalValues };
};

export default useModal;
