import React, { useState, useContext } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Heading,
  Button,
  Text,
  FormControl,
  FormLabel,
  Input,
  Box,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';

import AuthLayout from 'components/AuthLayout';

import {
  RESET_PASSWORD_VALUES,
  RESET_PASSWORD_INPUTS,
} from 'assets/constants/resetpassword';

import { resetPasswordSchema } from 'schema';
import { AuthContext } from 'context/auth';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { errorMessageChecker } from 'utils';

export type ResetPasswordValueType = {
  new_password1: string;
  new_password2: string;
};

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [isSuccessful, setIsSuccessful] = useState(false);
  const authContext = useContext(AuthContext);

  const handleShowPassword = (name: string) => {
    setShowPassword({
      ...showPassword,
      [name]: !showPassword[name],
    });
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<typeof RESET_PASSWORD_VALUES>({
    mode: 'onTouched',
    defaultValues: RESET_PASSWORD_VALUES,
    resolver: yupResolver(resetPasswordSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmitHandler: SubmitHandler<ResetPasswordValueType> = async (
    data
  ) => {
    const uid: any = searchParams.get('uid');
    const token: any = searchParams.get('token');
    try {
      const payload: any = {
        ...data,
        uid,
        token,
      };
      const response: any = await authContext?.onPasswordResetConfirm(payload);
      if (response?.status === 200) {
        setIsSuccessful(true);
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <AuthLayout>
      <Heading fontSize="48px" color="text.basic">
        {!isSuccessful ? 'Reset Password' : 'Password Changed!'}
      </Heading>
      <Box maxW="458px" textAlign="center" color="text.primary">
        <Text>
          {!isSuccessful
            ? 'Enter a new password below to reset your password.'
            : 'Password has been reset with the new password.'}
        </Text>
      </Box>
      {!isSuccessful ? (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          {RESET_PASSWORD_INPUTS?.map((item) => (
            <FormControl
              key={item?.id}
              isInvalid={
                errorMessageChecker(item?.name, errors)?.title !== undefined ||
                (authContext?.error !== null &&
                  authContext?.error?.[item?.name])
              }
              isRequired
              m="2px"
            >
              <FormLabel
                fontSize="12px"
                color="text.neutral"
                htmlFor={item?.name}
              >
                {item?.label}
              </FormLabel>
              <InputGroup>
                <Input
                  {...register(item?.name)}
                  type={showPassword[item?.name] ? 'text' : item?.type}
                  placeholder={item?.placeholder}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={() => handleShowPassword(item?.name)}
                    background="transparent"
                    color="#80818B"
                  >
                    {showPassword[item?.name] ? <FiEye /> : <FiEyeOff />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              {errorMessageChecker(item?.name, errors)?.title !== undefined && (
                <FormErrorMessage>
                  {errorMessageChecker(item?.name, errors)?.msg}
                </FormErrorMessage>
              )}
              {authContext?.error &&
                authContext?.error?.[item?.name]?.map(
                  (err: string, i: number) => (
                    <FormErrorMessage key={i + 1}>{err}</FormErrorMessage>
                  )
                )}
            </FormControl>
          ))}
          <Button
            type="submit"
            variant="solid"
            colorScheme="teal"
            size="md"
            w="100%"
            marginBlock="24px"
            isLoading={authContext?.isLoading}
          >
            Reset Password
          </Button>

          <Link to="/login">
            <Button variant="link" color="text.basic" w="100%">
              I just remembered my password
            </Button>
          </Link>
        </form>
      ) : (
        <Link to="/login">
          <Button variant="solid" colorScheme="teal" size="md" w="343px">
            Back to Login
          </Button>
        </Link>
      )}
    </AuthLayout>
  );
};

export default ResetPassword;
