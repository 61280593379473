import React, { useContext, useEffect, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import DashboardHome from 'components/Dashboard/DashboardHome';
import DashboardLayout from 'components/Dashboard/DashboardLayout';
import NewCalculationModal from 'components/Modal/NewCalculationModal';
import ModalContentDisplay from 'components/ModalContentDisplay';
import MaxCustomizationModal from 'components/Modal/MaxCustomizationModal';

import { NewCustomizationContext } from 'context/newCustomization';
import { AuthContext } from 'context/auth';
import { EDIT_STEP } from 'enums/customizations';
import { MODAL_INITIAL_VALUES } from 'assets/constants/modal';
import { renderModalContent } from 'utils';
import useEditCustomisation from 'hooks/useEditCustomisation';

const Dashboard = () => {
  const newCustomizationContext = useContext(NewCustomizationContext);
  const { isCustomisationMax } = newCustomizationContext?.state;
  const authContext = useContext(AuthContext);

  const isAuthenticated = authContext?.isAuthenticated;

  const [customizationsLength, setCustomizationsLength] = useState<number>(0);

  const {
    isOpen,
    onOpen,
    onClose,
    modalValues,
    setModalValues,
    updateModalValues,
    handleEditCustomization,
  } = useEditCustomisation();

  const {
    isOpen: isOpenMaxCustomizationModal,
    onOpen: onOpenMaxCustomizationModal,
    onClose: onCloseMaxCustomizationModal,
  } = useDisclosure();

  const handleAddCustomization = () => {
    onOpen();
    setModalValues(MODAL_INITIAL_VALUES);
    newCustomizationContext?.dispatch({
      type: 'NAME',
      payload: 'new customization',
    });
    newCustomizationContext?.dispatch({
      type: 'UPDATE_DISABLED',
      payload: true,
    });
  };

  const checkMaxCustomizationLength = () => {
    // Show prompt if number of customizations reach 12
    if (isCustomisationMax) {
      onOpenMaxCustomizationModal();
      return;
    }
    handleAddCustomization();
  };

  const getUserCustomizationsLength = (length: number) => {
    setCustomizationsLength(length);
  };

  useEffect(() => {
    const isCustomisationMax =
      customizationsLength !== 0 && customizationsLength >= 12;
    newCustomizationContext?.dispatch({
      type: 'UPDATE_IS_CUSTOMISATION_MAX',
      payload: isCustomisationMax,
    });
  }, [customizationsLength]);
  
  return (
    <>
      <DashboardLayout>
        <DashboardHome
          handleAddCustomization={handleAddCustomization}
          handleEditCustomization={handleEditCustomization}
          getUserCustomizationsLength={getUserCustomizationsLength}
          checkMaxCustomizationLength={checkMaxCustomizationLength}
        />
      </DashboardLayout>

      {/* MODALS */}
      <NewCalculationModal
        openModal={isOpen}
        closeModal={onClose}
        modalValues={modalValues}
        updateModalValues={updateModalValues}
      >
        <ModalContentDisplay
          steps={modalValues.steps}
          renderModalContent={renderModalContent}
        />
      </NewCalculationModal>
      <MaxCustomizationModal
        handleAddCustomization={handleAddCustomization}
        openModal={isOpenMaxCustomizationModal}
        closeModal={onCloseMaxCustomizationModal}
      />
    </>
  );
};

export default Dashboard;
