const NZ_DATE_LOCALE = 'en-GB';

export const SELECTION_HISTORY_TABLE_HEADERS = [
  'Status',
  'Date added',
  'Analysis period',
  'Role',
  // 'Purchase location', NOTE: Commented out for temporary removal of location
  'Stock',
  'Stock type',
  'Stock age',
  'Live Weight',
];

export const STATUS_COLOR = {
  red: '#FF5353',
  green: '#00AA9A',
  orange: '#FFA17A',
};

export const MONTH_HEADER = [
  'Oct',
  'Nov',
  'Dec',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
];

export const TABLE_ROWS = [
  'Normal Price Seasonality',
  'Long Term Relativity',
  'Derived Store Price',
  'Scaler -/+',
];
export const TABLE_ROWS_2 = [
  'Approximate Liveweight',
  'Dollar amount per head',
];
export const AVERAGE_SCHEDULE_PREVIOUS_5_YEARS = 5.43;
export const CURRENT_DATE = new Date().toLocaleDateString(NZ_DATE_LOCALE);
export const DEFAULT_NAME = 'Current season analysis - AgFirst Default';
export const TABLE_STYLES = {
  display: 'grid',
  gridTemplateColumns: '270px repeat(12, 125px)',
};
