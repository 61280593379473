import React from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import DashboardLayout from 'components/Dashboard/DashboardLayout';

import SettingsAccount from 'components/Dashboard/SettingsAccount';
import SettingsPreferences from 'components/Dashboard/SettingsPreferences';

const DashboardSettings = () => {
  return (
    <DashboardLayout>
      <Flex
        flexDirection="column"
        alignItems="center"
        minH="90vh"
        paddingBlock="60px"
        gap="2.5rem"
        backgroundColor="bg.neutral"
      >
        <Heading color="text.basic">Account Settings</Heading>
        <Flex flexDir="column" w="550px" gap="2.5rem">
          <SettingsAccount />
          <SettingsPreferences />
        </Flex>
      </Flex>
    </DashboardLayout>
  );
};

export default DashboardSettings;
