import React from 'react';

type ModalContentDisplayProps = {
  steps: number | string | undefined;
  renderModalContent: (steps: string | number | undefined) => JSX.Element;
};

const ModalContentDisplay = ({
  steps,
  renderModalContent,
}: ModalContentDisplayProps) => {
  return renderModalContent(steps);
};

export default ModalContentDisplay;
