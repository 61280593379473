import { SelectionHistoryActionTypes } from 'utils/types';
import { ISelectionHistoryContext } from 'interfaces/selection-history';
import { DEFAULT_NAME } from 'assets/constants/selection-history';

export const SELECTION_HISTORY_INITIAL_STATE: ISelectionHistoryContext = {
  isLoading: false,
  selectionHistory: {
    id: 0,
    user: 0,
    date: '',
    merchant: '',
    // location: '', NOTE: Commented out for temporary removal of location
    livestock: {
      id: 0,
      name: '',
      livestock_type: {
        id: 0,
        name: '',
        category: {
          id: 0,
          name: '',
          is_available: false,
        },
        is_available: false,
      },
      is_meat: false,
      is_available: false,
    },
    weight: '',
    indicator_schedule: 0,
    current_store_price: 0,
    average_store_price_percentage: 0,
    quartiles: {
      lower: 0,
      average: 0,
      upper: 0,
    },
    five_year_average_schedule: 0,
    analysis_period: '',
    name: '',
    forecast_average_schedule: 0,
    carcass_prices: 0,
    per_head: 0,
  },
};

export const selectionHistoryReducer = (
  state: any,
  action: SelectionHistoryActionTypes
) => {
  switch (action.type) {
    case 'SET_SELECTION_HISTORY_DETAILS':
      return {
        ...state,
        selectionHistory: {
          ...action.payload,
          name: DEFAULT_NAME,
          forecast_average_schedule: action.payload.five_year_average_schedule,
        },
      };
    case 'UPDATE_SELECTION_HISTORY':
      return { ...state, selectionHistory: action.payload };
    case 'UPDATE_SELECTION_HISTORY_CUSTOMISATION':
      return {
        ...state,
        selectionHistory: {
          ...state?.selectionHistory,
          merchant: action?.payload?.merchant,
          // location: action?.payload?.location, NOTE: Commented out for temporary removal of location
          livestock: action?.payload?.livestock,
          weight: action?.payload?.weight,
        },
      };
    case 'UPDATE_INDICATOR_SCHEDULE':
      return {
        ...state,
        selectionHistory: {
          ...state?.selectionHistory,
          indicator_schedule: action.payload,
        },
      };
    case 'UPDATE_EXPECTED_PRICE_PERCENTAGE':
      return {
        ...state,
        selectionHistory: {
          ...state?.selectionHistory,
          average_store_price_percentage: action.payload,
        },
      };
    case 'UPDATE_CARCASS_PRICES':
      return {
        ...state,
        selectionHistory: {
          ...state?.selectionHistory,
          carcass_prices: action.payload.carcass_prices,
          forecast_average_schedule: action.payload.forecastSchedule,
        },
      };
    case 'UPDATE_SELECTION_HISTORY_NAME':
      return {
        ...state,
        selectionHistory: {
          ...state?.selectionHistory,
          name: action.payload,
        },
      };
    case 'REMOVE_SELECTION_HISTORY':
      return { ...state, id: action.payload };
    case 'STOP_LOADING':
      return { ...state, isLoading: false };
  }
};
