import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Heading,
  Button,
  Text,
  FormControl,
  FormLabel,
  Input,
  Box,
  FormErrorMessage,
} from '@chakra-ui/react';

import AuthLayout from 'components/AuthLayout';

import { forgotPasswordSchema } from 'schema';
import { AuthContext } from 'context/auth';

import styles from './styles.module.css';

export type RecoverPasswordValueType = {
  email: string;
};

const ForgotPassword = () => {
  const [isSuccessful, setIsSuccessful] = useState(false);
  const authContext = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    defaultValues: { email: '' },
    resolver: yupResolver(forgotPasswordSchema),
    reValidateMode: 'onSubmit',
  });

  // Form Submit Handler
  const onSubmitHandler: SubmitHandler<RecoverPasswordValueType> = (data) => {
    authContext?.onSendRecoveryLink(data);
    setIsSuccessful(true);
  };

  return (
    <AuthLayout>
      <Heading fontSize="48px" color="text.basic">
        {!isSuccessful ? 'Forgot password?' : 'Recovery email sent'}
      </Heading>
      <Box maxW="458px" textAlign="center" color="text.primary">
        <Text>
          {!isSuccessful
            ? 'Don’t worry, it happens! Please enter your email address below to be sent a recovery link'
            : 'An email with a link to reset your password has been sent. Please check your inbox and follow the link'}
        </Text>
      </Box>
      {!isSuccessful ? (
        <form
          className={styles.forgot_form}
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <FormControl
            isRequired
            isInvalid={errors?.email !== undefined}
            mb="1rem"
          >
            <FormLabel fontSize="12px" color="text.neutral" htmlFor="email">
              Email address
            </FormLabel>
            <Input
              {...register('email')}
              id="email"
              type="email"
              placeholder="Type here"
            />
            {errors?.email && (
              <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
            )}
          </FormControl>
          <Button
            type="submit"
            variant="solid"
            colorScheme="teal"
            size="md"
            w="100%"
            marginBlock="24px"
          >
            Send recovery link
          </Button>

          <Link to="/login">
            <Button variant="link" color="text.basic" w="100%">
              I just remembered my password
            </Button>
          </Link>
        </form>
      ) : (
        <Link to="/login" className={styles.link}>
          <Button variant="solid" colorScheme="teal" size="md" w="343px">
            Back to Login
          </Button>
        </Link>
      )}
    </AuthLayout>
  );
};

export default ForgotPassword;
