import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import { Box } from '@chakra-ui/react';
import { Tr, Td } from '@chakra-ui/table';
import { FiInfo } from 'react-icons/fi';
import { format } from 'date-fns';
import { DATE_FORMAT_SHORT_YEAR } from 'assets/constants/dashboard';
import { ISelectionHistory } from 'interfaces/selection-history';
import {
  capitalizeFirstLetter,
  processAgeClassDisplayText,
  displayStatusColor,
} from 'utils';
import { SelectionHistoryContext } from 'context/selectionHistoryContext';
import { getSelectionHistoryById } from 'services/SelectionHistory';
import Tooltip from 'components/atoms/Tooltip';
import { NewCustomizationContext } from 'context/newCustomization';
import { DEFAULT_NAME } from 'assets/constants/selection-history';

type TableContentType = {
  selectionHistories: ISelectionHistory[];
};

const TableContent = ({ selectionHistories }: TableContentType) => {
  const selectionHistoryContext = useContext(SelectionHistoryContext);
  const newCustomizationContext = useContext(NewCustomizationContext);

  const navigate = useNavigate();

  const handleNavigateToView = async (id: number, analysis_period: string) => {
    const historyDetails = await getSelectionHistoryById(id, analysis_period);

    // Destructure props used for initializing the edit customization in context
    const {
      merchant,
      // location, NOTE: Commented out for temporary removal of location
      weight,
    } = historyDetails;
    const stock = historyDetails?.livestock?.livestock_type?.category;
    const stockType = historyDetails?.livestock?.livestock_type;
    const ageClass = historyDetails?.livestock;

    // Update the edit customization context?
    newCustomizationContext?.dispatch({
      type: 'UPDATE_EDIT_CUSTOMIZATION',
      payload: {
        name: DEFAULT_NAME,
        analysis_period: analysis_period,
        merchant: merchant,
        // location: location, NOTE: Commented out for temporary removal of location
        category: {
          id: stock?.id,
          name: stock?.name,
        },
        livestockType: {
          id: stockType?.id,
          name: stockType?.name,
        },
        ageClass: {
          id: ageClass?.id,
          name: ageClass?.name,
        },
        weight: weight,
      },
    });

    selectionHistoryContext?.dispatch({
      type: 'SET_SELECTION_HISTORY_DETAILS',
      payload: historyDetails,
    });
    if (historyDetails?.analysis_period === 'short') {
      navigate('/dashboard/selection-history/short-term');
    } else {
      navigate('/dashboard/selection-history/long-term');
    }
    return;
  };

  return (
    <>
      {selectionHistories?.map((history) => {
        return (
          <Tr
            key={history.id}
            onClick={() =>
              handleNavigateToView(history?.id, history?.analysis_period)
            }
            cursor="pointer"
          >
            <Td>
              <Box
                width="40px"
                height="10px"
                background={displayStatusColor(history)}
                borderRadius="14px"
              />
            </Td>
            <Td>{format(new Date(history?.date), DATE_FORMAT_SHORT_YEAR)}</Td>
            <Td>{capitalizeFirstLetter(history?.analysis_period)} Term</Td>
            <Td>{capitalizeFirstLetter(history?.merchant)}</Td>
            {
              // NOTE: Commented out for temporary removal of location
            }
            {/* <Td>{capitalizeFirstLetter(history?.location)} Island</Td> */}
            <Td>{history?.livestock?.livestock_type?.category?.name}</Td>
            <Td>
              {capitalizeFirstLetter(history?.livestock?.livestock_type?.name)}
            </Td>
            <Td>
              {processAgeClassDisplayText(
                history?.livestock?.name,
                history?.livestock?.livestock_type?.name
              )}
            </Td>
            <Td>{parseInt(history?.weight)}kg</Td>
          </Tr>
        );
      })}
    </>
  );
};

export default TableContent;
