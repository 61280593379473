import React, { useContext, useEffect, useState } from 'react';
import {
  Flex,
  Button,
  FormControl,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import useGetWeightRange from 'hooks/useGetWeightRange';
import { NewCustomizationContext } from 'context/newCustomization';
import Weight from 'components/atoms/Icons/Weight';
import ModalContentLayout from 'components/Modal/ModalContentLayout';
import Loader from 'components/atoms/Loader';

const HEADING = 'Please enter live weight stock in kilograms';
const SUPPORT_HEADING = 'Input or choose between min, avg and max weight';
const PROGRESS_BAR_VALUE = 100;

const LiveWeight = () => {
  const [isWeightInvalid, setIsWeightInvalid] = useBoolean(false);
  const newCustomizationContext = useContext(NewCustomizationContext);
  const { isEditCustomization, editCustomizations, customizations } =
    newCustomizationContext?.state;
  const [weight, setWeight] = useState('');
  const { data, isLoading } = useGetWeightRange(
    isEditCustomization
      ? editCustomizations?.livestock
      : customizations?.livestock
  );
  const MIN_WEIGHT = data?.[0]?.weight_min?.toString();
  const AVG_WEIGHT = data?.[0]?.weight_typical?.toString();
  const MAX_WEIGHT = data?.[0]?.weight_max?.toString();
  const rangeButtons = [
    {
      id: 'min',
      text: MIN_WEIGHT ? `Min. (${MIN_WEIGHT} kg)` : 'No available data',
      isDisable: !MIN_WEIGHT,
    },
    {
      id: 'avg',
      text: AVG_WEIGHT ? `Avg. (${AVG_WEIGHT} kg)` : 'No available data',
      isDisable: !AVG_WEIGHT,
    },
    {
      id: 'max',
      text: MAX_WEIGHT ? `Max. (${MAX_WEIGHT} kg)` : 'No available data',
      isDisable: !MAX_WEIGHT,
    },
  ];

  const updateWeight = (inputValue: string) => {
    setWeight(inputValue);
    // Checker if mode is in edit
    if (isEditCustomization) {
      newCustomizationContext?.dispatch({
        type: 'EDIT_WEIGHT',
        payload: inputValue,
      });
      return;
    }

    // Default option for adding new customization
    newCustomizationContext?.dispatch({
      type: 'WEIGHT',
      payload: inputValue,
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    const regex = /^\d{0,4}(\.\d{0,2})?$/; // Regular expression to allow only up to 4 digits with two decimal places
    if (regex.test(inputValue)) {
      updateWeight(inputValue);
    }
  };

  const handleChangeRange = (id: string) => {
    if (id === 'min') {
      updateWeight(MIN_WEIGHT);
      return;
    }
    if (id === 'avg') {
      updateWeight(AVG_WEIGHT);
      return;
    }
    if (id === 'max') {
      updateWeight(MAX_WEIGHT);
      return;
    }
  };

  useEffect(() => {
    // Useful for validating edit values when the user clicks back button without selecting new value
    // then the previously edit value should still remain and not revert back to original value
    if (isEditCustomization)
      newCustomizationContext?.dispatch({
        type: 'UPDATE_CURRENT_EDITED_VALUE',
        payload: editCustomizations.weight,
      });
  }, []);

  useEffect(() => {
    if (AVG_WEIGHT) setWeight(AVG_WEIGHT);
  }, [AVG_WEIGHT]);

  useEffect(() => {
    if (
      parseFloat(weight) > parseFloat(MAX_WEIGHT) ||
      parseFloat(weight) < parseFloat(MIN_WEIGHT)
    ) {
      newCustomizationContext?.dispatch({
        type: 'UPDATE_DISABLED',
        payload: true,
      });
      return setIsWeightInvalid.on();
    }
    newCustomizationContext?.dispatch({
      type: 'UPDATE_DISABLED',
      payload: false,
    });
    setIsWeightInvalid.off();
  }, [weight]);
  return (
    <ModalContentLayout
      heading={HEADING}
      progressValue={PROGRESS_BAR_VALUE}
      icon={Weight}
      supportHeading={SUPPORT_HEADING}
    >
      <FormControl display="flex" justifyContent="center" alignItems="center">
        <InputGroup maxW="267px">
          <Input
            isInvalid={isWeightInvalid}
            errorBorderColor="status.red"
            textAlign="center"
            type="number"
            variant="flushed"
            placeholder="e.g 2.00"
            _placeholder={{ textAlign: 'center' }}
            _focusWithin={{
              borderColor: isWeightInvalid ? 'status.red' : '#EFEFEF',
              boxShadow: 'none',
            }}
            onChange={handleChange}
            onBlur={handleChange}
            value={isEditCustomization ? editCustomizations.weight : weight}
            // Added value attribute to auto assign value if isEdit but need to think of other solutions
            // since it creates a bug where adding new calculation while isEdit is true will display the
            // saved weight value.
            // value={isEditCustomization ? editCustomizations.weight : '0'} commented out for the mean time since it creates a bug where it does not totally clear.
          />
          <InputRightAddon
            color="text.neutral"
            border="none"
            borderBottom="1px solid #EFEFEF"
            borderRadius="0px"
            background="transparent"
            children="kg"
          />
        </InputGroup>
      </FormControl>
      {isWeightInvalid && (
        <Flex justifyContent="center" marginTop="9px">
          <Text fontSize="12px" fontWeight="500" color="status.red">
            Outside expected range. Relativity may not apply.
          </Text>
        </Flex>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <HStack justifyContent="center" marginTop="16px">
          {rangeButtons.map((range) => {
            return (
              <Button
                key={range.id}
                borderRadius="12px"
                border="3px solid rgba(22, 52, 88, 0.20)"
                padding="12px 28px"
                textColor="text.primary"
                fontWeight="400"
                isDisabled={range.isDisable}
                onClick={() => handleChangeRange(range.id)}
              >
                {range.text}
              </Button>
            );
          })}
        </HStack>
      )}
    </ModalContentLayout>
  );
};

export default LiveWeight;
