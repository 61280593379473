import React from 'react';
import { Icon } from '@chakra-ui/react';

const Cattle = () => {
  return (
    <Icon
      width="90"
      height="39"
      viewBox="0 0 90 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.975 34.8078L25.6221 35.7594L22.851 42.0943L28.4621 58H24.7171L15.902 39.5052L18.975 34.8078ZM89.6362 17.0021L81.4142 4.74767C80.6122 3.5527 79.1702 2.96528 77.7672 3.26201L76.8712 3.45111L75.5142 1.44844C74.9002 0.542158 73.8802 0 72.7902 0H71.7202V4.54147L59.7671 7.07221H5.62501C2.51801 7.07322 0 9.60598 0 12.7312V38.1926H2.81301V12.7312C2.81301 11.1711 4.07501 9.9017 5.62601 9.9017V15.5597L8.22102 29.6981L5.62601 38.1926L8.99002 57.999H12.734L11.405 42.0923L17.948 32.5165L39.9421 35.5341L41.1971 57.999H44.9421L46.3001 36.3941L49.3781 36.8095L53.9301 57.999H57.6751L55.1021 37.5307L71.1752 21.7226C72.3792 20.5387 74.1272 20.1052 75.7402 20.592L79.4312 21.7055C79.9132 21.8513 80.4132 21.9248 80.9162 21.9248H87.0422C87.7832 21.9248 88.4642 21.5174 88.8192 20.8636L89.7532 19.1416C90.1202 18.4646 90.0752 17.6358 89.6362 17.0021Z"
        fill="#163458"
      />
    </Icon>
  );
};

export default Cattle;
