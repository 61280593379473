import { ProfileAccountSettingsType } from 'components/Dashboard/SettingsAccount';

export type ProfileDetails = {
  id: number;
  label?: string;
  type: string;
  name: 'first_name' | 'last_name' | 'phone_number' | 'email';
  value: string | number;
};

export const PROFILE_DETAILS: (
  user: ProfileAccountSettingsType
) => ProfileDetails[] = (user) => [
  {
    id: 1,
    label: 'First Name:',
    type: 'first_name',
    name: 'first_name',
    value: user?.first_name,
  },
  {
    id: 2,
    label: 'Last Name:',
    type: 'last_name',
    name: 'last_name',
    value: user?.last_name,
  },
  {
    id: 3,
    label: 'Phone Number:',
    type: 'phone_number',
    name: 'phone_number',
    value: user?.phone_number,
  },
  {
    id: 4,
    label: 'Email address:',
    type: 'email',
    name: 'email',
    value: user?.email,
  },
];

export const PROFILE_DEFAULT_VALUES = {
  first_name: '',
  last_name: '',
  phone_number: '',
  email: '',
};
