import React, { useState, useEffect, useCallback } from 'react';

export const useDragScroll = ({
  sliderRef,
  reliants = [],
  scrollValue,
  momentumVelocity = 0.9,
}: any) => {
  const [hasSwiped, setHasSwiped] = useState(false);

  const init = useCallback(() => {
    const slider = sliderRef.current;
    let isDown = false;
    let startX: any;
    let scrollLeft: any;

    slider?.addEventListener('mousedown', (e: any) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
      scrollValue(() => scrollLeft);
      cancelMomentumTracking();
    });

    slider?.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });

    slider?.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
      beginMomentumTracking();
      setTimeout(() => setHasSwiped(false), 0);
    });

    slider?.addEventListener('mousemove', (e: any) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      let prevScrollLeft = slider.scrollLeft;
      slider.scrollLeft = scrollLeft - walk;
      velX = slider.scrollLeft - prevScrollLeft;
      scrollValue(() => scrollLeft);
      if (slider.scrollLeft - prevScrollLeft && !hasSwiped) {
        setHasSwiped(true);
      }
    });

    // Momentum
    let velX = 0;
    let momentumID: any;

    slider?.addEventListener('wheel', (e: any) => {
      cancelMomentumTracking();
    });

    function beginMomentumTracking() {
      cancelMomentumTracking();
      momentumID = requestAnimationFrame(momentumLoop);
    }
    function cancelMomentumTracking() {
      cancelAnimationFrame(momentumID);
    }
    function momentumLoop() {
      slider.scrollLeft += velX;
      velX *= momentumVelocity;
      if (Math.abs(velX) > 0.5) {
        momentumID = requestAnimationFrame(momentumLoop);
      }
    }
  }, []);

  useEffect(() => {
    init();
  }, [...reliants]);

  return {
    hasSwiped,
  };
};
