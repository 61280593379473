import React from 'react';
import DashboardLayout from 'components/Dashboard/DashboardLayout';
import LegalPage from 'components/Legals/LegalPage';
import {
  TERMS_DEFINITIONS,
  TERMS_CONDITIONS_TITLE,
  TERMS_CONDITIONS_DESCRIPTION,
  TERMS_DASHBOARD_ROUTE,
  TERMS_LINK_TO_OTHER_SITES,
  TERMS_CHANGES,
  CONTACT_EMAIL,
  TERMS_LAST_UPDATED,
} from 'assets/constants/legals';
import useIsRoutePathToDashboard from 'hooks/useIsRoutePathToDashboard';
import LegalsLayout from 'components/Legals/LegalsLayout';
import LegalAcknowledgement from 'components/Legals/LegalAcknowledgement';
import LegalAsDisclaimer from 'components/Legals/LegalAsDisclaimer';
import LegalChanges from 'components/Legals/LegalChanges';
import LegalContactUs from 'components/Legals/LegalContactUs';
import LegalDisputesResolution from 'components/Legals/LegalDisputesResolution';
import LegalEuropeanUsers from 'components/Legals/LegalEuropeanUsers';
import LegalGoverningLaw from 'components/Legals/LegalGoverningLaw';
import LegalLimitationLiability from 'components/Legals/LegalLimitationLiability';
import LegalLinksSites from 'components/Legals/LegalLinksSites';
import LegalSeverability from 'components/Legals/LegalSeverability';
import LegalTermination from 'components/Legals/LegalTermination';
import LegalTranslation from 'components/Legals/LegalTranslation';
import LegalUSCompliance from 'components/Legals/LegalUSCompliance';

const TermsConditions = () => {
  const isRoutePathToDashboard = useIsRoutePathToDashboard(
    TERMS_DASHBOARD_ROUTE
  );
  return (
    <>
      {isRoutePathToDashboard ? (
        <DashboardLayout>
          <LegalPage
            definitions={TERMS_DEFINITIONS}
            title={TERMS_CONDITIONS_TITLE}
            descriptions={TERMS_CONDITIONS_DESCRIPTION}
            lastUpdated={TERMS_LAST_UPDATED}
          >
            <LegalAcknowledgement />
            <LegalLinksSites texts={TERMS_LINK_TO_OTHER_SITES} />
            <LegalTermination />
            <LegalLimitationLiability />
            <LegalAsDisclaimer />
            <LegalGoverningLaw />
            <LegalDisputesResolution />
            <LegalEuropeanUsers />
            <LegalUSCompliance />
            <LegalSeverability />
            <LegalTranslation />
            <LegalChanges
              title={TERMS_CONDITIONS_TITLE}
              texts={TERMS_CHANGES}
            />
            <LegalContactUs
              title={TERMS_CONDITIONS_TITLE}
              contactEmail={CONTACT_EMAIL}
            />
          </LegalPage>
        </DashboardLayout>
      ) : (
        <LegalsLayout>
          <LegalPage
            definitions={TERMS_DEFINITIONS}
            title={TERMS_CONDITIONS_TITLE}
            descriptions={TERMS_CONDITIONS_DESCRIPTION}
            lastUpdated={TERMS_LAST_UPDATED}
          >
            <LegalAcknowledgement />
            <LegalLinksSites texts={TERMS_LINK_TO_OTHER_SITES} />
            <LegalTermination />
            <LegalLimitationLiability />
            <LegalAsDisclaimer />
            <LegalGoverningLaw />
            <LegalDisputesResolution />
            <LegalEuropeanUsers />
            <LegalUSCompliance />
            <LegalSeverability />
            <LegalTranslation />
            <LegalChanges
              title={TERMS_CONDITIONS_TITLE}
              texts={TERMS_CHANGES}
            />
            <LegalContactUs
              title={TERMS_CONDITIONS_TITLE}
              contactEmail={CONTACT_EMAIL}
            />
          </LegalPage>
        </LegalsLayout>
      )}
    </>
  );
};

export default TermsConditions;
