import { Box, Heading, Text } from '@chakra-ui/react';
import Select from 'react-select';
import { SUBSCRIPTION_PLANS } from './constants';
import styles from './styles.module.css';

type OptionsType = {
  value: string;
  label: string;
  price: string;
};

const SubscriptionPlans = () => {
  const CustomOptionsLabel = ({ label, price }: OptionsType) => (
    <div className={styles['option-item']}>
      <span>{label}</span>
      <span>{price}</span>
    </div>
  );

  const CustomGroupLabel = (data: any) => (
    <div className={styles['group-label']}>
      <span>{data.label}</span>
    </div>
  );
  return (
    <Box width="full" borderBottom="1px solid #D4D5D8" marginTop="30px">
      <Heading
        fontSize="24px"
        fontWeight="700"
        textAlign="center"
        color="#263042"
      >
        Subscription Plan
      </Heading>
      <Box margin="16px 0px 20px 0px">
        <Text fontSize={12} marginBottom="8px" color="#6B6C75">
          Subscription Plan
        </Text>
        <Select
          options={SUBSCRIPTION_PLANS}
          formatOptionLabel={CustomOptionsLabel}
          defaultValue={SUBSCRIPTION_PLANS[0].options}
          onChange={(data) => console.log(data?.value)}
          formatGroupLabel={CustomGroupLabel}
          placeholder="Select a subscription Plan"
          styles={{
            groupHeading: (styles) => ({
              ...styles,
              color: '#394c75',
            }),
            group: (styles) => ({
              ...styles,
              borderBottom: '1px solid rgba(0, 0, 0, 0.10)',
            }),
            option: (styles, { isFocused }) => ({
              ...styles,
              backgroundColor: isFocused ? '#026C64' : undefined,
              color: isFocused ? '#fff' : '#263042',
              ':active': {
                ...styles[':active'],
                backgroundColor: '#026C64',
                color: '#fff',
              },
            }),
            singleValue: (styles) => ({
              ...styles,
              color: '#263042',
            }),
          }}
        />
      </Box>
    </Box>
  );
};

export default SubscriptionPlans;
