import { Flex, Heading, Box, Text, Stack } from '@chakra-ui/react';

const LegalEuropeanUsers = () => {
  return (
    <Flex flexDir="column">
      <Box>
        <Heading>For European Union (EU) Users</Heading>
        <Stack marginTop="32px">
          <Text>
            If You are a European Union consumer, you will benefit from any
            mandatory provisions of the law of the country in which you are
            resident in.
          </Text>
        </Stack>
      </Box>
    </Flex>
  );
};

export default LegalEuropeanUsers;
