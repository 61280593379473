import { useQuery } from 'react-query';
import { getCustomizationById } from 'services/Customizations';

const useGetCustomisation = (id: number) => {
  return useQuery({
    queryKey: ['customizationsDetails', id],
    queryFn: () => getCustomizationById(id),
    enabled: !!id,
  });
};

export default useGetCustomisation;
