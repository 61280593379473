import React from 'react';
import {
  Box,
  UnorderedList,
  ListItem,
  Flex,
  ListIcon,
  Button,
  Image,
} from '@chakra-ui/react';
import Logo from 'assets/images/logo.png';
import { FiLogOut, FiChevronLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import LegalButtonGroup from 'components/molecules/LegalButtonGroup';

const LegalSidebar = () => {
  const navigate = useNavigate();

  const handleClickLogo = () => {
    navigate('/');
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Flex
      flexDir="column"
      w="324px"
      padding="30px 45px"
      borderRight="1px solid #EFEFEF"
    >
      <Image
        src={Logo}
        maxW="80px"
        maxH="40px"
        onClick={handleClickLogo}
        cursor="pointer"
      />
      <Box as="nav" flex="1" paddingBlock="60px">
        <UnorderedList margin="0">
          <ListItem
            listStyleType="none"
            marginBottom="20px"
            fontSize="18px"
            color={'text.secondary'}
            fontWeight="semibold"
          >
            <Button
              leftIcon={<FiChevronLeft size="24px" color={'text.secondary'} />}
              variant={'link'}
              onClick={handleBack}
            >
              Back
            </Button>
          </ListItem>
        </UnorderedList>
      </Box>
      <Flex flexDir="column">
        <Box>
          <Button
            leftIcon={<FiLogOut color="#D4D5D8" size="24px" />}
            variant="link"
            fontSize="18px"
            color="text.basic"
            onClick={handleLogin}
          >
            Login
          </Button>
        </Box>
        <Flex gap={5} marginTop="32px">
          <LegalButtonGroup />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LegalSidebar;
