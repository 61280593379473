import {
  Flex,
  Heading,
  Box,
  Text,
  Stack,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';

type LegalContactUsType = {
  title: string;
  contactEmail: string;
};

const LegalContactUs = ({ title, contactEmail }: LegalContactUsType) => {
  return (
    <Box>
      <Heading>Contact Us</Heading>
      <Stack marginTop="32px" spacing={5}></Stack>
      <Text>
        If you have any questions about these {title}, You can contact us:
      </Text>
      <Box>
        <UnorderedList style={{ paddingLeft: '20px' }}>
          <ListItem> By email: {contactEmail}</ListItem>
          {title === 'Privacy Policy' && (
            <ListItem>
              By visiting this page on our website:
              https://app.livestockbarometer.co.nz
            </ListItem>
          )}
        </UnorderedList>
      </Box>
    </Box>
  );
};

export default LegalContactUs;
