import React, { useContext, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalCloseButton,
  Button,
  Portal,
  useBoolean,
} from '@chakra-ui/react';

import { CustomModalProps } from 'interfaces/modal';
import { NewCustomizationContext } from 'context/newCustomization';
import CalculationViewModal from '../CalculationViewModal';
import { createCustomizations } from 'services/Customizations';
import { EDIT_STEP } from 'enums/customizations';

const NewCalculationModal = ({
  children,
  openModal,
  closeModal,
  modalValues,
  updateModalValues,
}: CustomModalProps) => {
  const newCustomizationContext = useContext(NewCustomizationContext);
  const [isCalculationViewModalOpen, setIsCalculationViewModalOpen] =
    useBoolean();

  const STEPS = modalValues?.steps;
  const NUMBER_OF_STEPS = modalValues?.stepTitles?.length;
  // const ANALYSIS_PERIOD = newCustomizationContext?.state?.choices[0]; // Commented as this variable is not being used
  // const isLongTerm = ANALYSIS_PERIOD === 'long'; // Commented as this variable is not being used
  const isInLiveWeight = STEPS === NUMBER_OF_STEPS - 1;
  const {
    isEditCustomization,
    isProceedViewLatestPrice,
    editCustomizations,
    customizations,
    currentEditedValue,
    stock,
    editStock,
    currentChoice,
    isDisabled,
    firstModalOpen,
    choices,
    editChoices,
  } = newCustomizationContext?.state;

  const revertAllEditLivestock = () => {
    newCustomizationContext?.dispatch({
      type: 'EDIT_STOCKS',
      payload: {
        id: stock?.category,
        name: stock?.category_name,
      },
    });
    newCustomizationContext?.dispatch({
      type: 'EDIT_STOCK_TYPE',
      payload: {
        id: stock?.livestock_type,
        name: stock?.livestock_type_name,
      },
    });

    newCustomizationContext?.dispatch({
      type: 'EDIT_AGE_CLASS',
      payload: {
        id: customizations?.livestock,
        name: stock?.livestock_name,
      },
    });
  };

  // Validation to check if the edited value is the same to the current selected value then do not
  // revert to original value when pressing back button
  const isEditValueSame = (value: string) => {
    if (currentEditedValue === value) {
      return true;
    } else {
      return false;
    }
  };

  const processEditRoleLocationData = () => {
    if (STEPS === EDIT_STEP.ROLE) {
      newCustomizationContext?.dispatch({
        type: 'EDIT_ROLE',
        payload: currentChoice,
      });

      newCustomizationContext?.dispatch({
        type: 'UPDATE_EDIT_CHOICES',
        payload: {
          step: EDIT_STEP.ROLE,
          name: currentChoice,
        },
      });

      closeModal();
      return;
    }

    // NOTE: Commented out for temporary removal of location
    // if (STEPS === EDIT_STEP.LOCATION) {
    //   newCustomizationContext?.dispatch({
    //     type: 'EDIT_LOCATION',
    //     payload: currentChoice,
    //   });

    //   newCustomizationContext?.dispatch({
    //     type: 'UPDATE_EDIT_CHOICES',
    //     payload: {
    //       step: EDIT_STEP.LOCATION,
    //       name: currentChoice,
    //     },
    //   });

    //   closeModal();
    //   return;
    // }
  };

  const processEditLivestockData = () => {
    switch (STEPS) {
      case EDIT_STEP.STOCKS:
        updateModalValues({
          ...modalValues,
          steps: modalValues?.steps + 1,
        });
        newCustomizationContext?.dispatch({
          type: 'EDIT_STOCKS',
          payload: {
            id: editStock?.category,
            name: currentChoice,
          },
        });
        // newCustomizationContext?.dispatch({ type: 'ADD_EDIT_LIVESTOCK_CHOICE' });
        newCustomizationContext?.dispatch({
          type: 'UPDATE_EDIT_CHOICES',
          payload: {
            step: EDIT_STEP.STOCKS,
            name: currentChoice,
          },
        });

        newCustomizationContext?.dispatch({
          type: 'UPDATE_DISABLED',
          payload: true,
        });
        return;
      case EDIT_STEP.STOCK_TYPE:
        updateModalValues({
          ...modalValues,
          steps: modalValues?.steps + 1,
        });

        newCustomizationContext?.dispatch({
          type: 'EDIT_STOCK_TYPE',
          payload: {
            id: editStock?.livestock_type,
            name: currentChoice,
          },
        });

        if (firstModalOpen === STEPS) {
          newCustomizationContext?.dispatch({
            type: 'UPDATE_EDIT_CHOICES',
            payload: {
              step: EDIT_STEP.STOCK_TYPE,
              name: currentChoice,
            },
          });
          newCustomizationContext?.dispatch({
            type: 'UPDATE_DISABLED',
            payload: true,
          });
        } else {
          newCustomizationContext?.dispatch({
            type: 'ADD_EDIT_LIVESTOCK_CHOICE',
          });
        }

        return;
      case EDIT_STEP.AGE_CLASS:
        newCustomizationContext?.dispatch({
          type: 'EDIT_AGE_CLASS',
          payload: {
            id: editCustomizations?.livestock,
            name: currentChoice,
          },
        });
        if (firstModalOpen === STEPS) {
          newCustomizationContext?.dispatch({
            type: 'UPDATE_EDIT_CHOICES',
            payload: {
              step: EDIT_STEP.AGE_CLASS,
              name: currentChoice,
            },
          });
        }

        // Double check if first modal open is not 0 then reset to 0
        if (firstModalOpen !== 0) {
          newCustomizationContext?.dispatch({
            type: 'UPDATE_FIRST_MODAL_OPEN',
            payload: 0,
          });
        }

        closeModal();
        return;
      default: // Default for closing the weight modal
        closeModal();
        return;
    }
  };

  const processPreviousCustomizationValues = () => {
    switch (STEPS) {
      case EDIT_STEP.ROLE:
        if (!isEditValueSame(editCustomizations?.merchant)) {
          newCustomizationContext?.dispatch({
            type: 'EDIT_ROLE',
            payload: customizations?.merchant,
          });
        }
        closeModal();
        return;
      // NOTE: Commented out for temporary removal of location
      // case EDIT_STEP.LOCATION:
      //   if (!isEditValueSame(editCustomizations?.location)) {
      //     newCustomizationContext?.dispatch({
      //       type: 'EDIT_LOCATION',
      //       payload: customizations?.location,
      //     });
      //   }
      //   closeModal();
      //   return;
      case EDIT_STEP.STOCKS:
        // Revert value of stock
        if (!isEditValueSame(editStock?.category_name)) {
          newCustomizationContext?.dispatch({
            type: 'EDIT_STOCKS',
            payload: {
              id: stock?.category,
              name: stock?.category_name,
            },
          });
        }

        // Check if this is the first livestock modal then close modal else proceed to previous modal
        if (STEPS === firstModalOpen) {
          newCustomizationContext?.dispatch({
            type: 'UPDATE_FIRST_MODAL_OPEN',
            payload: 0,
          });

          // Revert all livestock changes back to original value
          revertAllEditLivestock();
          // Revert editChoices back to original choices
          newCustomizationContext?.dispatch({
            type: 'ADD_EDIT_CHOICES',
            payload: choices,
          });
          closeModal();
        }
        return;
      case EDIT_STEP.STOCK_TYPE:
        const hasChosenLivestockType = editChoices.length === 5;
        // Check if this is the first livestock modal then close modal else proceed to previous modal
        if (STEPS === firstModalOpen) {
          newCustomizationContext?.dispatch({
            type: 'UPDATE_FIRST_MODAL_OPEN',
            payload: 0,
          });
          // Revert all livestock changes back to original value
          revertAllEditLivestock();
          // Revert editChoices back to original choices
          newCustomizationContext?.dispatch({
            type: 'ADD_EDIT_CHOICES',
            payload: choices,
          });
          closeModal();
          return;
        } else {
          newCustomizationContext?.dispatch({
            type: 'EDIT_STOCK_TYPE',
            payload: {
              id: stock?.livestock_type,
              name: stock?.livestock_type_name,
            },
          });

          if (hasChosenLivestockType) {
            newCustomizationContext?.dispatch({
              type: 'REMOVE_EDIT_LIVESTOCK_CHOICE',
            });
          }

          updateModalValues({
            ...modalValues,
            steps: modalValues?.steps - 1,
          });
        }
        return;
      case EDIT_STEP.AGE_CLASS:
        // Check if this is the first livestock modal then close modal else proceed to previous modal
        if (STEPS === firstModalOpen) {
          newCustomizationContext?.dispatch({
            type: 'UPDATE_FIRST_MODAL_OPEN',
            payload: 0,
          });
          closeModal();
          return;
        } else {
          newCustomizationContext?.dispatch({
            type: 'EDIT_AGE_CLASS',
            payload: {
              id: customizations?.livestock,
              name: stock?.livestock_name,
            },
          });

          updateModalValues({
            ...modalValues,
            steps: modalValues?.steps - 1,
          });
        }
        return;
      case EDIT_STEP.WEIGHT:
        newCustomizationContext?.dispatch({
          type: 'EDIT_WEIGHT',
          payload: customizations?.weight,
        });
        closeModal();
        return;
      default:
        return;
    }
  };

  const handleNextStep = async () => {
    if (isEditCustomization) {
      processEditRoleLocationData();
      processEditLivestockData();
      return;
    }

    if (isInLiveWeight) {
      closeModal();
      setIsCalculationViewModalOpen.on();
    }

    if (STEPS + 1 === NUMBER_OF_STEPS - 1) {
      updateModalValues({
        ...modalValues,
        steps: modalValues?.steps + 1,
      });
      newCustomizationContext?.dispatch({ type: 'ADD_CHOICES' });
      return;
    }

    updateModalValues({
      ...modalValues,
      modalSize: '4xl',
      steps: modalValues?.steps + 1,
      header: 'Current Season Analysis',
      returnBtnText: 'Back',
    });
    newCustomizationContext?.dispatch({ type: 'ADD_CHOICES' });
  };

  const handlePrevStep = () => {
    if (isEditCustomization) {
      processPreviousCustomizationValues();
      return;
    }

    // Close command if in the 1st step
    if (modalValues?.steps === 0) {
      closeModal();
      return;
    }

    // Updates modal values if back at the 1st step
    if (modalValues?.steps - 1 === 0) {
      updateModalValues({
        ...modalValues,
        modalSize: 'md',
        steps: modalValues?.steps - 1,
        header: 'New Calculation',
        returnBtnText: 'Cancel',
      });
      newCustomizationContext?.dispatch({ type: 'REMOVE_CHOICES' });
      return;
    }

    // Validation for max limit customization process
    if (isProceedViewLatestPrice) {
      newCustomizationContext?.dispatch({
        type: 'UPDATE_PROCEED_VIEW_LATEST_PRICE',
        payload: false,
      });
    }

    // Default command
    newCustomizationContext?.dispatch({ type: 'REMOVE_CHOICES' });
    updateModalValues({
      ...modalValues,
      acceptBtnText: 'Next',
      steps: modalValues?.steps - 1,
    });
  };

  const onCloseComplete = () => {
    if (!isEditCustomization)
      newCustomizationContext?.dispatch({ type: 'CLEAR_CHOICES' });

    if (isEditCustomization) {
      newCustomizationContext?.dispatch({
        type: 'ADD_EDIT_CHOICES',
        payload: choices,
      });
    }
  };

  useEffect(() => {
    if (isEditCustomization) {
      updateModalValues({
        ...modalValues,
        acceptBtnText: 'Apply Changes',
      });
    }
  }, [isEditCustomization]);

  return (
    <Portal>
      <Modal
        autoFocus={false}
        onCloseComplete={onCloseComplete}
        isOpen={openModal}
        onClose={closeModal}
        isCentered
        size={modalValues?.modalSize}
      >
        <ModalOverlay />
        <ModalContent data-testid="new-calculation-modal">
          {children}
          <ModalCloseButton />
          <ModalFooter
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            gap="0.5rem"
            paddingBlock="1.5rem"
          >
            <Button
              colorScheme="teal"
              w="100%"
              maxW="300px"
              onClick={handleNextStep}
              isDisabled={isDisabled}
            >
              {modalValues?.acceptBtnText}
            </Button>
            <Button
              variant="ghost"
              w="100%"
              maxW="300px"
              onClick={handlePrevStep}
            >
              {modalValues?.returnBtnText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <CalculationViewModal
        openModal={isCalculationViewModalOpen}
        closeModal={setIsCalculationViewModalOpen.off}
      />
    </Portal>
  );
};

export default NewCalculationModal;
