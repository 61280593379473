import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';

import { ModalProps } from 'interfaces/modal';
import ADatePicker from '../ModalContent/ADatePicker';
import { NewCustomizationContext } from 'context/newCustomization';
import useDateProps from 'hooks/useDateProps';

interface DatePickerModalProps extends ModalProps {
  handleGetIndicatorSchedule: (newIndicatorScheduleDate: Date) => Promise<void>;
  handleGetExpectedStorePrice: (newExpectedPriceDate: Date) => Promise<void>;
}

const DatePickerModal = ({
  openModal,
  closeModal,
  handleGetExpectedStorePrice,
  handleGetIndicatorSchedule,
}: DatePickerModalProps) => {
  const newCustomizationContext = useContext(NewCustomizationContext);
  const { currentEditDate } = newCustomizationContext?.state;
  const [startDate, setStartDate] = useState(new Date());
  const additionalDateProps = useDateProps(currentEditDate);

  const updateDate = () => {
    switch (currentEditDate) {
      case 'indicatorSchedule':
        newCustomizationContext?.dispatch({
          type: 'UPDATE_INDICATOR_SCHEDULE_DATE',
          payload: startDate,
        });
        handleGetIndicatorSchedule(startDate);
        break;
      case 'expectedPrice':
        newCustomizationContext?.dispatch({
          type: 'UPDATE_EXPECTED_PRICE_DATE',
          payload: startDate,
        });
        handleGetExpectedStorePrice(startDate);
        break;
      default:
        newCustomizationContext?.dispatch({
          type: 'UPDATE_TODAYS_DATE',
          payload: startDate,
        });
        break;
    }
  };

  const handleChangeDate = (date: Date) => {
    setStartDate(date);
  };

  const handleApplyChanges = () => {
    updateDate();
    closeModal();
  };

  return (
    <>
      <Modal
        isOpen={openModal}
        onClose={closeModal}
        isCentered
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent data-testid="calculation-view-modal">
          <ADatePicker
            startDate={startDate}
            handleChangeDate={handleChangeDate}
            {...additionalDateProps}
          />
          <ModalCloseButton />
          <ModalFooter
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            gap="0.5rem"
            paddingBlock="1.5rem"
          >
            <Button
              colorScheme="teal"
              w="100%"
              maxW="300px"
              onClick={handleApplyChanges}
            >
              Apply changes
            </Button>
            <Button variant="ghost" w="100%" maxW="300px" onClick={closeModal}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DatePickerModal;
