import { useQuery } from 'react-query';
import { getAllUserCustomization } from 'services/Customizations';

const useGetAllCustomisations = () => {
  return useQuery({
    queryKey: ['userCustomizations'],
    queryFn: getAllUserCustomization,
  });
};

export default useGetAllCustomisations;
