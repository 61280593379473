import React from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import { toTitleCase } from 'utils';
const Breadcrumbs = () => {
  const currentLocation = useLocation();

  let currentLink = '';

  const crumbs = currentLocation.pathname
    .split('/')
    .filter((crumb) => crumb !== '')
    .map((crumb) => {
      currentLink += `/${crumb}`;

      return (
        <BreadcrumbItem key={crumb}>
          <BreadcrumbLink as={Link} to={currentLink}>
            {toTitleCase(crumb)}
          </BreadcrumbLink>
        </BreadcrumbItem>
      );
    });
  return (
    <Breadcrumb
      visibility={
        currentLocation.pathname === '/dashboard' ? 'hidden' : 'visible'
      }
    >
      {crumbs}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
