import React, { useContext } from 'react';
import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react';

import { SettingsContext } from 'context/settingsContext';
import ProfileForm from '../ModalContent/ProfileForm';

const ProfileModal = () => {
  const { isOpenProfile, setIsOpenProfile } = useContext(SettingsContext);

  return (
    <>
      {/* Profile Modal */}
      <Modal isOpen={isOpenProfile} onClose={() => setIsOpenProfile(false)}>
        <ModalOverlay />
        <ModalContent>
          <ProfileForm />
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProfileModal;
