import { Heading, Box, Text } from '@chakra-ui/react';

const LegalSecurityData = () => {
  return (
    <Box>
      <Heading>Security of Your Personal Data</Heading>
      <Text marginTop="32px">
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security.
      </Text>
    </Box>
  );
};

export default LegalSecurityData;
