import React, { useContext, useState } from 'react';
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Portal,
  useBoolean,
} from '@chakra-ui/react';

import { ModalProps } from 'interfaces/modal';
import ForecastAverageSchedule from '../ModalContent/ForecastAverageSchedule';
import { SelectionHistoryContext } from 'context/selectionHistoryContext';
import { getUpdatedForecastAverageScheduleById } from 'services/SelectionHistory';

const ForecastAverageScheduleModal = ({
  openModal,
  closeModal,
}: ModalProps) => {
  const selectionHistoryContext = useContext(SelectionHistoryContext);
  const { five_year_average_schedule, id, forecast_average_schedule } =
    selectionHistoryContext?.state?.selectionHistory;
  const [forecastAverageSchedule, setForecastAverageSchedule] = useState(
    five_year_average_schedule
  );
  const [isLoading, setIsLoading] = useBoolean(false);

  const handleConfirm = async () => {
    try {
      setIsLoading.on();
      const updatedCarcassPrices = await getUpdatedForecastAverageScheduleById(
        id,
        forecastAverageSchedule
      );
      selectionHistoryContext?.dispatch({
        type: 'UPDATE_CARCASS_PRICES',
        payload: {
          carcass_prices: updatedCarcassPrices?.carcass_prices,
          forecastSchedule: forecastAverageSchedule,
        },
      });
      setIsLoading.off();
      closeModal();
    } catch (error) {
      setIsLoading.off();
      return error;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setForecastAverageSchedule(inputValue);
  };

  const handleBack = () => {
    // revert input back to original value of forecast_average_schedule
    setForecastAverageSchedule(forecast_average_schedule);
    closeModal();
  };

  return (
    <Portal>
      <Modal isOpen={openModal} onClose={closeModal} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ForecastAverageSchedule
            forecastAverageSchedule={forecastAverageSchedule}
            handleChange={handleChange}
          />
          <ModalCloseButton />
          <ModalFooter
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            gap="0.5rem"
            paddingBlock="1.5rem"
          >
            <Button
              colorScheme="teal"
              w="100%"
              maxW="300px"
              onClick={handleConfirm}
              isLoading={isLoading}
            >
              Confirm
            </Button>
            <Button variant="ghost" w="100%" maxW="300px" onClick={handleBack}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Portal>
  );
};

export default ForecastAverageScheduleModal;
