import { useState, useCallback, useEffect } from 'react';

export const useLocalStorage = <InitialValueType>(
  key: string,
  initialValue: InitialValueType
): readonly [
  InitialValueType,
  (newValue: InitialValueType) => void,
  () => void
] => {
  const [value, setValue] = useState(initialValue);

  const update = useCallback(
    (newValue: InitialValueType) => {
      localStorage.setItem(key, JSON.stringify(newValue));
      setValue(newValue);
    },
    [key]
  );

  const clear = () => {
    localStorage.clear();
  };

  useEffect(() => {
    const userToken = localStorage.getItem(key);
    if (userToken) {
      const parsedSessionData = JSON.parse(userToken);
      setValue(parsedSessionData);
    }
  }, [key]);

  return [value, update, clear];
};
