import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Box,
  Flex,
  Icon,
  Text,
} from '@chakra-ui/react';
import { FiChevronRight } from 'react-icons/fi';

import { SETTINGS_ACCOUNT_ACCORDION_VALUES } from 'assets/constants/dashboard';
import { PROFILE_DEFAULT_VALUES } from 'assets/constants/profile';
import { profileAccountSettingsSchema } from 'schema';
import PasswordModal from 'components/Modal/PasswordModal';

import { SettingsContext } from 'context/settingsContext';

export type ProfileAccountSettingsType = {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
};

const SettingsAccount = () => {
  const { isOpenProfile, setIsOpenProfile } = useContext(SettingsContext);
  const [isOpenPassword, setIsOpenPassword] = useState(false);
  const [user, setUser] = useState<ProfileAccountSettingsType>({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
  });

  const { reset } = useForm<typeof PROFILE_DEFAULT_VALUES>({
    mode: 'onChange',
    defaultValues: PROFILE_DEFAULT_VALUES,
    resolver: yupResolver(profileAccountSettingsSchema),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    const userDetail = localStorage?.getItem('user');
    if (userDetail) {
      const parseData = JSON?.parse(userDetail);
      if (parseData) {
        setUser(parseData);
      }
    }
  }, [isOpenProfile]);

  useEffect(() => {
    reset(user);
  }, [user, reset]);

  return (
    <Flex flexDir="column" gap="1rem">
      {/* Password Modal */}
      <PasswordModal
        isOpenPassword={isOpenPassword}
        setIsOpenPassword={setIsOpenPassword}
      />
      {SETTINGS_ACCOUNT_ACCORDION_VALUES.map((item) => (
        <Accordion key={item?.id} allowToggle>
          {item?.title && (
            <Text marginBottom="20px" color="text.neutral">
              {item?.title}
            </Text>
          )}
          <AccordionItem border="none">
            <>
              <AccordionButton
                bg="bg.primary"
                p="1rem"
                borderRadius="12px"
                boxShadow="0px 4px rgba(194, 206, 214, 0.2)"
                onClick={() => {
                  if (item?.name === 'Profile') {
                    setIsOpenProfile(true);
                  } else if (item?.name === 'Password') {
                    setIsOpenPassword(true);
                  }
                }}
              >
                <Icon color="text.logo" as={item?.icon} />
                <Box
                  flex="1"
                  textAlign="left"
                  ml="20px"
                  color="text.basic"
                  fontWeight="600"
                >
                  {item?.name}
                </Box>
                <Flex justifyContent="flex-end" flex="1">
                  {item?.name === 'Profile' ? (
                    <Text maxW="170px" noOfLines={1} color="text.neutral">
                      {user?.email}
                    </Text>
                  ) : (
                    <Text maxW="170px" noOfLines={1} color="text.neutral">
                      {item?.content}
                    </Text>
                  )}
                </Flex>
                <Icon color="text.basic" as={FiChevronRight} />
              </AccordionButton>
            </>
          </AccordionItem>
        </Accordion>
      ))}
    </Flex>
  );
};

export default SettingsAccount;
