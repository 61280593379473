import React, {
  useEffect,
  useRef,
  useState,
  useReducer,
  useContext,
} from 'react';

import {
  Text,
  Box,
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  Button,
  Heading,
  IconButton,
  HStack,
  useBoolean,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { useDragScroll } from 'hooks/useDragScroll';

import {
  customizationOptionReducer,
  CUSTOMIZATION_OPTION_INITIAL_STATE,
} from 'reducers/customizationOptionReducer';
import { NewCustomizationContext } from 'context/newCustomization';

import { CustomizationsData } from 'interfaces/customizations';
import { ISelectionHistory } from 'interfaces/selection-history';
import { getSelectionHistoryByPagination } from 'services/SelectionHistory';

import { FiChevronRight, FiChevronLeft } from 'react-icons/fi';

import useIsFirstRender from 'hooks/useIsFirstRender';
import { SELECTION_HISTORY_TABLE_HEADERS } from 'assets/constants/selection-history';
import TabPanel from 'components/Tabs/TabPanel';
import SelectionHistoryTable from 'components/SelectionHistoryTable';
import TableContent from 'components/SelectionHistoryTable/TableContent';
import Loader from 'components/atoms/Loader';
import useGetAllCustomisations from 'hooks/useGetAllCustomisations';
import useGetCustomisation from 'hooks/useGetCustomisation';
import useIsOverflow from 'hooks/useIsOverflow';
import useGetAllSelectionHistory from 'hooks/useGetAllSelectionHistory';

type DashboardHomeProp = {
  handleAddCustomization: () => void;
  handleEditCustomization: (step: number) => void;
  getUserCustomizationsLength: (length: number) => void;
  checkMaxCustomizationLength: () => void;
};

const DashboardHome = ({
  handleAddCustomization,
  handleEditCustomization,
  getUserCustomizationsLength,
  checkMaxCustomizationLength,
}: DashboardHomeProp) => {
  const newCustomizationContext = useContext(NewCustomizationContext);
  const [scrollValue, setScrollValue] = useState(0);
  const [selectionHistories, setSelectionHistories] = useState<
    ISelectionHistory[]
  >([]);
  const [customisationId, setCustomisationId] = useState<number>(0);
  const [tabIndex, setTabIndex] = useState(0);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const pageState = { page, setPage };
  const limitState = { limit, setLimit };
  const tableState = { count, pageState, limitState };

  const editCustomizationContext = useContext(NewCustomizationContext);

  const {
    data: histories,
    isLoading: isLoadingGetAllSelectionHistories,
    error: errorSelectionHistories,
    isSuccess,
  } = useGetAllSelectionHistory(limit, page);

  const {
    data: userCustomizations,
    isLoading: isLoadingAllCustomisations,
    error: errorGetAll,
  } = useGetAllCustomisations();

  const {
    data: customisation,
    isLoading: isLoadingCustomisation,
    refetch,
    error: errorGet,
    status,
    isFetching,
    isFetched,
  } = useGetCustomisation(customisationId);

  const tabListRef = useRef<HTMLInputElement>(null);

  const isOverflow = useIsOverflow(
    tabListRef,
    (isOverflowFromCallback: boolean) => {}
  );

  const isFirstRender = useIsFirstRender();

  const isCustomizationsEmpty = userCustomizations?.length === 0;

  const navigate = useNavigate();

  useDragScroll({
    sliderRef: tabListRef || '',
    scrollValue: setScrollValue,
  });

  const handleScrollClick = (event: any) => {
    const name = event?.currentTarget?.classList;

    if (name.contains('rightScroll')) {
      setScrollValue((prevValue) => prevValue + 1000);
    }
    if (name.contains('leftScroll')) {
      setScrollValue(() => 0);
    }
  };

  const resetEditCustomization = (id: number) => {
    editCustomizationContext?.dispatch({
      type: 'UPDATE_EDIT_CUSTOMIZATION_MODE',
      payload: false,
    });

    setCustomisationId(id);
    refetch();
  };

  const handleTabChange = async (index: number) => {
    setTabIndex(index);

    // Reset indicator schedule and expected store price dates
    newCustomizationContext?.dispatch({
      type: 'RESET_DATES',
    });

    // Reset current market percentage during tab change
    newCustomizationContext?.dispatch({
      type: 'UPDATE_CURRENT_MARKET_PERCENTAGE',
      payload: 0,
    });
  };

  const handleViewSelectionHistoryPage = () => {
    navigate('/dashboard/selection-history');
  };

  useEffect(() => {
    setCustomisationId(userCustomizations?.[0]?.id);
    getUserCustomizationsLength(userCustomizations?.length);
  }, [userCustomizations, isSuccess]);

  useEffect(() => {
    handleTabChange(0);
  }, [userCustomizations?.length]);

  useEffect(() => {
    const inputElement = tabListRef?.current;
    if (inputElement) {
      inputElement?.scroll(scrollValue, 0);
    }
  }, [scrollValue]);

  useEffect(() => {
    if (histories) {
      setSelectionHistories(histories?.results);
      setCount(histories?.count);
    }
  }, [histories]);

  useEffect(() => {
    if (!isFirstRender) {
      setPage(1);
    }
  }, [limit]);

  return (
    <>
      {/* DASHBOARD HEADING*/}
      <Box padding="32px 45px 16px 45px">
        <Flex justifyContent="space-between" alignItems="center">
          <Heading>Saved Scenarios</Heading>
          <Button
            data-testid="new-calculation-button"
            colorScheme="teal"
            size="lg"
            onClick={checkMaxCustomizationLength}
          >
            Make new calculations
          </Button>
        </Flex>
      </Box>
      {/* DASHBOARD TABS */}
      {isLoadingAllCustomisations ? (
        <Loader />
      ) : (
        <Tabs
          colorScheme="teal"
          onChange={(index) => handleTabChange(index)}
          index={tabIndex}
          isLazy
        >
          {isCustomizationsEmpty ? (
            <Flex
              alignItems={'center'}
              justifyContent={'center'}
              flexDirection={'column'}
            >
              <Text fontWeight={600} fontSize={'16px'}>
                Let’s get you started
              </Text>
              <Text>Your dashboard is empty</Text>
            </Flex>
          ) : (
            <>
              <Box position="relative" marginInline="45px">
                <TabList
                  borderBottom="1px solid #EFEFEF"
                  ref={tabListRef}
                  overflowX="scroll"
                  // Need to improve this since this solution doesn't work on firefox
                  sx={{
                    '::-webkit-scrollbar': {
                      display: 'none',
                    },
                  }}
                  height="42px"
                >
                  {isOverflow && scrollValue > 0 && (
                    <IconButton
                      name="leftScroll"
                      className="leftScroll"
                      onClick={(e) => handleScrollClick(e)}
                      position="absolute"
                      aria-label="Chevron Left"
                      icon={
                        <FiChevronLeft
                          size="32px"
                          style={{ marginRight: 'auto' }}
                        />
                      }
                      width="150px"
                      sx={{
                        background:
                          'linear-gradient(to left, transparent 20%,white 80%);',
                      }}
                      _hover={{
                        background:
                          'linear-gradient(to left, transparent 20%,white 80%);',
                      }}
                      _active={{
                        background:
                          'linear-gradient(to left, transparent 20%,white 80%);',
                      }}
                    />
                  )}
                  {userCustomizations?.map((customize: CustomizationsData) => (
                    <Tab
                      key={customize.id}
                      flexShrink={0}
                      onClick={() => resetEditCustomization(customize.id)}
                      style={{
                        height: '41px',
                      }}
                    >
                      {customize.name}
                    </Tab>
                  ))}
                  {isOverflow && scrollValue === 0 && (
                    <IconButton
                      name="rightScroll"
                      className="rightScroll"
                      onClick={(e) => handleScrollClick(e)}
                      position="absolute"
                      right="0"
                      aria-label="Chevron Right"
                      icon={
                        <FiChevronRight
                          size="32px"
                          style={{ marginLeft: 'auto' }}
                        />
                      }
                      width="150px"
                      sx={{
                        background:
                          'linear-gradient(to right, transparent 20%,white 80%);',
                      }}
                      _hover={{
                        background:
                          'linear-gradient(to right, transparent 20%,white 80%);',
                      }}
                      _active={{
                        background:
                          'linear-gradient(to right, transparent 20%,white 80%);',
                      }}
                    />
                  )}
                </TabList>
              </Box>
              {status === 'loading' && isFetching ? (
                <Loader />
              ) : (
                status === 'success' &&
                isFetched && (
                  <TabPanels
                    backgroundColor="#F8F9F9"
                    padding="0px 45px 45px 45px"
                  >
                    {userCustomizations?.map(
                      (customize: CustomizationsData) => (
                        <TabPanel
                          key={customize.id}
                          data={customisation}
                          handleEditCustomization={handleEditCustomization}
                        />
                      )
                    )}
                  </TabPanels>
                )
              )}
            </>
          )}
        </Tabs>
      )}
      <Box marginInline="45px">
        <Box padding="32px 0px 16px 0px">
          <Flex justifyContent="space-between" alignItems="center">
            <Text color="#263042" fontSize="24px" fontWeight="700">
              Selection history
            </Text>
            <HStack>
              <Button
                colorScheme="teal"
                size="md"
                variant="ghost"
                onClick={handleViewSelectionHistoryPage}
              >
                View full page
              </Button>
            </HStack>
          </Flex>
        </Box>
        {isLoadingGetAllSelectionHistories ? (
          <Loader />
        ) : (
          <SelectionHistoryTable
            headers={SELECTION_HISTORY_TABLE_HEADERS}
            tableState={tableState}
          >
            <TableContent selectionHistories={selectionHistories} />
          </SelectionHistoryTable>
        )}
      </Box>
    </>
  );
};

export default DashboardHome;
