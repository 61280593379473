import React, { useContext } from 'react';
import {
  Box,
  UnorderedList,
  ListItem,
  Flex,
  ListIcon,
  Button,
  Image,
  useMediaQuery,
} from '@chakra-ui/react';
import Logo from 'assets/images/logo.png';
import { FiLogOut } from 'react-icons/fi';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SIDEBAR_NAV } from 'assets/constants/dashboard';
import LegalButtonGroup from 'components/molecules/LegalButtonGroup';
import { AuthContext } from 'context/auth';

const Sidebar = () => {
  const authContext = useContext(AuthContext);
  const currentLocation = useLocation();
  const navigate = useNavigate();
  const [isLargerThan1180] = useMediaQuery('(min-width: 1180px)');

  const handleClickLogo = () => {
    navigate('/dashboard');
  };

  const handleLogout = () => {
    authContext?.onLogout(navigate);
  };

  return (
    <Flex
      flexDir="column"
      w={isLargerThan1180 ? '' : '151px'}
      maxW="324px"
      padding="30px 45px"
      borderRight="1px solid #EFEFEF"
      alignItems={isLargerThan1180 ? '' : 'center'}
    >
      <Image
        src={Logo}
        maxW="80px"
        maxH="40px"
        onClick={handleClickLogo}
        cursor="pointer"
      />
      <Box as="nav" flex="1" paddingBlock="60px">
        <UnorderedList margin="0">
          {SIDEBAR_NAV.map(({ id, title, path, icon }) => (
            <ListItem
              key={id}
              listStyleType="none"
              marginBottom="20px"
              fontSize="18px"
              color={
                currentLocation?.pathname === path
                  ? 'text.secondary'
                  : 'text.basic'
              }
              fontWeight="semibold"
            >
              <Link to={path}>
                <ListIcon
                  as={icon}
                  boxSize="24px"
                  color={
                    currentLocation?.pathname === path
                      ? 'text.secondary'
                      : '#D4D5D8'
                  }
                  marginRight="16px"
                />
                {isLargerThan1180 && title}
              </Link>
            </ListItem>
          ))}
        </UnorderedList>
      </Box>
      <Flex flexDir="column" alignItems={isLargerThan1180 ? '' : 'center'}>
        <Box>
          <Button
            leftIcon={<FiLogOut color="#D4D5D8" size="24px" />}
            variant="link"
            fontSize="18px"
            color="text.basic"
            onClick={handleLogout}
          >
            {isLargerThan1180 && 'Logout'}
          </Button>
        </Box>
        <Flex
          gap={5}
          marginTop="32px"
          flexDir={isLargerThan1180 ? 'row' : 'column'}
        >
          <LegalButtonGroup />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Sidebar;
