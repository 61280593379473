import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import Sidebar from '../Sidebar';
import DashboardHeader from '../DashboardHeader';
import { DashboardLayoutProps } from 'interfaces/dashboard';
import { useProtectedRoute } from 'hooks/useProtectedRoute';

const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  useProtectedRoute();
  return (
    <>
      <Flex h="100vh" maxH="100vh">
        <Sidebar />
        <Box flex="1" h="100vh" overflow="auto">
          <DashboardHeader />
          {children}
        </Box>
      </Flex>
    </>
  );
};

export default DashboardLayout;
