import { Flex, Heading, Box, Text, Stack } from '@chakra-ui/react';

const LegalDisputesResolution = () => {
  return (
    <Flex flexDir="column">
      <Box>
        <Heading>Disputes Resolution</Heading>
        <Stack marginTop="32px">
          <Text>
            If You have any concern or dispute about the Service, You agree to
            first try to resolve the dispute informally by contacting the
            Company.
          </Text>
        </Stack>
      </Box>
    </Flex>
  );
};

export default LegalDisputesResolution;
