import React from 'react';
import { Flex } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';

const Loader = () => {
  return (
    <Flex
      width="100%"
      alignItems="center"
      justifyContent="center"
      margin="45px"
    >
      <Spinner
        thickness="6px"
        speed="0.8s"
        emptyColor="gray.200"
        color="brand.primary"
        size="xl"
      />
    </Flex>
  );
};

export default Loader;
