import React from 'react';
import { Heading, Text, Button, Image, HStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import AuthLayout from 'components/AuthLayout';
import GooglePlay from 'assets/images/google-play-badge.png';
import AppleStore from 'assets/images/apple-store-badge.svg';

const Home = () => {
  return (
    <AuthLayout>
      <Heading color={{ base: '#FFF', lg: 'text.basic' }}>
        Welcome to AGFIRST!
      </Heading>
      <Text
        textAlign="center"
        marginBottom="89px"
        color={{ base: '#FFF', lg: 'text.neutral' }}
      >
        Download the mobile app for a better experience
      </Text>
      <Link to="/login" className={styles.link}>
        <Button variant="solid" colorScheme="teal" size="md" w="100%">
          Get Started
        </Button>
      </Link>
      <Text textAlign="center" marginX="32px" color="text.neutral">
        or
      </Text>
      <HStack w="100%">
        <Link to="/">
          <Image
            m={0}
            flex={1}
            alt="Download on the Google Play"
            height="100px"
            src={GooglePlay}
          />
        </Link>

        <Link to="/">
          <Image
            src={AppleStore}
            alt="Download on the App Store"
            height="65px"
          />
        </Link>
      </HStack>
    </AuthLayout>
  );
};

export default Home;
