import { ICustomizationQuartiles } from 'interfaces/customizations';

export const INITIAL_EDIT_CUSTOMIZATION_DATA: ICustomizationQuartiles = {
  id: 0,
  user: 0,
  name: '',
  analysis_period: '',
  merchant: '',
  // location: '', NOTE: Commented out for temporary removal of location
  livestock: {
    id: 0,
    name: '',
    livestock_type: {
      id: 0,
      name: '',
      category: {
        id: 0,
        name: '',
      },
    },
    is_meat: false,
    is_available: false,
  },
  weight: '',
  indicator_schedule: 0,
  current_store_price: 0,
  average_store_price_percentage: 0,
  quartiles: {
    upper: 0,
    average: 0,
    lower: 0,
  },
};

export const INITIAL_CUSTOMISATION = {
  id: 0,
  user: 0,
  name: '',
  analysis_period: '',
  merchant: '',
  // location: '', NOTE: Commented out for temporary removal of location
  livestock: {
    id: 0,
    name: '',
    livestock_type: {
      id: 0,
      name: '',
      category: {
        id: 0,
        name: '',
      },
    },
    is_meat: false,
  },
  weight: '',
};
