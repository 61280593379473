import { instance } from 'http/axios';
import {
  LivestockAgeClass,
  LivestockCategories,
  LivestockTypes,
} from 'interfaces/livestocks';

const LIVESTOCK_CATEGORIES_ENDPOINT = '/livestocks/categories/';
const LIVESTOCK_TYPES_ENDPOINT = '/livestocks/types/';
const LIVESTOCK_AGE_CLASS_ENDPOINT = '/livestocks/';
const LIVESTOCK_WEIGHT_RANGE_ENDPOINT = '/livestocks/weight-ranges/';

export const getAllLivestockCategories = async () => {
  try {
    const res = await instance(LIVESTOCK_CATEGORIES_ENDPOINT);
    const data = res?.data;
    const isAvailableData = data?.filter(
      (category: LivestockCategories) => category?.is_available === true
    );

    return isAvailableData;
  } catch (error) {
    return [];
  }
};

export const getFilteredLivestockTypes = async (id: number) => {
  try {
    const res = await instance(`${LIVESTOCK_TYPES_ENDPOINT}?category=${id}`);
    const data = res?.data;

    const isAvailableData = data?.filter(
      (livestockType: LivestockTypes) => livestockType?.is_available === true
    );

    return isAvailableData;
  } catch (error) {
    return [];
  }
};

export const getFilteredLivestockAgeClass = async (id: number) => {
  try {
    const res = await instance(
      `${LIVESTOCK_AGE_CLASS_ENDPOINT}?livestock_type=${id}`
    );
    const data = res?.data;

    const isAvailableData = data?.filter(
      (ageClass: LivestockAgeClass) => ageClass?.is_available === true
    );

    return isAvailableData;
  } catch (error) {
    return error;
  }
};

export const getLivestockAgeClassById = async (id: number) => {
  try {
    const res = await instance(`${LIVESTOCK_AGE_CLASS_ENDPOINT}${id}`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getLivestockWeightRangeById = async (
  id: number,
  month: string
) => {
  try {
    const res = await instance(
      `${LIVESTOCK_WEIGHT_RANGE_ENDPOINT}?livestock=${id}&month=${month}`
    );
    return res.data;
  } catch (error) {
    return error;
  }
};
