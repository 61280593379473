import React from 'react';
import { useLocation } from 'react-router-dom';

const useIsRoutePathToDashboard = (path: string): boolean => {
  const location = useLocation();
  const isRoutePathToDashboard = location.pathname === path;
  return isRoutePathToDashboard;
};

export default useIsRoutePathToDashboard;
