import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from 'pages/Home';
import Login from 'pages/Login';
import Register from 'pages/Register';
import Dashboard from 'pages/Dashboard';
import PageNotFound from 'pages/PageNotFound';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import DashboardSettings from 'pages/DashboardSettings';
import DashboardInfo from 'pages/DashboardInfo';
import SelectionHistory from 'pages/SelectionHistory';
import LongTerm from 'pages/LongTerm';
import DashboardDefaultView from 'pages/DashboardDefaultView';
import DashboardCustomizedView from 'pages/DashboardCustomizedView';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import TermsConditions from 'pages/TermsConditions';
import SettingsSupport from 'pages/SettingsSupport';

function App() {
  return (
    <Router>
      <Routes>
        {/* HOME */}
        <Route path="/" element={<Home />} />

        {/* AUTHENTICATION */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/password/reset/" element={<ResetPassword />} />
        <Route path="/support" element={<SettingsSupport />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/terms-and-conditions"
          element={<TermsConditions />}
        />

        {/* DASHBOARD */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route
          path="/dashboard/selection-history"
          element={<SelectionHistory />}
        />
        <Route
          path="/dashboard/selection-history/long-term"
          element={<LongTerm />}
        />
        <Route
          path="/dashboard/selection-history/short-term"
          element={<DashboardDefaultView />}
        />
        <Route
          path="/dashboard/view/customized"
          element={<DashboardCustomizedView />}
        />
        <Route path="/dashboard/information" element={<DashboardInfo />} />
        <Route path="/dashboard/settings" element={<DashboardSettings />} />

        {/* SETTINGS */}
        <Route
          path="/dashboard/settings/support"
          element={<SettingsSupport />}
        />

        {/* LEGAL PAGES */}
        <Route path="/dashboard/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/dashboard/terms-and-conditions"
          element={<TermsConditions />}
        />

        {/* PAGE NOT FOUND */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
