import React from 'react';
import { Box, ModalBody, ModalHeader } from '@chakra-ui/react';
import Slider from 'components/atoms/Slider';
import { SliderPropsType } from 'interfaces/dashboard';

const AdjustCurrentMarket = ({
  sliderState,
  sliderSettings,
}: SliderPropsType) => {
  return (
    <>
      <ModalHeader
        textAlign="center"
        padding="2rem 2rem 1rem 2rem"
        fontSize="24px"
        fontWeight="bold"
        color="text.basic"
      >
        Adjust current market %
      </ModalHeader>
      <ModalBody>
        <Box pt="6" pb="2">
          <Slider sliderState={sliderState} sliderSettings={sliderSettings} />
        </Box>
      </ModalBody>
    </>
  );
};

export default AdjustCurrentMarket;
