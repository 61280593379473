import React from 'react';
import {
  FormControl,
  Input,
  InputGroup,
  ModalBody,
  ModalHeader,
} from '@chakra-ui/react';

type ForecastAverageScheduleType = {
  forecastAverageSchedule: number;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const ForecastAverageSchedule = ({
  forecastAverageSchedule,
  handleChange,
}: ForecastAverageScheduleType) => {
  return (
    <>
      <ModalHeader
        textAlign="center"
        padding="2rem 2rem 1rem 2rem"
        fontSize="24px"
        fontWeight="bold"
        color="text.basic"
      >
        Forecast Average Schedule
      </ModalHeader>
      <ModalBody>
        <FormControl display="flex" justifyContent="center" alignItems="center">
          <InputGroup maxW="343px">
            <Input
              type="text"
              variant="flushed"
              placeholder="Forecast Average Schedule"
              _placeholder={{ textAlign: 'center' }}
              _focusWithin={{ borderColor: '#EFEFEF', boxShadow: 'none' }}
              onChange={(e) => handleChange(e)}
              value={forecastAverageSchedule}
            />
          </InputGroup>
        </FormControl>
      </ModalBody>
    </>
  );
};

export default ForecastAverageSchedule;
