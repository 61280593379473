import React, { useState, useLayoutEffect } from 'react';

const useIsOverflow = (
  ref: React.RefObject<HTMLInputElement>,
  callback?: (isOverflowFromCallback: boolean) => void
) => {
  const [isOverflow, setIsOverflow] = useState<boolean | undefined>(undefined);

  useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow = current
        ? current?.scrollWidth > current?.clientWidth
        : false;

      setIsOverflow(hasOverflow);

      if (callback) callback(hasOverflow);
    };

    if (current) {
      if ('ResizeObserver' in window) {
        new ResizeObserver(trigger).observe(current);
      }

      trigger();
    }
  }, [callback, ref]);

  return isOverflow;
};

export default useIsOverflow;
