type LoginInput = {
  id: number;
  label?: string;
  type: string;
  placeholder?: string;
  name: 'email' | 'password';
};

export const LOGIN_INPUTS: LoginInput[] = [
  {
    id: 1,
    label: 'Email address:',
    type: 'email',
    placeholder: 'ex. johndoe@gmail.com',
    name: 'email',
  },
  {
    id: 2,
    label: 'Password:',
    type: 'password',
    placeholder: 'Minimum of 8 characters',
    name: 'password',
  },
];

export const LOGIN_DEFAULT_VALUES = {
  email: '',
  password: '',
};

export const DEFAULT_TOKEN_EXPIRY_DAYS = 1
export const SIGN_IN_TOKEN_EXPIRY_DAYS = 7