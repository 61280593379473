import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import { Button } from '@chakra-ui/react';
import { AuthContext } from 'context/auth';
import {
  PRIVACY_DASHBOARD_ROUTE,
  PRIVACY_ROUTE,
  TERMS_DASHBOARD_ROUTE,
  TERMS_ROUTE,
} from 'assets/constants/legals';

const LegalButtonGroup = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const isAuthenticated = authContext?.isAuthenticated;

  const handleClickLegal = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { name } = event.target as HTMLInputElement;
    if (name === 'privacy') {
      navigate(isAuthenticated ? PRIVACY_DASHBOARD_ROUTE : PRIVACY_ROUTE);
    } else {
      navigate(isAuthenticated ? TERMS_DASHBOARD_ROUTE : TERMS_ROUTE);
    }
  };

  const utilityButtonStyles = {
    variant: 'link',
    fontSize: '14px',
    color: 'text.basic',
    fontWeight: 400,
    lineHeight: '18px',
    onClick: (event: React.MouseEvent<HTMLButtonElement>) =>
      handleClickLegal(event),
  };

  return (
    <>
      <Button {...utilityButtonStyles} name="privacy">
        Privacy Policy
      </Button>
      <Button {...utilityButtonStyles} name="terms">
        Terms & Conditions
      </Button>
    </>
  );
};
export default LegalButtonGroup;
