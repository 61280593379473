import { useState, useEffect } from 'react';
import { addMonths, subMonths } from 'date-fns';

const defaultDatepickerProps = {
  minDate: null,
  maxDate: null,
};

const useDateProps = (dateName: string, monthLimit: number = 3) => {
  const [dateProps, setDateProps] = useState<{
    minDate: Date | null;
    maxDate: Date | null;
  }>(defaultDatepickerProps);

  useEffect(() => {
    if (dateName && dateName === 'indicatorSchedule') {
      setDateProps({
        minDate: subMonths(new Date(), monthLimit),
        maxDate: new Date(),
      });
    }

    if (dateName && dateName === 'expectedPrice') {
      setDateProps({
        minDate: new Date(),
        maxDate: addMonths(new Date(), monthLimit),
      });
    }
  }, [dateName, monthLimit]);

  return dateProps;
};

export default useDateProps;
