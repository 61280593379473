import React, { useContext, useState, useEffect, useCallback } from 'react';
import {
  TabPanel as ChakraTabPanel,
  Box,
  Flex,
  Text,
  HStack,
  Tag,
  Button,
  Card,
  CardBody,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Center,
  Container,
  useBoolean,
  useDisclosure,
  Portal,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { NewCustomizationContext } from 'context/newCustomization';
import { FiInfo } from 'react-icons/fi';
import {
  processAgeClassDisplayText,
  getExpectedStorePrice,
  getPerHead,
  getCentsPerKilo,
  putCommaNumbers,
} from 'utils';
import {
  getCustomisationIndicatorScheduleByDate,
  getCustomisationExpectedStorePricePercentageByDate,
} from 'services/Customizations';
import { getLivestockAgeClassById } from 'services/Livestock';
import { LivestockAgeClass } from 'interfaces/livestocks';
import {
  CustomizationsData,
  ICustomizationQuartiles,
} from 'interfaces/customizations';
import { QUERY_DATE_FORMAT, CHART_MARKS } from 'assets/constants/dashboard';
import DatePickerModal from 'components/Modal/DatePickerModal';
import AdjustCurrentMarketModal from 'components/Modal/AdjustCurrentMarketModal';
import Charts from 'components/molecules/Charts';
import Tooltip from 'components/atoms/Tooltip';
import ChartMarks from 'components/molecules/Charts/ChartMarks';
import ChartQuartiles from 'components/molecules/Charts/ChartQuartiles';
import EditTags from 'components/molecules/EditTags';
import useEditCustomisation from 'hooks/useEditCustomisation';
import { INITIAL_EDIT_CUSTOMIZATION_DATA } from 'assets/constants/customizations';

type TabPanelType = {
  handleEditCustomization: (step: number) => void;
  data: ICustomizationQuartiles;
};

const TabPanel = ({ handleEditCustomization, data }: TabPanelType) => {
  const editCustomizationContext = useContext(NewCustomizationContext);
  const [customizationValues, setCustomizationValues] =
    useState<ICustomizationQuartiles>(data);
  const [editValues, setEditValues] = useState<CustomizationsData>(data);
  const [adjustCurrentMarket, setAdjustCurrentMarket] = useState<number>(0);
  const [isPerHead, setIsPerHead] = useBoolean();
  const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useBoolean();
  const customizationId = customizationValues.id;

  const {
    isEditCustomization,
    editCustomizations,
    currentCustomizationId,
    currentMarketPercentage,
    todaysDate,
    indicatorScheduleDate,
    expectedPriceDate,
  } = editCustomizationContext?.state;

  const isCurrentTab = currentCustomizationId === customizationId;
  const { weight } = customizationValues;
  const stockType = customizationValues?.livestock?.livestock_type;
  const ageClass = customizationValues?.livestock;

  const {
    indicator_schedule,
    current_store_price,
    average_store_price_percentage,
  } = customizationValues;
  const { upper, lower } = customizationValues?.quartiles;
  const isQuartilesEmpty =
    customizationValues?.quartiles &&
    Object.keys(customizationValues?.quartiles).length === 0 &&
    Object.getPrototypeOf(customizationValues?.quartiles) === Object.prototype;
  const formattedAgeClass = processAgeClassDisplayText(
    isEditCustomization ? editValues?.livestock?.name : ageClass?.name,
    isEditCustomization
      ? editValues?.livestock?.livestock_type?.name
      : stockType?.name
  );
  const expectedStorePrice = getExpectedStorePrice(
    indicator_schedule,
    average_store_price_percentage
  );
  const isLivestockAvailable = customizationValues?.livestock?.is_available;
  const {
    isOpen: isOpenAdjustCurrentMarket,
    onOpen: onOpenAdjustCurrentMarket,
    onClose: onCloseAdjustCurrentMarket,
  } = useDisclosure();

  const {
    editCustomization,
    cancelEditCustomization,
    handleUpdateCustomization,
  } = useEditCustomisation();

  const handleOpenDatePickerModal: React.MouseEventHandler<
    HTMLParagraphElement | HTMLSpanElement
  > = (event) => {
    const editDateIndicator = event?.currentTarget?.id;
    editCustomizationContext?.dispatch({
      type: 'UPDATE_CURRENT_EDIT_DATE',
      payload: editDateIndicator,
    });
    setIsDatePickerModalOpen.on();
  };

  const displayAdjustedMarket = () => {
    const isPercentageAdjusted = currentMarketPercentage !== 0;
    if (isPercentageAdjusted) {
      return getCentsPerKilo(adjustCurrentMarket);
    }

    // Default return expected store price
    return getCentsPerKilo(expectedStorePrice);
  };

  const displayPerHeadAdjustedMarket = () => {
    const isPercentageAdjusted = currentMarketPercentage !== 0;
    if (isPercentageAdjusted) {
      return getPerHead(adjustCurrentMarket, parseInt(weight));
    }

    // Default return expected store price
    return getPerHead(expectedStorePrice, parseInt(weight));
  };

  const displayUnit = () => {
    return isPerHead ? 'head' : 'kilo';
  };

  const QUARTILE_VALUES = [
    { name: 'Lower', quartile: lower },
    {
      name: 'Average',
      quartile: average_store_price_percentage,
    },
    {
      name: 'Upper',
      quartile: upper,
    },
  ];

  const getNewIndicatorScheduleValue = useCallback(
    async (newIndicatorScheduleDate: Date) => {
      const date = format(
        new Date(newIndicatorScheduleDate),
        QUERY_DATE_FORMAT
      );
      const indicatorScheduleData =
        await getCustomisationIndicatorScheduleByDate(customizationId, date);
      setCustomizationValues((prevState) => ({
        ...prevState,
        indicator_schedule: indicatorScheduleData?.indicator_schedule,
      }));
    },
    [indicatorScheduleDate]
  );

  const getNewExpectedStorePriceValue = useCallback(
    async (newExpectedPriceDate: Date) => {
      const date = format(new Date(newExpectedPriceDate), QUERY_DATE_FORMAT);
      const expectedPricePercentageData =
        await getCustomisationExpectedStorePricePercentageByDate(
          customizationId,
          date
        );
      setCustomizationValues((prevState) => ({
        ...prevState,
        average_store_price_percentage:
          expectedPricePercentageData?.average_store_price_percentage,
      }));
    },
    [expectedPriceDate]
  );

  useEffect(() => {
    editCustomizationContext?.dispatch({
      type: 'UPDATE_CURRENT_CUSTOMIZATION_ID',
      payload: data?.id,
    });
  }, [data?.id]);

  useEffect(() => {
    // Validation to filter other tab lists to avoid rewriting the data since this component is rerendered with the other tab list values
    if (isEditCustomization && isCurrentTab) {
      setEditValues((prevValue: CustomizationsData) => ({
        ...prevValue,
        merchant: editCustomizations.merchant,
      }));
    }
  }, [editCustomizations.merchant]);

  // NOTE: Commented out for temporary removal of location
  // useEffect(() => {
  //   // Validation to filter other tab lists to avoid rewriting the data since this component is re=rendered with the other tab list values
  //   if (isEditCustomization && isCurrentTab) {
  //     setEditValues((prevValue: CustomizationsData) => ({
  //       ...prevValue,
  //       location: editCustomizations.location,
  //     }));
  //   }
  // }, [editCustomizations.location]);

  useEffect(() => {
    // Validation to filter other tab lists to avoid rewriting the data since this component is rerendered with the other tab list values
    if (isEditCustomization && isCurrentTab) {
      setEditValues((prevValue: CustomizationsData) => ({
        ...prevValue,
        weight: editCustomizations.weight,
      }));
    }
  }, [editCustomizations.weight]);

  useEffect(() => {
    setCustomizationValues(data);
  }, [data]);

  useEffect(() => {
    if (currentMarketPercentage !== 0) {
      const newAdjustedPercentage =
        average_store_price_percentage + currentMarketPercentage;
      const adjustedExpectedStorePrice = getExpectedStorePrice(
        indicator_schedule,
        newAdjustedPercentage
      );
      setAdjustCurrentMarket(adjustedExpectedStorePrice);
    }
  }, [currentMarketPercentage]);

  return (
    <ChakraTabPanel padding="10px 0px">
      <Box paddingBlock="16px">
        <Flex justifyContent="space-between">
          <Flex gap="2rem">
            <Flex gap="1rem" alignItems="center">
              <Text fontSize="12px">Today’s date</Text>
              <Text
                id="todaysDate"
                color="teal"
                fontWeight="500"
                onClick={(e) => handleOpenDatePickerModal(e)}
                cursor="pointer"
              >
                {todaysDate}
              </Text>
            </Flex>
            <Flex
              gap="1rem"
              alignItems="center"
              onClick={onOpenAdjustCurrentMarket}
              cursor="pointer"
            >
              <Text fontSize="12px">Adjust current market</Text>
              <Text color="teal" fontWeight="500">
                {currentMarketPercentage}%
              </Text>
            </Flex>
          </Flex>
          <HStack
            p="5px"
            backgroundColor="#EFEFEF"
            borderRadius="6px"
            gap="6px"
          >
            <Tag
              backgroundColor={isPerHead ? 'transparent' : '#fff'}
              boxShadow="0px 4px 12px rgba(194, 206, 214, 0.2)"
              borderRadius="6px"
              p="5px"
              onClick={() => setIsPerHead.toggle()}
              cursor="pointer"
            >
              ¢ per kilogram
            </Tag>
            <Tag
              backgroundColor={isPerHead ? '#fff' : 'transparent'}
              boxShadow="0px 4px 12px rgba(194, 206, 214, 0.2)"
              borderRadius="6px"
              p="5px"
              onClick={() => setIsPerHead.toggle()}
              cursor="pointer"
            >
              $ per head
            </Tag>
          </HStack>
        </Flex>
      </Box>
      <Box backgroundColor="#fff" borderRadius="8px" p="26px">
        <Flex alignItems="center" paddingBottom="22px">
          <Box flex={1}>
            {customizationValues !== undefined && (
              <EditTags
                customisation={customizationValues}
                editValues={editValues}
                handleEditCustomization={handleEditCustomization}
              />
            )}
          </Box>
          {isEditCustomization && (
            <Button
              colorScheme="teal"
              variant="ghost"
              size="lg"
              fontWeight="700"
              onClick={() =>
                handleUpdateCustomization(
                  editCustomizations,
                  customizationId,
                  setEditValues,
                  setCustomizationValues
                )
              }
            >
              Save
            </Button>
          )}
          {isEditCustomization ? (
            <Button
              name="cancel"
              colorScheme="gray"
              variant="ghost"
              size="lg"
              fontWeight="700"
              onClick={() =>
                cancelEditCustomization(customizationValues, setEditValues)
              }
            >
              Cancel
            </Button>
          ) : (
            <Button
              name="edit"
              colorScheme="teal"
              variant="ghost"
              size="lg"
              fontWeight="700"
              onClick={() => editCustomization(editValues, customizationValues)}
            >
              Edit
            </Button>
          )}
        </Flex>
        <Flex>
          <Flex flex="1" flexDir="column" justifyContent="space-between">
            {/*NOTE: Create another card component*/}
            <Card marginBottom="12px">
              <CardBody>
                <Stat>
                  <Flex justifyContent="space-between">
                    <StatLabel>Indicator schedule</StatLabel>
                    <Box>
                      <Tag
                        id="indicatorSchedule"
                        backgroundColor="#EFEFEF"
                        boxShadow="0px 4px 12px rgba(194, 206, 214, 0.2)"
                        borderRadius="6px"
                        p="5px"
                        onClick={(e) => handleOpenDatePickerModal(e)}
                        cursor="pointer"
                      >
                        {indicatorScheduleDate}
                      </Tag>
                    </Box>
                  </Flex>
                  <Flex alignItems="flex-end" gap="4px">
                    <StatNumber>
                      {`${getCentsPerKilo(indicator_schedule)}`}
                    </StatNumber>
                    <StatHelpText marginBottom="4px">
                      cents per kilo
                    </StatHelpText>
                  </Flex>
                </Stat>
              </CardBody>
            </Card>

            <Card marginBottom="12px">
              <CardBody>
                <Stat>
                  <Flex justifyContent="space-between">
                    <StatLabel>Current store price</StatLabel>
                  </Flex>
                  <Flex alignItems="flex-end" gap="4px">
                    {!current_store_price ? (
                      <Tooltip label="No Data Available " icon={FiInfo} />
                    ) : (
                      <>
                        <StatNumber>
                          {isPerHead
                            ? `$${putCommaNumbers(
                                getPerHead(
                                  current_store_price,
                                  parseInt(weight)
                                )
                              )}`
                            : `${getCentsPerKilo(current_store_price)}`}
                        </StatNumber>
                        <StatHelpText marginBottom="4px">
                          {!isPerHead && 'cents'} per {displayUnit()}
                        </StatHelpText>
                      </>
                    )}
                  </Flex>
                </Stat>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Stat>
                  <Flex justifyContent="space-between">
                    <StatLabel color={isLivestockAvailable ? '' : 'text.logo'}>
                      Expected store price
                    </StatLabel>
                    <Box>
                      <Tag
                        id="expectedPrice"
                        backgroundColor="#EFEFEF"
                        boxShadow="0px 4px 12px rgba(194, 206, 214, 0.2)"
                        borderRadius="6px"
                        p="5px"
                        onClick={
                          isLivestockAvailable
                            ? (e) => handleOpenDatePickerModal(e)
                            : undefined
                        }
                        color={isLivestockAvailable ? '' : 'text.logo'}
                        cursor={
                          isLivestockAvailable ? 'pointer' : 'not-allowed'
                        }
                      >
                        {expectedPriceDate}
                      </Tag>
                    </Box>
                  </Flex>
                  <Flex alignItems="flex-end" gap="4px">
                    {!average_store_price_percentage ||
                    !isLivestockAvailable ? (
                      <Tooltip label="No Data Available " icon={FiInfo} />
                    ) : (
                      <>
                        <StatNumber>
                          {isPerHead
                            ? `$${putCommaNumbers(
                                displayPerHeadAdjustedMarket()
                              )}`
                            : `${displayAdjustedMarket()}`}
                        </StatNumber>
                        <StatHelpText marginBottom="4px">
                          {!isPerHead && 'cents'} per {displayUnit()}
                        </StatHelpText>
                      </>
                    )}
                  </Flex>
                </Stat>
              </CardBody>
            </Card>
          </Flex>
          <Box flex="2.5">
            <Container maxW="90%" marginRight={0}>
              {/* Need to improve implementation for rendering same conditional rendering on multiple components */}
              {isQuartilesEmpty ? (
                <Center p="32px">
                  <Text fontSize="4xl">No Data Available</Text>
                </Center>
              ) : (
                <>
                  <HStack>
                    <Text fontWeight="600">
                      Store price relative to current schedule
                    </Text>
                    <Text color="#6B6C75">
                      {`${formattedAgeClass} store ${stockType?.name} price`}
                    </Text>
                  </HStack>
                  <Charts
                    height={300}
                    role={data.merchant}
                    quartile={QUARTILE_VALUES}
                    indicatorSchedule={indicator_schedule}
                  />
                  <Flex
                    position="relative"
                    display="grid"
                    gridTemplateColumns="25% 10% 25%"
                    justifyContent="center"
                    gap="20px"
                    fontSize="12px"
                  >
                    {QUARTILE_VALUES.map((quartileValue) => (
                      <ChartQuartiles
                        key={quartileValue.name}
                        name={quartileValue.name}
                        quartile={quartileValue.quartile}
                      />
                    ))}
                  </Flex>
                </>
              )}
            </Container>
          </Box>
        </Flex>
      </Box>
      <Portal>
        <DatePickerModal
          openModal={isDatePickerModalOpen}
          closeModal={() => setIsDatePickerModalOpen.off()}
          handleGetIndicatorSchedule={getNewIndicatorScheduleValue}
          handleGetExpectedStorePrice={getNewExpectedStorePriceValue}
        />
        <AdjustCurrentMarketModal
          openModal={isOpenAdjustCurrentMarket}
          closeModal={onCloseAdjustCurrentMarket}
        />
      </Portal>
    </ChakraTabPanel>
  );
};

export default TabPanel;
