import React from 'react';
import { ModalBody, ModalHeader, Box } from '@chakra-ui/react';

const MaxCustomization = () => {
  return (
    <>
      <ModalHeader
        textAlign="center"
        padding="2rem 2rem 1rem 2rem"
        fontSize="24px"
        fontWeight="bold"
        color="text.basic"
      >
        Warning
      </ModalHeader>
      <ModalBody>
        <Box alignItems="center" textAlign="center">
          You’ve reached the max amount of customisations (12) you will only be
          able to view new calculations. Do you wish to proceed?
        </Box>
      </ModalBody>
    </>
  );
};

export default MaxCustomization;
