import {
  Flex,
  Heading,
  UnorderedList,
  ListItem,
  Stack,
  Box,
  Text,
} from '@chakra-ui/react';
import { DATA_COLLECTED } from 'assets/constants/legals';

type LegalCollectPersonalDataType = {
  datas: typeof DATA_COLLECTED;
};

const LegalCollectPersonalData = ({ datas }: LegalCollectPersonalDataType) => {
  return (
    <Flex flexDir="column" gap={10}>
      <Heading>Collecting and Using Your Personal Data</Heading>
      <Heading size="lg">Types of Data Collected</Heading>
      <Box>
        <Heading size="lg">Personal Data</Heading>
        <Text marginY="32px">
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </Text>
        <UnorderedList
          style={{
            paddingLeft: '20px',
          }}
        >
          {datas.map((data) => {
            return <ListItem key={data?.id}>{data?.data}</ListItem>;
          })}
        </UnorderedList>
      </Box>
      <Box>
        <Heading size="lg">Usage Data</Heading>
        <Stack marginTop="32px" spacing={3}>
          <Text>
            Usage Data is collected automatically when using the Service.
          </Text>
          <Text>
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
          </Text>
          <Text>
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
          </Text>
          <Text>
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </Text>
        </Stack>
      </Box>
    </Flex>
  );
};

export default LegalCollectPersonalData;
