import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLocalStorage } from 'utils';
import { KEYS } from 'enums/localstorage-keys';

export const useProtectedRoute = () => {
  const token = getLocalStorage(KEYS.TOKEN);
  const expirationDate = getLocalStorage(KEYS.EXPIRY_DATE);

  const navigate = useNavigate();

  useEffect(() => {
    if (!token || !expirationDate) {
      return navigate('/login');
    }
    const currentDate = new Date();
    if (currentDate > new Date(expirationDate)) {
      localStorage.clear();
      return navigate('/login');
    }
  }, [token, expirationDate]);
};
