import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import DashboardAvatar from '../Avatar';
import ProfileModal from 'components/Modal/ProfileModal';
import Breadcrumbs from '../Breadcrumbs';

const DashboardHeader = () => {
  return (
    <Box h="10vh" borderBottom="1px solid #EFEFEF" paddingInline="45px">
      <Flex justifyContent="space-between" alignItems="center" h="100%">
        <Breadcrumbs />
        <DashboardAvatar />
        <ProfileModal />
      </Flex>
    </Box>
  );
};

export default DashboardHeader;
