import type { FieldErrorsImpl } from 'react-hook-form';
import { format } from 'date-fns';
import AgeClass from 'components/Modal/ModalContent/AgeClass';
import Roles from 'components/Modal/ModalContent/Roles';
import LiveWeight from 'components/Modal/ModalContent/LiveWeight';
import Location from 'components/Modal/ModalContent/Location';
import Stocks from 'components/Modal/ModalContent/Stocks';
import StockTypes from 'components/Modal/ModalContent/StockTypes';
import Terms from 'components/Modal/ModalContent/Terms';
import { EDIT_STEP } from 'enums/customizations';
import { CustomizationsData, IStock } from 'interfaces/customizations';
import { ISelectionHistory } from 'interfaces/selection-history';

export const VALIDATION_ERROR_MESSAGE = (label: string) =>
  `${label} is a required field`;

/** Checks if the error object in useForm has a value and gets the message property in the error object */
export const errorMessageChecker = (
  name: string,
  errors: Partial<FieldErrorsImpl>
) => {
  const errorObj = {
    title: errors[name],
    msg: errors[name]?.message?.toString(), //throws a type error when no converted to a string
  };

  return errorObj;
};

/** Checks if the error object in useForm has a value and gets the message property in the error object */
export const capitalizeFirstLetter = (str: string) =>
  `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

export const lowerCaseFirstLetter = (str: string) =>
  `${str.charAt(0).toLowerCase()}${str.slice(1)}`;

export const toTitleCase = (string: string) => {
  return string
    .replaceAll(/(_|-)/g, ' ')
    .replaceAll(/(^\w|\s\w)/g, (firstCharOfWord) =>
      firstCharOfWord.toUpperCase()
    )
    .trim();
};

// Note: This way of rounding a number is needed to avoid certain values that causes a bug
//       where the round up value is wrong, didn't use toFixed method because it causes this bug.
//       (e.g.when rounding 1.005 to two decimal places, it will round down to 1.00 instead of 1.05)
export const roundTwoDecimalNumber = (value: number) => {
  return Math.round((value + Number.EPSILON) * 100) / 100;
};

export const getLocalStorage = (key: string): string => {
  const localStorageValue = localStorage.getItem(key);
  if (localStorageValue) {
    const parsedValue = JSON.parse(localStorageValue);
    return parsedValue;
  }
  return '';
};

export const processAgeClassDisplayText = (
  name: string,
  replaceWord: string
) => {
  const removeStockTypeText = name.replace(replaceWord, '');
  const titleCaseName = toTitleCase(removeStockTypeText);
  if (titleCaseName === 'Ma') {
    return 'Mixed Age';
  } else {
    return titleCaseName;
  }
};

export const renderModalContent = (steps: string | number | undefined) => {
  switch (steps) {
    case 1:
      return <Roles />;
    // NOTE: Commented out for temporary removal of location
    // case 2:
    //   return <Location />;
    case 2:
      return <Stocks />;
    case 3:
      return <StockTypes />;
    case 4:
      return <AgeClass />;
    case 5:
      return <LiveWeight />;
    default:
      return <Terms />;
  }
};

export const computePercentageQuartiles = (
  percent: number,
  indicatorSchedule: number
) => {
  const percentToDecimal = percent / 100;
  const computedQuartile = indicatorSchedule * percentToDecimal;
  return percent ? Math.round(computedQuartile) : 'N/A';
};

export const getExpectedStorePrice = (
  indicator_schedule: number,
  average_store_price_percentage: number
) => {
  const computedExpectedStorePrice =
    indicator_schedule * (average_store_price_percentage / 100);
  return computedExpectedStorePrice;
};

export const getPerHead = (value: number, weight: number) => {
  return roundTwoDecimalNumber((value * weight) / 100);
};

export const getCentsPerKilo = (value: number) => {
  return Math.round(value);
};

export const putCommaNumbers = (value: number) => {
  return value.toLocaleString(undefined, {
    maximumFractionDigits: 0,
  });
};

export const calculateScaler = (value: number, scaler: number) => {
  return Math.round(value * (scaler / 100) + value);
};

export const getChoicesFromCustomization = (
  customisation: CustomizationsData
) => {
  const {
    analysis_period,
    merchant,
    // location NOTE: Commented out for temporary removal of location
  } = customisation;
  const stock = customisation?.livestock?.livestock_type?.category;
  const stockType = customisation?.livestock?.livestock_type;
  const ageClass = customisation?.livestock;
  const choices = [
    analysis_period,
    merchant,
    // location, NOTE: Commented out for temporary removal of location
    stock.name,
    stockType.name,
    ageClass.name,
  ];
  return choices;
};

export const arraysHaveSameValues = (array1: any, array2: any) => {
  // changies these to typescript
  if (array1.length !== array2.length) {
    return false; // If the arrays have different lengths, they can't have the same values
  }

  return array1.every((value: any) => array2.includes(value));
};

export const formatWeight = (weight: string) => weight?.split('.')[0];
export const getSelectedLivestock = (
  editStock: IStock,
  livestockId: number
) => {
  const selectedLivestock = {
    id: livestockId,
    name: editStock?.livestock_name,
    livestock_type: {
      id: editStock?.livestock_type,
      name: editStock?.livestock_type_name,
      category: {
        id: editStock?.category,
        name: editStock?.category_name,
      },
    },
    is_meat: false,
    is_available: true,
  };

  return selectedLivestock;
};

export const displayStatusColor = (history: ISelectionHistory) => {
  const upperQuartile = Math.round(
    (history?.indicator_schedule * history?.quartiles?.upper) / 100
  );
  const lowerQuartile = Math.round(
    (history?.indicator_schedule * history?.quartiles?.lower) / 100
  );

  if (!history?.quartiles?.upper || !history?.quartiles?.lower)
    return 'status.basic';

  if (history?.current_store_price >= upperQuartile)
    return history?.merchant === 'buyer' ? 'status.red' : 'status.green';

  if (history?.current_store_price <= lowerQuartile)
    return history?.merchant === 'buyer' ? 'status.green' : 'status.red';

  return 'status.orange';
};

export const getNumericalMonth = () => {
  const currentDate = new Date();
  const monthNumber = format(currentDate, 'M');
  return monthNumber;
};
