import React, { useContext } from 'react';
import { Box, Button, Heading, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import AuthLayout from 'components/AuthLayout';
import LoginForm from 'components/Form/LoginForm';
import Alert from 'components/atoms/Alert';
import { AuthContext } from 'context/auth';

const Login = () => {
  const authContext = useContext(AuthContext);

  return (
    <AuthLayout>
      <Box textAlign="center">
        <Heading>Welcome back! Please login</Heading>
      </Box>
      {authContext?.error && (
        <Alert status="error">
          {authContext?.error?.map((err: string, i: number) => (
            <Text key={i}>{err}</Text>
          ))}
        </Alert>
      )}
      <LoginForm />
      <Link to="/register">
        <Button color="text.basic" variant="link">
          I don’t have an account
        </Button>
      </Link>
    </AuthLayout>
  );
};

export default Login;
