type ChangePasswordInput = {
  id: number;
  label?: string;
  type: string;
  placeholder?: string;
  name: 'old_password' | 'new_password1' | 'new_password2';
};

export const CHANGE_PASSWORD_INPUTS: ChangePasswordInput[] = [
  {
    id: 1,
    label: 'Current Password',
    type: 'password',
    placeholder: 'Type your current password here',
    name: 'old_password',
  },
  {
    id: 2,
    label: 'New Password',
    type: 'password',
    placeholder: 'Minimum of 8 characters',
    name: 'new_password1',
  },
  {
    id: 3,
    label: 'Confirm Password',
    type: 'password',
    placeholder: 'Retype password here',
    name: 'new_password2',
  },
];

export const CHANGE_PASSWORD_VALUES = {
  old_password: '',
  new_password1: '',
  new_password2: '',
};
