import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Portal,
  useBoolean,
} from '@chakra-ui/react';

import { ModalProps } from 'interfaces/modal';
import PriceCalculationView from '../ModalContent/PriceCalculationView';
import { useNavigate } from 'react-router-dom';
import CustomizationNameModal from '../CustomizationNameModal';
import { NewCustomizationContext } from 'context/newCustomization';
import { addSelectionHistory } from 'services/SelectionHistory';
import { ISelectionHistoryData } from 'interfaces/selection-history';
import { SelectionHistoryContext } from 'context/selectionHistoryContext';
import { DEFAULT_NAME } from 'assets/constants/selection-history';

const CalculationViewModal = ({ openModal, closeModal }: ModalProps) => {
  const newCustomizationContext = useContext(NewCustomizationContext);
  const selectionHistoryContext = useContext(SelectionHistoryContext);
  const [userChoice, setUserChoice] = useState<string | null>(null);
  const [isCustomizationNameModalOpen, setIsCustomizationNameModalOpen] =
    useBoolean();
  const [isLoading, setIsLoading] = useBoolean();

  const { customizations } = newCustomizationContext?.state;
  const {
    analysis_period,
    merchant,
    // location, NOTE: Commented out for temporary removal of location
    livestock,
    weight,
  } = customizations;

  const navigate = useNavigate();

  const updateUserChoice = (choice: string) => {
    setUserChoice(choice);
  };

  const handleNext = async () => {
    if (userChoice === 'default') {
      try {
        newCustomizationContext?.dispatch({
          type: 'UPDATE_PROCEED_VIEW_LATEST_PRICE',
          payload: false,
        });

        const selectionHistoryData: ISelectionHistoryData = {
          analysis_period,
          merchant,
          // location, NOTE: Commented out for temporary removal of location
          livestock,
          weight,
        };

        setIsLoading.on();

        const history = await addSelectionHistory(selectionHistoryData);

        // Destructure props used for initializing the edit customization in context
        const stock = history?.livestock?.livestock_type?.category;
        const stockType = history?.livestock?.livestock_type;
        const ageClass = history?.livestock;

        // Update the edit customization context?
        newCustomizationContext?.dispatch({
          type: 'UPDATE_EDIT_CUSTOMIZATION',
          payload: {
            name: DEFAULT_NAME,
            analysis_period: analysis_period,
            merchant: merchant,
            // location: location, NOTE: Commented out for temporary removal of location
            category: {
              id: stock?.id,
              name: stock?.name,
            },
            livestockType: {
              id: stockType?.id,
              name: stockType?.name,
            },
            ageClass: {
              id: ageClass?.id,
              name: ageClass?.name,
            },
            weight: weight,
          },
        });

        selectionHistoryContext?.dispatch({
          type: 'SET_SELECTION_HISTORY_DETAILS',
          payload: history,
        });
        setIsLoading.off();

        if (analysis_period === 'short') {
          navigate('/dashboard/selection-history/short-term');
        } else {
          navigate('/dashboard/selection-history/long-term');
        }
        return;
      } catch (error) {
        setIsLoading.off();
        return error;
      }
    }
    if (userChoice === 'customized') {
      closeModal();
      setIsCustomizationNameModalOpen.on();
      return;
    }
  };

  useEffect(() => {
    setUserChoice(null);
  }, [openModal]);

  return (
    <>
      <Modal
        isOpen={openModal}
        onClose={closeModal}
        isCentered
        autoFocus={false}
      >
        <ModalOverlay />
        <ModalContent data-testid="calculation-view-modal">
          <PriceCalculationView updateUserChoice={updateUserChoice} />
          <ModalCloseButton />
          <ModalFooter
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            gap="0.5rem"
            paddingBlock="1.5rem"
          >
            <Button
              colorScheme="teal"
              w="100%"
              maxW="300px"
              onClick={handleNext}
              isDisabled={!userChoice}
              isLoading={isLoading}
            >
              Next
            </Button>
            <Button variant="ghost" w="100%" maxW="300px" onClick={closeModal}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Portal>
        <CustomizationNameModal
          openModal={isCustomizationNameModalOpen}
          closeModal={setIsCustomizationNameModalOpen.off}
        />
      </Portal>
    </>
  );
};

export default CalculationViewModal;
