import React from 'react';
import { Icon } from '@chakra-ui/react';

const Sheep = () => {
  return (
    <Icon
      width="51"
      height="42"
      viewBox="0 0 51 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.2469 26.9399L31.3354 32.5455L30.5608 41.9989H28.2148L27.6419 32.373L25.4857 26.9405H11.9464L4.48741 32.4136V41.9955L2.09042 42L0 27.7326L1.61046 25.3557V19.0166C0.727597 19.0166 0.0124665 18.3046 0.0124665 17.4268V16.7164C0.0124665 14.153 1.13729 11.7169 3.09285 10.0476C4.69425 8.67986 6.73537 7.92778 8.84619 7.92778H29.2586C30.8894 7.92778 32.42 7.14357 33.3674 5.82322L35.8398 2.37687L34.2469 0H41.0106C42.812 0 44.5528 0.647212 45.9128 1.82268L50.2189 5.54528C50.908 6.14118 51.1715 7.09002 50.887 7.95315C50.5816 8.88112 49.7112 9.5086 48.7297 9.5086H44.6004L42.03 20.7593C41.2044 24.3742 37.9739 26.9399 34.2469 26.9399ZM33.7596 30.4669L36.5623 41.9994H38.9395L36.2835 27.8983C35.8585 27.9953 35.4261 28.0624 34.9898 28.0985L33.7596 30.4669ZM8.3226 31.0763L10.4833 41.9994H12.8848L12.2479 32.4136L18.2511 28.1289H12.3397L8.3226 31.0763Z"
        fill="#163458"
      />
    </Icon>
  );
};

export default Sheep;
