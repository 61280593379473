import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';
import LegalButtonGroup from '../LegalButtonGroup';

const SUPPORT_ROUTE = '/support';

const HomeFooter = () => {
  const currentLocation = useLocation();
  const isCurrentlyOnSupport = currentLocation.pathname === SUPPORT_ROUTE;

  return (
    <Flex width="100%" justifyContent="space-around">
      <Flex gap="1" display={isCurrentlyOnSupport ? 'none' : 'flex'}>
        <Text>Need Help?</Text>
        <Link to={SUPPORT_ROUTE} style={{ color: '#026C64', fontWeight: 700 }}>
          Contact Us.
        </Link>
      </Flex>
      <Flex gap="24px">
        <LegalButtonGroup />
      </Flex>
    </Flex>
  );
};

export default HomeFooter;
