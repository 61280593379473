import React, { PropsWithChildren } from 'react';
import { Flex, Box, Image } from '@chakra-ui/react';
import Background from 'assets/images/auth-bg.png';
import Logo from 'assets/images/logo.png';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@chakra-ui/react';
import HomeFooter from 'components/molecules/HomeFooter';

const SupportLayout = ({ children }: PropsWithChildren) => {
  const [isLargerThan992] = useMediaQuery('(min-width: 992px)');

  return (
    <Flex justifyContent="flex-start" alignItems="center" h="100vh">
      {isLargerThan992 && (
        <Box
          flex="1"
          h="100%"
          backgroundImage={Background}
          bgSize="cover"
        ></Box>
      )}
      <Flex
        flex="1"
        flexDir="column"
        justifyContent="flex-start"
        alignItems="center"
        h="100%"
        padding="21px"
      >
        <Link to="/">
          <Image src={Logo} maxW="79px" maxH="40px" />
        </Link>

        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gap="1.5rem"
        >
          {children}
        </Flex>

        <HomeFooter />
      </Flex>
    </Flex>
  );
};

export default SupportLayout;
