import React, { useContext, useEffect, useRef, useState } from 'react';
import { Wrap } from '@chakra-ui/react';
import { MODAL_ICON } from 'enums/customizations';
import ModalContentButton from 'components/Modal/ModalContentButton';
import ModalContentLayout from 'components/Modal/ModalContentLayout';
import { NewCustomizationContext } from 'context/newCustomization';
import { getFilteredLivestockTypes } from 'services/Livestock';
import { Livestock } from 'interfaces/livestocks';
import { lowerCaseFirstLetter, capitalizeFirstLetter } from 'utils';

const doNotDisplayStocktype = ['deer', 'cattle', 'mutton'];
const PROGRESS_BAR_VALUE = 60;

const StockTypes = () => {
  const [stockTypes, setStockTypes] = useState([]);
  const newCustomizationContext = useContext(NewCustomizationContext);
  const { isEditCustomization, stock, editStock } =
    newCustomizationContext?.state;
  const ref = useRef<HTMLButtonElement>(null);

  const HEADING = `What type of ${lowerCaseFirstLetter(
    stock?.category_name
  )} would you like to look at?`;

  const filterLivestockTypes = async () => {
    try {
      const id = isEditCustomization ? editStock?.category : stock?.category;
      const types = await getFilteredLivestockTypes(id);
      setStockTypes(types);
    } catch (error) {
      setStockTypes([]);
    }
  };

  useEffect(() => {
    filterLivestockTypes();

    // Useful for validating edit values when the user clicks back button without selecting new value
    // then the previously edit value should still remain and not revert back to original value
    if (isEditCustomization) {
      newCustomizationContext?.dispatch({
        type: 'UPDATE_CURRENT_EDITED_VALUE',
        payload: editStock.livestock_type_name,
      });
    }
  }, []);

  useEffect(() => {
    if (isEditCustomization) {
      ref?.current?.focus();
    }
  }, [isEditCustomization, stockTypes]);

  return (
    <ModalContentLayout
      heading={HEADING}
      progressValue={PROGRESS_BAR_VALUE}
      icon={
        MODAL_ICON[
          (isEditCustomization
            ? editStock?.category_name
            : stock?.category_name) as keyof typeof MODAL_ICON
        ]
      }
    >
      <Wrap
        justify="center"
        alignItems="center"
        spacing="1rem"
        maxW="80%"
        margin="auto"
      >
        {stockTypes?.map((item: Livestock) => {
          return (
            !doNotDisplayStocktype.includes(item?.name) && (
              <ModalContentButton
                key={item?.id}
                maxWidth="260px"
                ref={item?.id === editStock?.livestock_type ? ref : undefined}
                handleClick={() =>
                  newCustomizationContext?.dispatch({
                    type: isEditCustomization
                      ? 'EDIT_STOCK_TYPE'
                      : 'STOCK_TYPE',
                    payload: { id: item?.id, name: item?.name },
                  })
                }
              >
                {capitalizeFirstLetter(item?.name)}
              </ModalContentButton>
            )
          );
        })}
      </Wrap>
    </ModalContentLayout>
  );
};

export default StockTypes;
