import { useQuery } from 'react-query';
import { getLivestockWeightRangeById } from 'services/Livestock';
import { getNumericalMonth } from 'utils';

const useGetWeightRange = (id: number) => {
  const currentMonth = getNumericalMonth();
  return useQuery({
    queryKey: ['customizationsDetails', id],
    queryFn: () => getLivestockWeightRangeById(id, currentMonth),
  });
};

export default useGetWeightRange;
