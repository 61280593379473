import React from 'react';
import { ResponsiveContainer } from 'recharts';
import bellcurve from 'highcharts/modules/histogram-bellcurve';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { STATUS_COLOR } from 'assets/constants/selection-history';
import { computePercentageQuartiles } from 'utils';

bellcurve(Highcharts);

type ChartType = {
  height?: string | number;
  role?: string;
  quartile: {
    name: string;
    quartile: number;
  }[];
  indicatorSchedule: number;
};

const LABEL_COLOR = '#263042';

const Charts = ({ height, role, quartile, indicatorSchedule }: ChartType) => {
  const lowerQuartileColor =
    role === 'seller' ? STATUS_COLOR.red : STATUS_COLOR.green;
  const higherQuartileColor =
    role === 'seller' ? STATUS_COLOR.green : STATUS_COLOR.red;

  const highchartStyle = {
    style: { height: height },
  };
  const dataSet = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  // Define the custom x-axis labels
  const xAxisLabels = [
    '',
    '',
    computePercentageQuartiles(
      quartile[0]?.quartile,
      indicatorSchedule
    ).toString(),
    '',
    '',
    computePercentageQuartiles(
      quartile[1]?.quartile,
      indicatorSchedule
    ).toString(),
    '',
    '',
    computePercentageQuartiles(
      quartile[2]?.quartile,
      indicatorSchedule
    ).toString(),
  ];

  const options: Highcharts.Options = {
    title: {
      text: undefined,
    },
    xAxis: [
      {
        categories: [],
        labels: {
          enabled: true,
          allowOverlap: true,
          rotation: 0,
          padding: 0,
          overflow: 'allow',
          style: {
            color: LABEL_COLOR,
            fontSize: '14px',
            fontWeight: '600',
          },
          // Set the custom labels for the x-axis
          formatter: function (
            this: Highcharts.AxisLabelsFormatterContextObject
          ) {
            return xAxisLabels[this.pos];
          },
        },
      },
    ],
    yAxis: [
      {
        visible: false,
      },
    ],
    series: [
      {
        type: 'bellcurve',
        animation: false,
        xAxis: 0, // Set this value depending on the index in the xAxis object array
        yAxis: 0, // Set this value depending on the index in the yAxis object array
        baseSeries: 'QuartileData',
        zIndex: -1,
        enableMouseTracking: false,
        zoneAxis: 'x',
        fillColor: STATUS_COLOR.orange,
        color: STATUS_COLOR.orange,
        zones: [
          {
            value: 2,
            fillColor: lowerQuartileColor,
            color: lowerQuartileColor,
          },
          {
            value: 8,
          },
          {
            fillColor: higherQuartileColor,
            color: higherQuartileColor,
          },
        ],
      },
      {
        id: 'QuartileData',
        type: 'scatter',
        data: dataSet,
        visible: false,
      },
    ],
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
  };

  return (
    <ResponsiveContainer height={height} width="100%">
      <HighchartsReact
        containerProps={highchartStyle}
        constructorType={'chart'}
        highcharts={Highcharts}
        options={options}
      />
    </ResponsiveContainer>
  );
};

export default Charts;
