import React, { PropsWithChildren } from 'react';
import {
  Box,
  Flex,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import {
  CONTACT_EMAIL,
  COOKIE_PURPOSES,
  PERSONAL_DATA_USE,
  PERSONAL_INFO_SHARE,
  PRIVACY_CHANGES,
  PRIVACY_DEFINITIONS,
  PRIVACY_DESCRIPTION,
  PRIVACY_LINK_TO_OTHER_SITES,
} from 'assets/constants/legals';
import LegalDefinitions from '../LegalDefinitions';

interface LegalPageType extends PropsWithChildren {
  definitions: typeof PRIVACY_DEFINITIONS;
  title: string;
  descriptions: typeof PRIVACY_DESCRIPTION;
  lastUpdated: string;
}

const LegalPage = ({
  children,
  definitions,
  title,
  descriptions,
  lastUpdated,
}: LegalPageType) => {
  return (
    <Flex flexDir="column" gap="32px" margin="24px 45px 24px 24px">
      <Flex flexDir="column" gap={5}>
        <Heading>{title}</Heading>
        <Text fontWeight={700} fontSize="16px" lineHeight="24px">
          Last updated: {lastUpdated}
        </Text>
        {descriptions.map((description) => (
          <Text key={description.id}>{description.description}</Text>
        ))}
      </Flex>
      <Flex flexDir="column" gap={10}>
        <Box>
          <Heading marginY="32px">Interpretation and Definitions</Heading>
          <Heading size="lg">Interpretation</Heading>
          <Text marginTop="16px">
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </Text>
        </Box>
        <LegalDefinitions definitions={definitions} title={title} />
      </Flex>
      {children}
    </Flex>
  );
};

export default LegalPage;
