import React from 'react';
import { ModalBody, ModalHeader, Box, Text } from '@chakra-ui/react';
import styles from './styles.module.css';

const SupportMessage = () => {
  return (
    <>
      <ModalHeader
        textAlign="center"
        padding="2rem 2rem 1rem 2rem"
        fontSize="24px"
        fontWeight="bold"
        color="text.basic"
      >
        Message Sent!
      </ModalHeader>
      <ModalBody>
        <Box alignItems="center" textAlign="center">
          <Text className={styles.support_text} color="text.neutral">
            Your inquiry has been submitted.
          </Text>
          <Text className={styles.support_text} color="text.neutral">
            We’ll be in touch soon!
          </Text>
        </Box>
      </ModalBody>
    </>
  );
};

export default SupportMessage;
