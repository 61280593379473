import React, { useContext, useEffect, useRef } from 'react';
import { HStack } from '@chakra-ui/react';

import ModalContentButton from 'components/Modal/ModalContentButton';
import ModalContentLayout from 'components/Modal/ModalContentLayout';
import Dollar from 'components/atoms/Icons/Dollar';
import { NewCustomizationContext } from 'context/newCustomization';

import { ROLES_CHOICE_VALUES } from 'assets/constants/modal';

const HEADING = 'Are you looking to buy or sell?';
const PROGRESS_BAR_VALUE = 15;

const Roles = () => {
  const newCustomizationContext = useContext(NewCustomizationContext);
  const { isEditCustomization, editCustomizations } =
    newCustomizationContext?.state;
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    // Useful for validating edit values when the user clicks back button without selecting new value
    // then the previously edit value should still remain and not revert back to original value
    if (isEditCustomization) {
      newCustomizationContext?.dispatch({
        type: 'UPDATE_CURRENT_EDITED_VALUE',
        payload: editCustomizations?.merchant,
      });

      ref?.current?.focus();
    }
  }, []);

  return (
    <ModalContentLayout
      heading={HEADING}
      progressValue={PROGRESS_BAR_VALUE}
      icon={Dollar}
    >
      <HStack justifyContent="center" spacing="1rem">
        {ROLES_CHOICE_VALUES?.map((item) => (
          <ModalContentButton
            key={item.id}
            maxWidth="260px"
            ref={item.value === editCustomizations?.merchant ? ref : undefined}
            handleClick={() =>
              newCustomizationContext?.dispatch({
                type: isEditCustomization ? 'EDIT_ROLE' : 'ROLE',
                payload: item.value,
              })
            }
          >
            {item.text}
          </ModalContentButton>
        ))}
      </HStack>
    </ModalContentLayout>
  );
};

export default Roles;
