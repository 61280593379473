import React from 'react';
import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';

import PasswordChangeForm from '../ModalContent/PasswordChangeForm';

export type PasswordModalProps = {
  isOpenPassword: boolean;
  setIsOpenPassword: (value: boolean) => void;
};

const PasswordModal = ({
  isOpenPassword,
  setIsOpenPassword,
}: PasswordModalProps) => {
  return (
    <Modal isOpen={isOpenPassword} onClose={() => setIsOpenPassword(false)}>
      <ModalOverlay />
      <ModalContent>
        <PasswordChangeForm
          isOpenPassword={isOpenPassword}
          setIsOpenPassword={setIsOpenPassword}
        />
      </ModalContent>
    </Modal>
  );
};

export default PasswordModal;
