import React from 'react';
import { Flex, useDisclosure } from '@chakra-ui/react';
import DashboardLayout from 'components/Dashboard/DashboardLayout';
import SendSupport from 'components/molecules/SendSupport';
import useIsRoutePathToDashboard from 'hooks/useIsRoutePathToDashboard';
import SupportLayout from 'components/SupportLayout';
import SupportMessageModal from 'components/Modal/SupportMessageModal';

const SUPPORT_DASHBOARD_ROUTE = '/dashboard/settings/support';

const SettingsSupport = () => {
  const isRoutePathToDashboard = useIsRoutePathToDashboard(
    SUPPORT_DASHBOARD_ROUTE
  );
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      {isRoutePathToDashboard ? (
        <DashboardLayout>
          <Flex
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            h="90vh"
            overflow="auto"
            bgColor="bg.neutral"
          >
            <SendSupport handleOpenMessage={onOpen} />
          </Flex>
        </DashboardLayout>
      ) : (
        <SupportLayout>
          <Flex
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            h="90vh"
            overflow="auto"
            bgColor="white"
          >
            <SendSupport handleOpenMessage={onOpen} />
          </Flex>
        </SupportLayout>
      )}
      <SupportMessageModal
        openModal={isOpen}
        closeModal={onClose}
        isRoutePathToDashboard={isRoutePathToDashboard}
      />
    </>
  );
};

export default SettingsSupport;
