import type { ModalInitialValuesType } from 'interfaces/modal';

export const TERMS_CHOICE_VALUES = [
  {
    id: 1,
    text: 'Current season analysis',
    value: 'short',
  },
  {
    id: 2,
    text: 'Long term planning',
    value: 'long',
  },
];

export const ROLES_CHOICE_VALUES = [
  {
    id: 1,
    text: 'Buyer',
    value: 'buyer',
  },
  {
    id: 2,
    text: 'Seller',
    value: 'seller',
  },
];

export const AGE_CLASS_CHOICE_VALUES = {
  cattle_age: [
    {
      id: 1,
      text: 'R1',
      value: 'r1',
    },
    {
      id: 2,
      text: 'R2',
      value: 'r2',
    },
    {
      id: 3,
      text: 'R3',
      value: 'r3',
    },
  ],
  sheep_adult: [
    {
      id: 1,
      text: 'Mutton',
      value: 'mutton',
    },
    {
      id: 2,
      text: '2 Tooth',
      value: '2 Tooth',
    },
    {
      id: 3,
      text: '5 Year Old',
      value: '5yr Old',
    },
  ],
  sheep_lamb: [
    {
      id: 1,
      text: 'Wether',
      value: 'wether',
    },
    {
      id: 2,
      text: 'Ram',
      value: 'ram',
    },
    {
      id: 3,
      text: 'Ewe',
      value: 'ewe',
    },
  ],
  deer_age: [
    {
      id: 1,
      text: 'Weaner',
      value: 'weaner',
    },
  ],
};

export const LOCATION_CHOICE_VALUES = [
  {
    id: 1,
    text: 'North Island',
    value: 'north',
  },
  {
    id: 2,
    text: 'South Island',
    value: 'south',
  },
];

export const PRICE_CALC_VIEW_CHOICE_VALUES = [
  {
    id: 1,
    text: 'View Latest Price',
    value: 'default',
  },
  {
    id: 2,
    text: 'Previous Scenarios',
    value: 'customized',
  },
];

export const STOCKS_CHOICE_VALUES = [
  {
    id: 1,
    text: 'Cattle',
    value: 'cattle',
  },
  {
    id: 2,
    text: 'Sheep',
    value: 'sheep',
  },
  {
    id: 3,
    text: 'Deer',
    value: 'deer',
  },
];

export const STOCK_TYPES_CHOICE_VALUES = {
  cattle: [
    {
      id: 1,
      text: 'Bull',
      value: 'bull',
    },
    {
      id: 2,
      text: 'Heifer',
      value: 'heifer',
    },
    {
      id: 3,
      text: 'Cow',
      value: 'cow',
    },
    {
      id: 4,
      text: 'Steer',
      value: 'steer',
    },
  ],
  sheep: [
    {
      id: 1,
      text: 'Adult',
      value: 'adult',
    },
    {
      id: 2,
      text: 'Lamb',
      value: 'lamb',
    },
  ],
  deer: [
    {
      id: 1,
      text: 'Stag',
      value: 'stag',
    },
    {
      id: 2,
      text: 'Hind',
      value: 'hind',
    },
  ],
};

export const MODAL_INITIAL_VALUES: ModalInitialValuesType = {
  modalSize: 'md',
  header: 'New Calculation',
  acceptBtnText: 'Next',
  returnBtnText: 'Cancel',
  steps: 0,
  stepTitles: [
    'term',
    'role',
    // 'location', NOTE: Commented out for temporary removal of location
    'stocks',
    'stock-type',
    'age-class',
    'live-weight',
  ],
};
