import * as yup from 'yup';
import { VALIDATION_ERROR_MESSAGE } from 'utils';

export const registerSchema = yup
  .object({
    first_name: yup.string().required(VALIDATION_ERROR_MESSAGE('First name')),
    last_name: yup.string().required(VALIDATION_ERROR_MESSAGE('Last name')),
    email: yup
      .string()
      .email('Invalid email address')
      .required(VALIDATION_ERROR_MESSAGE('Email address')),
    phone_number: yup.string(),
    password1: yup
      .string()
      .required(VALIDATION_ERROR_MESSAGE('Password'))
      .min(8, 'Password must be 8 characters long!'),
    password2: yup
      .string()
      .required('Please retype your password.')
      .oneOf([yup.ref('password1')], 'Passwords do not match'),
  })
  .required();

export const loginSchema = yup
  .object({
    email: yup
      .string()
      .email('Email address not found')
      .required(VALIDATION_ERROR_MESSAGE('Email address')),
    password: yup.string().required(VALIDATION_ERROR_MESSAGE('Password')),
  })
  .required();

export const forgotPasswordSchema = yup.object({
  email: yup
    .string()
    .email()
    .required(VALIDATION_ERROR_MESSAGE('Email address')),
});

export const resetPasswordSchema = yup.object({
  new_password1: yup
    .string()
    .required(VALIDATION_ERROR_MESSAGE('New password'))
    .min(8, 'New password must be at least 8 characters long'),
  new_password2: yup
    .string()
    .required(VALIDATION_ERROR_MESSAGE('Confirm new password'))
    .oneOf([yup.ref('new_password1')], 'New passwords do not match'),
});

export const profileAccountSettingsSchema = yup
  .object({
    first_name: yup.string().required(VALIDATION_ERROR_MESSAGE('First name')),
    last_name: yup.string().required(VALIDATION_ERROR_MESSAGE('Last name')),
    phone_number: yup
      .string()
      .matches(
        /^\+64[2-9]\d{7,8}$/,
        'Phone number must be in New Zealand format, e.g. +64 21234567'
      )
      .required(VALIDATION_ERROR_MESSAGE('Phone Number')),
  })
  .required();

export const changePasswordSchema = yup.object({
  old_password: yup
    .string()
    .required(VALIDATION_ERROR_MESSAGE('Old password'))
    .min(8, 'Old password must be at least 8 characters long'),
  new_password1: yup
    .string()
    .required(VALIDATION_ERROR_MESSAGE('New password'))
    .min(8, 'New password must be at least 8 characters long'),
  new_password2: yup
    .string()
    .required(VALIDATION_ERROR_MESSAGE('Confirm new password'))
    .oneOf([yup.ref('new_password1')], 'New passwords do not match'),
});

export const supportSchema = yup
  .object({
    email: yup
      .string()
      .required(VALIDATION_ERROR_MESSAGE('Email address is not valid'))
      .email(),
    body: yup.string().required(VALIDATION_ERROR_MESSAGE('Message Content')),
  })
  .required();
