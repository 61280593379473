import React from 'react';
import { Tooltip as ATooltip, Icon } from '@chakra-ui/react';
import { IconType } from 'react-icons';

type TooltipType = { label: string; icon: IconType };

const Tooltip = ({ label, icon }: TooltipType) => {
  return (
    <ATooltip
      hasArrow
      label={label}
      placement="top-start"
      bgColor="#263042"
      color="#FFF"
    >
      <span>
        <Icon boxSize="18px" color="gray" as={icon} />
      </span>
    </ATooltip>
  );
};

export default Tooltip;
