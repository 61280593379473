import React from 'react';
import { Heading, Text, Flex } from '@chakra-ui/react';
import SendSupportForm from 'components/Form/SendSupportForm';

interface SendSupportProps {
  handleOpenMessage: () => void;
}

const SendSupport = ({ handleOpenMessage }: SendSupportProps) => {
  return (
    <>
      <Heading
        fontWeight={['700', null, null, '600', null]}
        fontSize={['24px', null, null, '32px', null]}
        lineHeight="36px"
      >
        How can we help you?
      </Heading>
      <Text
        marginTop={['12px', null, null, '9px', null]}
        fontSize={['14px', null, null, '18px', null]}
        textAlign="center"
        maxW="545px"
      >
        Your satisfaction is our priority, let us know how we can help by
        sending us a message!
      </Text>
      <Flex flexDir="column" width="100%" maxW="609px" flex="0.7">
        <SendSupportForm handleOpenMessage={handleOpenMessage} />
      </Flex>
    </>
  );
};

export default SendSupport;
