import React, { useContext } from 'react';
import {
  Center,
  Heading,
  HStack,
  Icon,
  ModalBody,
  ModalHeader,
  Progress,
  Text,
} from '@chakra-ui/react';
import { ModalContentLayoutProps } from 'interfaces/modal';
import Tags from 'components/atoms/Tags';
import { NewCustomizationContext } from 'context/newCustomization';
import { FiArrowRight } from 'react-icons/fi';
import { capitalizeFirstLetter, processAgeClassDisplayText } from 'utils';

const ModalContentLayout = ({
  children,
  heading,
  supportHeading,
  progressValue,
  icon,
}: ModalContentLayoutProps) => {
  const newCustomizationContext = useContext(NewCustomizationContext);
  const { isEditCustomization, choices, editChoices } =
    newCustomizationContext?.state;

  const HEADER =
    newCustomizationContext?.state?.choices[0] === 'short'
      ? 'Customised season analysis'
      : 'Long term analysis';

  const USER_CHOICES = isEditCustomization ? editChoices : choices;

  const formatUserChoices = (choice: string, index: number) => {
    const isAgeClassChoice = index === 5;
    const livestockTypeChoice =
      USER_CHOICES.length > 3 ? USER_CHOICES[4] : 'No Livestock Type Chosen';

    if (choice === 'north' || choice === 'south') {
      return capitalizeFirstLetter(choice) + ' Island';
    }

    if (isAgeClassChoice) {
      if (choice === 'ma_ewe') {
        return 'Mixed Age';
      } else {
        return processAgeClassDisplayText(choice, livestockTypeChoice);
      }
    }

    return capitalizeFirstLetter(choice);
  };

  return (
    <>
      <Progress colorScheme="green" size="sm" value={progressValue} />
      <ModalHeader
        textAlign="center"
        padding="3rem 2rem 1rem 2rem"
        fontSize="18px"
        fontWeight="bold"
        color="text.basic"
      >
        {HEADER}
      </ModalHeader>

      {/* New Customization Modal Breadcrumbs */}
      <Center h="57px" bgColor="bg.neutral">
        <HStack>
          {USER_CHOICES?.map((item: string, i: number) => (
            <Center key={i}>
              {i !== 1 && <Icon as={FiArrowRight} marginRight="0.5rem" />}
              <Tags>{formatUserChoices(item, i)}</Tags>
            </Center>
          )).filter((item: string, i: number) => i !== 0)}
        </HStack>
      </Center>

      <Center margin="40px 0px 20px 0px">
        <Center
          w="80px"
          h="80px"
          maxH="80px"
          maxW="80px"
          bgColor="#F3F3F5"
          borderRadius="full"
        >
          <Icon as={icon} />
        </Center>
      </Center>
      <Heading textAlign="center" fontSize="24px" color="text.basic">
        {heading}
      </Heading>
      {supportHeading && (
        <Text textAlign="center" color="text.neutral">
          {supportHeading}
        </Text>
      )}
      <ModalBody m="20px 0px 20px 0px">{children}</ModalBody>
    </>
  );
};

export default ModalContentLayout;
