import {
  Heading,
  Stack,
  UnorderedList,
  ListItem,
  Box,
  Text,
  Flex,
} from '@chakra-ui/react';

const LegalDisclosureData = () => {
  return (
    <Flex flexDir="column" gap={10}>
      <Heading>Disclosure of Your Personal Data</Heading>
      <Heading size="lg">Business Transactions</Heading>
      <Text>
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy
      </Text>
      <Heading size="lg">Law enforcement</Heading>
      <Text>
        Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
      </Text>
      <Heading size="lg">Other legal requirements</Heading>
      <Stack spacing={5}>
        <Text>
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </Text>
        <Box>
          <UnorderedList spacing={5}>
            <ListItem>Comply with a legal obligation</ListItem>
            <ListItem>
              Protect and defend the rights or property of the Company
            </ListItem>
            <ListItem>
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </ListItem>
            <ListItem>
              Protect the personal safety of Users of the Service or the public
            </ListItem>
            <ListItem>Protect against legal liability</ListItem>
          </UnorderedList>
        </Box>
      </Stack>
    </Flex>
  );
};

export default LegalDisclosureData;
