import React, { forwardRef, useContext } from 'react';
import { Button } from '@chakra-ui/react';
import { ModalButtonProps } from 'interfaces/modal';
import { NewCustomizationContext } from 'context/newCustomization';

const ModalContentButton = forwardRef(
  (
    { children, maxWidth, handleClick, value }: ModalButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const newCustomizationContext = useContext(NewCustomizationContext);
    const { isProceedViewLatestPrice } =
      newCustomizationContext?.state;
    const onClick = () => {
      handleClick(value);
    };

    return (
      <Button
        w="100%"
        maxW={maxWidth}
        size="lg"
        variant="outline"
        color="text.primary"
        borderRadius="12px"
        fontSize="16px"
        value={value}
        _focus={{ border: '1px solid #394C75', bg: '#E0F1FF' }}
        _hover={{ border: '1px solid #394C75', bg: '#E0F1FF' }}
        onClick={onClick}
        ref={ref}
        isDisabled={
          isProceedViewLatestPrice && value === 'customized' ? true : false
        }
      >
        {children}
      </Button>
    );
  }
);

export default ModalContentButton;
