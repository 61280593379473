import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Tag,
  Td,
  Text,
  Tr,
  useBoolean,
} from '@chakra-ui/react';
import { AiOutlineMinusCircle, AiFillPlusCircle } from 'react-icons/ai';
import DashboardLayout from 'components/Dashboard/DashboardLayout';
import Tags from 'components/atoms/Tags';
import ATable from 'components/molecules/ATable';
import {
  calculateScaler,
  getSelectedLivestock,
  processAgeClassDisplayText,
  renderModalContent,
} from 'utils';
import {
  AVERAGE_SCHEDULE_PREVIOUS_5_YEARS,
  CURRENT_DATE,
  DEFAULT_NAME,
  MONTH_HEADER,
  TABLE_ROWS,
  TABLE_ROWS_2,
  TABLE_STYLES,
} from 'assets/constants/selection-history';
import { SelectionHistoryContext } from 'context/selectionHistoryContext';
import { NewCustomizationContext } from 'context/newCustomization';
import { CarcassPricesType, PerHeadType } from 'interfaces/selection-history';
import ForecastAverageScheduleModal from 'components/Modal/ForecastAverageScheduleModal';
import { CustomizationsData } from 'interfaces/customizations';
import useEditCustomisation from 'hooks/useEditCustomisation';
import { useNavigate } from 'react-router-dom';
import { createCustomizations } from 'services/Customizations';
import EditTags from 'components/molecules/EditTags';
import NewCalculationModal from 'components/Modal/NewCalculationModal';
import ModalContentDisplay from 'components/ModalContentDisplay';
import InputButtons from 'components/Dashboard/InputButtons';
import MaxError from 'components/molecules/MaxError';

const placeholderStyle = {
  color: '#263042',
  fontWeight: 600,
  fontSize: '32px',
  lineHeight: '150%',
};

const LongTerm = () => {
  const navigate = useNavigate();
  const selectionHistoryContext = useContext(SelectionHistoryContext);
  const newCustomizationContext = useContext(NewCustomizationContext);
  const {
    isCustomisationMax,
    isEditCustomization,
    editCustomizations,
    editStock,
  } = newCustomizationContext?.state;
  const { selectionHistory } = selectionHistoryContext?.state;
  const [
    isForecastAverageScheduleModalOpen,
    setIsForecastAverageScheduleModalOpen,
  ] = useBoolean();
  const [isShowCalculationPerHead, setIsShowCalculationPerHead] = useBoolean();
  const [isCustomizationName, setIsCustomizationName] = useBoolean();
  const [isLoading, setIsLoading] = useBoolean();
  const [scaler, setScaler] = useState(0);
  const [customisationName, setCustomisationName] = useState(DEFAULT_NAME);
  const [editCustomisationName, setEditCustomisationName] =
    useState(DEFAULT_NAME);
  const [editHistoryCustomisation, setEditHistoryCustomisation] =
    useState<CustomizationsData>(selectionHistory);
  const [isHover, setIsHover] = useBoolean();

  const {
    isOpen,
    onClose,
    modalValues,
    updateModalValues,
    handleEditCustomization,
    editCustomization,
    cancelEditCustomization,
  } = useEditCustomisation();

  const {
    five_year_average_schedule,
    forecast_average_schedule,
    carcass_prices,
    per_head,
  } = selectionHistory;

  const stockType = selectionHistory?.livestock?.livestock_type;
  const ageClass = selectionHistory?.livestock;

  const formattedAgeClass = processAgeClassDisplayText(
    ageClass?.name,
    stockType?.name
  );
  const UPPER_TABLE_HEADER = ['Carcass price', ...MONTH_HEADER];
  const LOWER_TABLE_HEADER = ['Per head', ...MONTH_HEADER];

  const NUMBER_OF_MISSING_CARCASS_PRICE = 12 - carcass_prices?.length;
  const NUMBER_OF_MISSING_PER_HEAD = 12 - per_head?.length;

  const handleSaveEditSelectionHistory = () => {
    const selectedLivestock = getSelectedLivestock(
      editStock,
      editCustomizations?.livestock
    );

    setEditHistoryCustomisation((prevHistory) => {
      return {
        ...prevHistory,
        merchant: editCustomizations?.merchant,
        // location: editCustomizations?.location, NOTE: Commented out for temporary removal of location
        livestock: selectedLivestock,
        weight: editCustomizations?.weight,
      };
    });
    selectionHistoryContext?.dispatch({
      type: 'UPDATE_SELECTION_HISTORY',
      payload: {
        ...selectionHistory,
        name: customisationName,
        merchant: editCustomizations?.merchant,
        // location: editCustomizations?.location, NOTE: Commented out for temporary removal of location
        livestock: selectedLivestock,
        weight: editCustomizations?.weight,
      },
    });

    newCustomizationContext?.dispatch({
      type: 'UPDATE_EDIT_CUSTOMIZATION_MODE',
      payload: !isEditCustomization,
    });
  };

  const displayDash = (count: number) => {
    return Array.from({ length: count }, () => {
      return <Td>-</Td>;
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEditCustomisationName(event.target.value);

  const handleConfirmName = () => {
    selectionHistoryContext?.dispatch({
      type: 'UPDATE_SELECTION_HISTORY_NAME',
      payload: editCustomisationName,
    });
    newCustomizationContext?.dispatch({
      type: 'EDIT_NAME',
      payload: customisationName,
    });
    setCustomisationName(editCustomisationName);
    setIsCustomizationName.off();
  };

  const handleAddCustomisation = async () => {
    try {
      setIsLoading.on();
      const customizationData = editCustomizations;
      await createCustomizations(customizationData);
      setIsLoading.off();
      navigate('/dashboard/');
    } catch (error) {
      setIsLoading.off();
      return error;
    }
  };

  const handleCloseInput = () => {
    //Reset edit customisation state to confirmed name
    setEditCustomisationName(customisationName);
    setIsCustomizationName.off();
  };

  const handleAddScaler = () => {
    setScaler(scaler + 1);
  };

  const handleSubtractScaler = () => {
    setScaler(scaler - 1);
  };

  const displayScaler = (row: string) => {
    if (row === 'Scaler -/+') {
      return (
        <HStack>
          <Text>{row}</Text>;
          <IconButton
            color="teal"
            aria-label="AiOutlineMinusCircle"
            icon={<AiOutlineMinusCircle />}
            variant="ghost"
            size="lg"
            onClick={handleSubtractScaler}
          />
          <Text>{scaler}%</Text>;
          <IconButton
            color="teal"
            aria-label="AiFillPlusCircle"
            icon={<AiFillPlusCircle />}
            variant="ghost"
            size="lg"
            onClick={handleAddScaler}
          />
        </HStack>
      );
    }

    return row;
  };

  const normalPriceSeasonalities = carcass_prices?.map(
    (head: CarcassPricesType, index: number) => (
      <Td key={`${head}${index}`}>¢{head?.normal_price_seasonality}</Td>
    )
  );
  const longTermRelativities = carcass_prices?.map(
    (head: CarcassPricesType, index: number) => (
      <Td key={`${head}${index}`}>{head?.long_term_relativity}%</Td>
    )
  );
  const carcassDerivedStorePrics = carcass_prices?.map(
    (head: CarcassPricesType, index: number) => (
      <Td key={`${head}${index}`}>¢{head?.derived_store_price}</Td>
    )
  );
  const scalerValues = carcass_prices?.map(
    (head: CarcassPricesType, index: number) => (
      <Td key={`${head}${index}`}>
        ¢{calculateScaler(head?.normal_price_seasonality, scaler)}
      </Td>
    )
  );
  const displayCarcassPrices = (row: string) => {
    switch (row) {
      case 'Normal Price Seasonality':
        return normalPriceSeasonalities;
      case 'Long Term Relativity':
        return longTermRelativities;
      case 'Derived Store Price':
        return carcassDerivedStorePrics;
      default:
        return scalerValues;
    }
  };

  const displayPerHeadDerivedStorePrice = () => {
    return per_head?.map((head: PerHeadType, index: number) => (
      <Td key={`${head}${index}`}>{head?.average_weight}kg</Td>
    ));
  };

  const displayPerHeadDollarAmount = () => {
    return per_head?.map((head: PerHeadType, index: number) => (
      <Td key={`${head}${index}`}>
        $
        {(calculateScaler(
          carcass_prices[index].normal_price_seasonality,
          scaler
        ) *
          head?.average_weight) /
          100}
      </Td>
    ));
  };

  useEffect(() => {
    newCustomizationContext?.dispatch({
      type: 'EDIT_NAME',
      payload: customisationName,
    });
  }, [customisationName]);

  return (
    <DashboardLayout>
      {/* DASHBOARD HEADING*/}
      <Box padding="32px 45px 16px 45px">
        <Flex justifyContent="space-between" alignItems="center">
          <HStack gap="1rem" flex="1">
            {!isCustomizationName && (
              <Heading onClick={() => setIsCustomizationName.on()}>
                {customisationName}
              </Heading>
            )}
            {isCustomizationName && (
              <InputGroup>
                <Input
                  autoFocus={true}
                  style={{ ...placeholderStyle }}
                  placeholder={customisationName}
                  variant="flushed"
                  _placeholder={{ ...placeholderStyle }}
                  onChange={handleChange}
                  value={editCustomisationName}
                />
                <InputRightElement
                  width="100px"
                  children={
                    <InputButtons
                      onClose={handleCloseInput}
                      onClick={handleConfirmName}
                    />
                  }
                />
              </InputGroup>
            )}
            <Tags>Long term planning</Tags>
          </HStack>
          <HStack>
            {isHover && isCustomisationMax && <MaxError />}
            <Button
              colorScheme="teal"
              size="lg"
              color="#FFF"
              w="220px"
              onClick={handleAddCustomisation}
              disabled={isCustomisationMax}
              isLoading={isLoading}
              onPointerEnter={() => setIsHover.on()}
              onPointerLeave={() => setIsHover.off()}
            >
              Save Changes
            </Button>
          </HStack>
        </Flex>
        <Flex gap="1rem" alignItems="center" marginTop="6px">
          <Text fontSize="12px">Today’s date</Text>
          <Text color="teal" fontWeight="500">
            {CURRENT_DATE}
          </Text>
        </Flex>
      </Box>
      <Flex
        flexDirection="column"
        width="100%"
        bgColor="#F8F9F9"
        padding="26px 45px"
      >
        <Flex gap="5rem">
          <Flex gap="2rem" alignItems="center" marginTop="6px">
            <Text fontSize="12px" color="#6B6C75">
              Average schedule for previous 5 year
            </Text>
            <Text fontWeight="500">¢{five_year_average_schedule}</Text>
          </Flex>
          <Flex
            gap="2rem"
            alignItems="center"
            marginTop="6px"
            onClick={() => setIsForecastAverageScheduleModalOpen.on()}
            cursor="pointer"
          >
            <Text fontSize="12px" color="#6B6C75">
              Forecast average schedule - dollar per kg carcass
            </Text>
            <Tags>¢{forecast_average_schedule}</Tags>
          </Flex>
        </Flex>
        <Flex
          flexDir="column"
          w="100%"
          p="26px"
          bgColor="#FFF"
          borderRadius="8px"
          marginTop="34px"
        >
          {/* Table Title Header */}
          <Flex w="100%" alignItems="flex-start" justifyContent="space-between">
            <Text
              alignSelf="center"
              color="#263042"
              fontWeight="600"
              fontSize="18px"
            >
              Using the meat price average to derive monthly meat and store
              stock prices - {formattedAgeClass}
            </Text>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            marginTop="13px"
          >
            <EditTags
              customisation={selectionHistory}
              editValues={editHistoryCustomisation}
              handleEditCustomization={handleEditCustomization}
            />
            <Box>
              {isEditCustomization && (
                <Button
                  colorScheme="teal"
                  variant="ghost"
                  size="lg"
                  fontWeight="700"
                  onClick={() => handleSaveEditSelectionHistory()}
                >
                  Save
                </Button>
              )}
              {isEditCustomization ? (
                <Button
                  name="cancel"
                  colorScheme="gray"
                  variant="ghost"
                  size="lg"
                  fontWeight="700"
                  onClick={() =>
                    cancelEditCustomization(
                      selectionHistory,
                      setEditHistoryCustomisation
                    )
                  }
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  bgColor="#394C75"
                  variant="solid"
                  size="lg"
                  fontWeight="700"
                  borderRadius="12px"
                  color="#FFF"
                  gap="2px"
                  onClick={() =>
                    editCustomization(selectionHistory, selectionHistory)
                  }
                >
                  Customize
                </Button>
              )}
            </Box>
          </Flex>
          <Box marginTop="20px" overflowX={'scroll'}>
            <Box>
              <ATable headers={UPPER_TABLE_HEADER}>
                {TABLE_ROWS.map((row) => {
                  return (
                    <Tr key={row} style={TABLE_STYLES}>
                      <Td>{displayScaler(row)}</Td>
                      {displayCarcassPrices(row)}
                      {displayDash(NUMBER_OF_MISSING_CARCASS_PRICE)}
                    </Tr>
                  );
                })}
              </ATable>
            </Box>

            <Flex justifyContent="center" marginTop="20px">
              <Text
                cursor="pointer"
                color="text.secondary"
                onClick={() => setIsShowCalculationPerHead.toggle()}
              >
                {isShowCalculationPerHead ? 'Hide' : 'Show'} calculation per
                head
              </Text>
            </Flex>
            {isShowCalculationPerHead && (
              <Box marginTop="26px">
                <ATable headers={LOWER_TABLE_HEADER}>
                  {TABLE_ROWS_2.map((row) => {
                    return (
                      <Tr key={row} style={TABLE_STYLES}>
                        {row === 'Approximate Liveweight' ? (
                          <>
                            <Td color="teal">{row}</Td>
                            {displayPerHeadDerivedStorePrice()}
                          </>
                        ) : (
                          <>
                            <Td color="black">{row}</Td>
                            {displayPerHeadDollarAmount()}
                          </>
                        )}
                        {displayDash(NUMBER_OF_MISSING_PER_HEAD)}
                      </Tr>
                    );
                  })}
                </ATable>
              </Box>
            )}
          </Box>
        </Flex>
      </Flex>
      <ForecastAverageScheduleModal
        openModal={isForecastAverageScheduleModalOpen}
        closeModal={setIsForecastAverageScheduleModalOpen.off}
      />
      <NewCalculationModal
        openModal={isOpen}
        closeModal={onClose}
        modalValues={modalValues}
        updateModalValues={updateModalValues}
      >
        <ModalContentDisplay
          steps={modalValues.steps}
          renderModalContent={renderModalContent}
        />
      </NewCalculationModal>
    </DashboardLayout>
  );
};

export default LongTerm;
