import { PropsWithChildren } from 'react';
import { Table, Thead, Tbody, Tr, Th } from '@chakra-ui/react';
import { FiHelpCircle } from 'react-icons/fi';
import {
  CARCASS_TOOLTIP_TEXT,
  PER_HEAD_TOOLTIP_TEXT,
} from 'assets/constants/dashboard';
import Tooltip from 'components/atoms/Tooltip';
import { TABLE_STYLES } from 'assets/constants/selection-history';

interface ATableProps extends PropsWithChildren {
  headers: string[];
}

const ATable = ({ children, headers }: ATableProps) => {
  return (
    <Table size="md">
      <Thead bgColor="#EFEFEF">
        <Tr style={TABLE_STYLES}>
          {headers.map((header: string, index: number) => (
            <Th key={header}>
              {header}{' '}
              {index === 0 && (
                <Tooltip
                  label={
                    header === 'Carcass price'
                      ? CARCASS_TOOLTIP_TEXT
                      : PER_HEAD_TOOLTIP_TEXT
                  }
                  icon={FiHelpCircle}
                />
              )}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody bgColor="#FCFCFC">{children}</Tbody>
    </Table>
  );
};

export default ATable;
