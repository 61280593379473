import { Flex, Heading, Box, Text, Stack } from '@chakra-ui/react';
import { PRIVACY_CHANGES } from 'assets/constants/legals';

type LegalChangesTypes = {
  title: string;
  texts: typeof PRIVACY_CHANGES;
};

const LegalChanges = ({ title, texts }: LegalChangesTypes) => {
  return (
    <Flex flexDir="column">
      <Box>
        <Heading>
          Changes to {title === 'Privacy Policy' ? 'this' : 'These'} {title}
        </Heading>
        <Stack marginTop="32px">
          {texts.map((text) => {
            return <Text key={text?.id}>{text?.text}</Text>;
          })}
        </Stack>
      </Box>
    </Flex>
  );
};

export default LegalChanges;
