import React from 'react';
import { Icon } from '@chakra-ui/react';

const Weight = () => {
  return (
    <Icon
      width="48"
      height="43"
      viewBox="0 0 48 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.6552 19.3163L40.9667 6.28173H44.7158C45.0015 6.29817 45.2823 6.20255 45.4971 6.01566C45.7118 5.82878 45.8433 5.56573 45.8627 5.28368C45.8433 5.00164 45.7118 4.73859 45.4971 4.5517C45.2823 4.36481 45.0015 4.2692 44.7158 4.28563H25.2076V0.992065C25.1706 0.717507 25.0339 0.465559 24.8229 0.283077C24.612 0.100595 24.3411 0 24.0606 0C23.7802 0 23.5093 0.100595 23.2983 0.283077C23.0874 0.465559 22.9507 0.717507 22.9137 0.992065V4.27565H3.41558C3.12986 4.26062 2.84966 4.35753 2.63586 4.54533C2.42206 4.73314 2.29191 4.99667 2.27368 5.27869C2.29317 5.56074 2.42458 5.82379 2.63934 6.01067C2.85411 6.19756 3.13488 6.29318 3.42063 6.27674H7.15958L3.4661 19.3163H0C0 23.4183 3.80463 26.7418 8.49853 26.7418C13.1924 26.7418 16.9971 23.4183 16.9971 19.3163H13.7735L10.0851 6.28173H22.9137V10.2739C22.1317 10.4415 21.4277 10.8598 20.9114 11.4636C20.3951 12.0674 20.0954 12.8229 20.0589 13.6124C20.0932 14.4037 20.3919 15.1614 20.9084 15.7673C21.4249 16.3731 22.1301 16.7928 22.9137 16.9609V34.312C17.0779 34.7461 12.5154 38.4639 12.5154 43H35.6211C35.6211 38.4689 31.0737 34.7461 25.2227 34.312V16.9609C25.9998 16.7861 26.697 16.3635 27.2066 15.7582C27.7162 15.153 28.0098 14.3989 28.0421 13.6124C28.0079 12.8211 27.7092 12.0634 27.1927 11.4576C26.6762 10.8517 25.971 10.432 25.1874 10.264V6.27175H38.0362L34.3478 19.3063H31.0029C31.0029 23.4083 34.8076 26.7318 39.5015 26.7318C44.1954 26.7318 48 23.4183 48 19.3163H44.6552ZM11.4139 19.3163H5.81053L8.59958 9.45054L11.4139 19.3163ZM36.7175 19.3163L39.5065 9.45054L42.2905 19.3163H36.7326H36.7175Z"
        fill="#394C75"
      />
    </Icon>
  );
};

export default Weight;
