import { useQuery } from 'react-query';
import { getSelectionHistoryByPagination } from 'services/SelectionHistory';

const useGetAllSelectionHistory = (limit: number, page: number) => {
  return useQuery({
    queryKey: ['selectionHistories', limit, page],
    queryFn: () => getSelectionHistoryByPagination(limit, page),
    refetchOnMount: false,
  });
};

export default useGetAllSelectionHistory;
