import React, { useContext } from 'react';
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Portal,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ModalProps } from 'interfaces/modal';
import SupportMessage from '../ModalContent/SupportMessage';

interface SupportMessageModalProps extends ModalProps {
  isRoutePathToDashboard: boolean;
}

const SupportMessageModal = ({
  openModal,
  closeModal,
  isRoutePathToDashboard,
}: SupportMessageModalProps) => {
  const navigate = useNavigate();

  const handleNext = () => {
    closeModal();
    navigate(isRoutePathToDashboard ? '/dashboard' : '/');
  };

  const handleClose = () => {
    closeModal();
  };
  return (
    <Portal>
      <Modal isOpen={openModal} onClose={closeModal} isCentered>
        <ModalOverlay />
        <ModalContent data-testid="customization-options-modal">
          <SupportMessage />
          <ModalCloseButton />
          <ModalFooter
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            gap="0.5rem"
            paddingBlock="1.5rem"
          >
            <Button
              colorScheme="teal"
              w="100%"
              maxW="300px"
              onClick={handleNext}
            >
              Go To {isRoutePathToDashboard ? 'Dashboard' : 'Home'}
            </Button>
            <Button variant="ghost" w="100%" maxW="300px" onClick={handleClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Portal>
  );
};

export default SupportMessageModal;
