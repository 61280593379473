import Cattle from 'components/atoms/Icons/Cattle';
import Sheep from 'components/atoms/Icons/Sheep';
import Deer from 'components/atoms/Icons/Deer';
import Bull from 'components/atoms/Icons/Bull';
import Weight from 'components/atoms/Icons/Weight';
import Dollar from 'components/atoms/Icons/Dollar';
import LocationPin from 'components/atoms/Icons/LocationPin';

export const EDIT_STEP = {
  ROLE: 1,
  // LOCATION: 2, NOTE: Commented out for temporary removal of location
  STOCKS: 2,
  STOCK_TYPE: 3,
  AGE_CLASS: 4,
  WEIGHT: 5,
};

export const MODAL_ICON = {
  Cattle: Cattle,
  Sheep: Sheep,
  Deer: Deer,
};
