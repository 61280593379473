import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import { useQueryClient, QueryClient, QueryClientProvider } from 'react-query';

import App from './App';
import theme from 'theme';
import AuthContextProvider from 'context/auth';
import NewCustomizationProvider from 'context/newCustomization';
import { SettingsProvider } from 'context/settingsContext';
import SelectionHistoryProvider from 'context/selectionHistoryContext';

import './index.css';
import '@fontsource/inter';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <NewCustomizationProvider>
            <SelectionHistoryProvider>
              <SettingsProvider>
                <App />
              </SettingsProvider>
            </SelectionHistoryProvider>
          </NewCustomizationProvider>
        </AuthContextProvider>
      </QueryClientProvider>
    </ChakraProvider>
  </React.StrictMode>
);
