import { Flex, Heading, Box, Text, Stack } from '@chakra-ui/react';

const LegalTermination = () => {
  return (
    <Flex flexDir="column">
      <Box>
        <Heading>Termination</Heading>
        <Stack marginTop="32px" spacing={5}>
          <Text>
            We may terminate or suspend Your access immediately, without prior
            notice or liability, for any reason whatsoever, including without
            limitation if You breach these Terms and Conditions.
          </Text>
          <Text>
            Upon termination, Your right to use the Service will cease
            immediately.
          </Text>
        </Stack>
      </Box>
    </Flex>
  );
};

export default LegalTermination;
