import React, { useContext } from 'react';
import {
  CardBody,
  Stat,
  Flex,
  StatLabel,
  Tag,
  StatHelpText,
  StatNumber,
  Box,
  Text,
} from '@chakra-ui/react';
import { FiInfo } from 'react-icons/fi';
import { SelectionHistoryContext } from 'context/selectionHistoryContext';
import {
  displayStatusColor,
  getExpectedStorePrice,
  getPerHead,
  putCommaNumbers,
} from 'utils';
import { CardTitleType } from 'interfaces/dashboard';
import Tooltip from 'components/atoms/Tooltip';
import { NewCustomizationContext } from 'context/newCustomization';

type CardContentType = {
  title: CardTitleType;
  index: number;
  handleOpenDatePickerModal: (
    event: React.MouseEvent<HTMLParagraphElement | HTMLSpanElement, MouseEvent>
  ) => void;
};

const CardContent = ({
  title,
  index,
  handleOpenDatePickerModal,
}: CardContentType) => {
  const newCustomizationContext = useContext(NewCustomizationContext);
  const { indicatorScheduleDate, expectedPriceDate } =
    newCustomizationContext?.state;
  const selectionHistoryContext = useContext(SelectionHistoryContext);
  const { selectionHistory } = selectionHistoryContext?.state;
  const {
    merchant,
    weight,
    indicator_schedule,
    current_store_price,
    average_store_price_percentage,
    status,
    livestock,
  } = selectionHistory;
  const expectedStorePrice = getExpectedStorePrice(
    indicator_schedule,
    average_store_price_percentage
  );
  const priceVariance = getPerHead(
    expectedStorePrice - current_store_price,
    weight
  );

  const isNegativePriceVariance = Math.sign(priceVariance) === -1;
  const negativePriceVariance = `- $${putCommaNumbers(
    Math.abs(priceVariance)
  )}`;

  const isLivestockAvailable = livestock?.is_available;

  const displayDateTags = (index: number) => {
    return [0, 2].includes(index);
  };

  const displayCurrency = (isLowerRow: boolean, id: string) => {
    if (id === 'priceVariance' && isNegativePriceVariance) {
      return '';
    }

    return isLowerRow ? '$' : '';
  };

  const formatCardValues = (cardId: string, cardValue: number) => {
    return isNegativePriceVariance && cardId === 'priceVariance'
      ? negativePriceVariance
      : putCommaNumbers(cardValue);
  };

  const cardValueColor = () => {
    return title?.name === 'Price variance'
      ? displayStatusColor(selectionHistory)
      : '#000';
  };

  const displayCardValues = (title: CardTitleType, index: number) => {
    const isLowerRow = index >= 3;
    if (['currentStorePrice', 'currentStorePriceHead'].includes(title.id)) {
      if (!current_store_price) {
        return (
          <Text color="text.neutral" fontSize="12px">
            No Data Available
          </Text>
        );
      }
    }

    if (['expectedPrice', 'priceVariance'].includes(title.id)) {
      if (!average_store_price_percentage || !isLivestockAvailable) {
        return (
          <Text color="text.neutral" fontSize="12px">
            No Data Available
          </Text>
        );
      }
    }

    return (
      <>
        <StatNumber color={cardValueColor()}>
          {displayCurrency(isLowerRow, title?.id)}
          {formatCardValues(title?.id, title?.value)}
        </StatNumber>
        {title?.name !== 'Price variance' && (
          <StatHelpText marginBottom="4px">
            {isLowerRow ? '' : 'cents'} per {isLowerRow ? 'head' : 'kilo'}
          </StatHelpText>
        )}
      </>
    );
  };
  return (
    <CardBody>
      <Stat>
        <Flex justifyContent="space-between">
          <StatLabel
            color={
              [
                'indicatorSchedule',
                'currentStorePrice',
                'currentStorePriceHead',
              ].includes(title.id) || isLivestockAvailable
                ? ''
                : 'text.logo'
            }
          >
            {title?.name}
          </StatLabel>
          {displayDateTags(index) && (
            <Box>
              <Tag
                id={title?.id}
                backgroundColor="#EFEFEF"
                boxShadow="0px 4px 12px rgba(194, 206, 214, 0.2)"
                borderRadius="6px"
                p="5px"
                cursor={!isLivestockAvailable ? 'not-allowed' : 'pointer'}
                onClick={
                  isLivestockAvailable
                    ? (e) => handleOpenDatePickerModal(e)
                    : undefined // necessary to use undefined otherwise it returns an error
                }
                color={
                  title?.id === 'indicatorSchedule' || isLivestockAvailable
                    ? ''
                    : 'text.logo'
                }
              >
                {title?.id === 'indicatorSchedule'
                  ? indicatorScheduleDate
                  : expectedPriceDate}
              </Tag>
            </Box>
          )}
        </Flex>
        <Flex alignItems="flex-end" gap="4px">
          {displayCardValues(title, index)}
        </Flex>
      </Stat>
    </CardBody>
  );
};

export default CardContent;
