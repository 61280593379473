import React, { useContext } from 'react';
import { HStack, Tag, Text } from '@chakra-ui/react';
import { EDIT_STEP } from 'enums/customizations';
import { capitalizeFirstLetter, processAgeClassDisplayText } from 'utils';
import { CustomizationsData } from 'interfaces/customizations';
import { NewCustomizationContext } from 'context/newCustomization';
import useIsShowAgeClass from 'hooks/useIsShowAgeClass';

type EditTagsType = {
  customisation: CustomizationsData;
  editValues: CustomizationsData;
  handleEditCustomization: (step: number) => void;
};

const EditTags = ({
  customisation,
  editValues,
  handleEditCustomization,
}: EditTagsType) => {
  const customizationContext = useContext(NewCustomizationContext);
  const { isEditCustomization, editCustomizations, editStock } =
    customizationContext?.state;
  const categoryName = customisation?.livestock?.livestock_type?.category?.name;
  const livestockTypeName = customisation?.livestock?.livestock_type?.name;
  const ageClass = customisation?.livestock?.name;
  const editCategoryName = editStock?.category_name;
  const editLivestockTypeName = editStock?.livestock_type_name;
  const editAgeClass = editStock?.livestock_name;
  const livestockTypeId = customisation?.livestock?.livestock_type?.id;
  const formattedAgeClass = processAgeClassDisplayText(
    ageClass,
    livestockTypeName
  );
  const formattedEditAgeClass = processAgeClassDisplayText(
    editAgeClass,
    editLivestockTypeName
  );

  const isShowAgeClass = useIsShowAgeClass(livestockTypeId);

  const tagStyles = {
    backgroundColor: '#fff',
    p: '8px 16px',
    color: isEditCustomization ? 'grey' : 'black',
    border: '1px solid #EFEFEF',
    size: 'lg',
    borderRadius: '16px',
    cursor: isEditCustomization ? 'pointer' : 'cursor',
    _hover: isEditCustomization ? { border: '1px solid #394C75' } : undefined,
  };

  return (
    <>
      <HStack paddingInline="1rem">
        <Text fontSize="12px" marginRight="20px">
          {isEditCustomization
            ? 'Select customisation value'
            : 'Customisation values'}
        </Text>
        <Tag
          {...tagStyles}
          onClick={() =>
            isEditCustomization ? handleEditCustomization(EDIT_STEP.ROLE) : {}
          }
        >
          {isEditCustomization
            ? capitalizeFirstLetter(editCustomizations?.merchant)
            : capitalizeFirstLetter(customisation?.merchant)}
        </Tag>
        {
          // NOTE: Commented out for temporary removal of location
        }
        {/* <Tag
          {...tagStyles}
          onClick={() =>
            isEditCustomization
              ? handleEditCustomization(EDIT_STEP.LOCATION)
              : {}
          }
        >
          {isEditCustomization
            ? capitalizeFirstLetter(editCustomizations?.location)
            : capitalizeFirstLetter(customisation?.location)}{' '}
          Island
        </Tag> */}
        <Tag
          {...tagStyles}
          onClick={() =>
            isEditCustomization ? handleEditCustomization(EDIT_STEP.STOCKS) : {}
          }
        >
          {isEditCustomization ? editCategoryName : categoryName}
        </Tag>
        <Tag
          {...tagStyles}
          onClick={() =>
            isEditCustomization
              ? handleEditCustomization(EDIT_STEP.STOCK_TYPE)
              : {}
          }
        >
          {capitalizeFirstLetter(
            isEditCustomization ? editLivestockTypeName : livestockTypeName
          )}
        </Tag>
        {isShowAgeClass && (
          <Tag
            backgroundColor="#fff"
            p="8px 16px"
            color={isEditCustomization ? 'grey' : 'black'}
            border="1px solid #EFEFEF"
            size="lg"
            borderRadius="16px"
            cursor={isEditCustomization ? 'pointer' : 'cursor'}
            _hover={isEditCustomization && { border: '1px solid #394C75' }}
            onClick={() =>
              isEditCustomization
                ? handleEditCustomization(EDIT_STEP.AGE_CLASS)
                : {}
            }
          >
            {isEditCustomization ? formattedEditAgeClass : formattedAgeClass}
          </Tag>
        )}
        <Tag
          {...tagStyles}
          onClick={() =>
            isEditCustomization ? handleEditCustomization(EDIT_STEP.WEIGHT) : {}
          }
        >
          {isEditCustomization
            ? editCustomizations?.weight
            : customisation?.weight}
          kg
        </Tag>
      </HStack>
    </>
  );
};

export default EditTags;
