import React, { useContext } from 'react';
import {
  FormControl,
  Input,
  InputGroup,
  ModalBody,
  ModalHeader,
} from '@chakra-ui/react';
import { NewCustomizationContext } from 'context/newCustomization';

const CustomizationName = () => {
  const newCustomizationContext = useContext(NewCustomizationContext);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    newCustomizationContext?.dispatch({
      type: 'NAME',
      payload: inputValue,
    });
  };

  return (
    <>
      <ModalHeader
        textAlign="center"
        padding="2rem 2rem 1rem 2rem"
        fontSize="24px"
        fontWeight="bold"
        color="text.basic"
      >
        Customisation Name
      </ModalHeader>
      <ModalBody>
        <FormControl display="flex" justifyContent="center" alignItems="center">
          <InputGroup maxW="343px">
            <Input
              type="text"
              variant="flushed"
              placeholder="Customisation Name"
              _placeholder={{ textAlign: 'center' }}
              _focusWithin={{ borderColor: '#EFEFEF', boxShadow: 'none' }}
              onChange={handleChange}
              onBlur={handleChange}
            />
          </InputGroup>
        </FormControl>
      </ModalBody>
    </>
  );
};

export default CustomizationName;
