import React from 'react';
import {
  useColorModeValue,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Box,
} from '@chakra-ui/react';
import TableFooter from './TableFooter';

type SelectionHistoryTableType = {
  children: any,
  headers: string[],
  tableState: any,
}

const SelectionHistoryTable = ({
  children,
  headers,
  tableState,
}: SelectionHistoryTableType) => {
  return (
    <Box
      height="100%"
      p={4}
      rounded="lg"
      boxShadow="lg"
      bg={useColorModeValue('white', 'gray.700')}
      sx={{
        '&::-webkit-scrollbar': {
          width: '4px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'rgba(0, 0, 0, 0.2)',
          borderRadius: '24px',
        },
      }}
    >
      {/* Table Container */}
      <Table variant="simple">
        <Thead position="sticky" top={0} bgColor="#FFF">
          <Tr>
            {headers?.map((value: string) => (
              <Th key={value}>{value}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>{children}</Tbody>
      </Table>
      <TableFooter tableState={tableState} />
    </Box>
  );
};

export default SelectionHistoryTable;
