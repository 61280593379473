import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  breakpoints: {
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
  },
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
  },
  colors: {
    brand: {
      primary: '#026C64',
      secondary: '#163458',
    },
    ui: {
      primary: '#026C64',
      secondary: '#163458',
    },
    text: {
      basic: '#263042',
      primary: '#394C75',
      secondary: '#026C64',
      neutral: '#6B6C75',
      white: '#FFF',
      logo: '#D4D5D8',
    },
    bg: {
      primary: '#FFF',
      neutral: '#F8F9F9',
      modal: '#263042',
    },
    status: {
      red: '#FF5353',
      green: '#00AA9A',
      orange: '#FFA17A',
      basic: '#263042',
    },
  },
});

export default theme;
