import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import ASlider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { SliderPropsType } from 'interfaces/dashboard';

const sliderStyle = { width: 300, margin: 0 };

const handleStyle = {
  backgroundColor: '#E92E05',
  width: '25px',
  borderRadius: '10px',
  border: 'none',
  boxShadow: 'none',
};

const trackStyle = { backgroundColor: '#E92E05' };

const markStyle = {
  color: '#6B6C75',
  fontSize: '14px',
};

const displayStyle = {
  color: '#263042',
};

const Slider = ({
  sliderState,
  sliderSettings,
  isDisplayTextPercent = true,
}: SliderPropsType) => {
  const { sliderValue, handleSliderValue } = sliderState;

  const onSliderChange = (value: number) => {
    handleSliderValue(value);
  };

  const marks = {
    [sliderSettings?.minValue]: {
      style: markStyle,
      label: sliderSettings?.minValue,
    },
    [sliderSettings?.startingValue]: {
      style: markStyle,
      label: sliderSettings?.startingValue,
    },
    [sliderSettings?.maxValue]: {
      style: markStyle,
      label: sliderSettings?.maxValue,
    },
  };
  return (
    <Flex alignItems={'center'} justifyContent={'center'} flexDir="column">
      {isDisplayTextPercent && (
        <Box className="display">
          <Text {...displayStyle}>{sliderValue}%</Text>
        </Box>
      )}
      <Flex alignItems={'center'} justifyContent={'center'} marginTop="20px">
        <ASlider
          defaultValue={sliderSettings?.defaultValue}
          value={sliderValue}
          onChange={onSliderChange as () => void}
          min={sliderSettings?.minValue}
          max={sliderSettings?.maxValue}
          startPoint={sliderSettings?.startingValue}
          style={sliderStyle}
          trackStyle={trackStyle}
          handleStyle={handleStyle}
          dotStyle={{ display: 'none' }}
          marks={marks}
        />
      </Flex>
    </Flex>
  );
};

export default Slider;
