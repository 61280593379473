import React, { useState } from 'react';

export const useSearch = (data: any, searchKey: string) => {
  const [searchQuery, setSearchQuery] = useState('');
  const filteredData = data.filter((item: any) =>
    item[searchKey].toLowerCase().includes(searchQuery.toLowerCase())
  );
  const handleSearchQuery = (query: string) => {
    setSearchQuery(query);
  };

  return [filteredData, handleSearchQuery];
};
