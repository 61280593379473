import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Select,
  Spacer,
  Text,
} from '@chakra-ui/react';
import {
  FaAngleDoubleLeft,
  FaAngleLeft,
  FaAngleRight,
  FaAngleDoubleRight,
} from 'react-icons/fa';

const TableFooter = ({ tableState }: any) => {
  const { count, pageState, limitState } = tableState;
  const { page, setPage } = pageState;
  const { limit, setLimit } = limitState;

  const lastPage = Math.ceil(count / limit);
  const isFirstPage = page <= 1;
  const isLastPage = page >= lastPage;

  return (
    <Box mt={8}>
      <Grid
        templateColumns={{
          base: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
        }}
        gap={6}
      >
        <GridItem display={{ base: 'none', md: 'block' }}>
          <HStack>
            <Text noOfLines={1} fontSize={{ base: 'lg', md: 'sm' }}>
              Items per Page:{' '}
            </Text>
            <Select
              value={limit}
              onChange={(e) => setLimit(parseInt(e.target.value, 10))}
              size="sm"
              maxWidth="max-content"
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </Select>
          </HStack>
        </GridItem>
        <GridItem display={{ base: 'block', md: 'none' }}>
          <HStack>
            <Text noOfLines={1} fontSize={{ base: 'lg', md: 'sm' }}>
              Items per Page:{' '}
            </Text>
            <Select
              value={limit}
              onChange={(e) => setLimit(parseInt(e.target.value, 10))}
              size="md"
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </Select>
          </HStack>
        </GridItem>
        <GridItem m="auto">
          <Text
            ms="auto"
            me={{ base: 0, sm: 'auto' }}
            fontSize={{ base: 'lg', md: 'sm' }}
          >
            {`Page ${page} of ${lastPage > 0 ? lastPage : 1}`}
          </Text>
        </GridItem>
        <GridItem colSpan={2} display={{ base: 'flex', md: 'none' }}>
          <HStack mx="auto">
            <Button size="lg" onClick={() => setPage(1)} disabled={isFirstPage}>
              <FaAngleDoubleLeft />
            </Button>
            <Button
              size="lg"
              onClick={() => setPage(page - 1)}
              disabled={isFirstPage}
            >
              <FaAngleLeft />
            </Button>
            <Button
              size="lg"
              onClick={() => setPage(page + 1)}
              disabled={isLastPage}
            >
              <FaAngleRight />
            </Button>
            <Button
              size="lg"
              onClick={() => setPage(lastPage)}
              disabled={isLastPage}
            >
              <FaAngleDoubleRight />
            </Button>
          </HStack>
        </GridItem>
        <GridItem>
          <Flex display={{ base: 'none', md: 'flex' }}>
            <Spacer />
            <HStack>
              <Button
                size="sm"
                onClick={() => setPage(1)}
                disabled={isFirstPage}
              >
                <FaAngleDoubleLeft />
              </Button>
              <Button
                size="sm"
                onClick={() => setPage(+page - 1)}
                disabled={isFirstPage}
              >
                <FaAngleLeft />
              </Button>
              <Button
                size="sm"
                onClick={() => setPage(+page + 1)}
                disabled={isLastPage}
              >
                <FaAngleRight />
              </Button>
              <Button
                size="sm"
                onClick={() => setPage(lastPage)}
                disabled={isLastPage}
              >
                <FaAngleDoubleRight />
              </Button>
            </HStack>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default TableFooter;
