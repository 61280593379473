import React, { useContext, useState } from 'react';
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';

import { ModalProps } from 'interfaces/modal';
import { NewCustomizationContext } from 'context/newCustomization';
import AdjustCurrentMarket from '../ModalContent/AdjustCurrentMarket';
import useSlider from 'hooks/useSlider';

const AdjustCurrentMarketModal = ({ openModal, closeModal }: ModalProps) => {
  const newCustomizationContext = useContext(NewCustomizationContext);
  const { currentMarketPercentage } = newCustomizationContext?.state;
  const { sliderState, sliderSettings } = useSlider(
    25,
    -25,
    0,
    currentMarketPercentage
  );

  const handleNext = () => {
    newCustomizationContext?.dispatch({
      type: 'UPDATE_CURRENT_MARKET_PERCENTAGE',
      payload: sliderState?.sliderValue,
    });
    closeModal();
  };

  const handleBack = () => {
    closeModal();
  };

  return (
    <Modal isOpen={openModal} onClose={closeModal} isCentered>
      <ModalOverlay />
      <ModalContent>
        <AdjustCurrentMarket
          sliderState={sliderState}
          sliderSettings={sliderSettings}
        />
        <ModalCloseButton />
        <ModalFooter
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          gap="0.5rem"
          paddingBlock="1.5rem"
        >
          <Button colorScheme="teal" w="100%" maxW="300px" onClick={handleNext}>
            Apply changes
          </Button>
          <Button variant="ghost" w="100%" maxW="300px" onClick={handleBack}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AdjustCurrentMarketModal;
