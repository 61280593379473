import React, { useEffect, useState, useContext } from 'react';
import { HStack, Avatar, Heading } from '@chakra-ui/react';

import { ProfileAccountSettingsType } from '../SettingsAccount';

import { SettingsContext } from 'context/settingsContext';

const DashboardAvatar = () => {
  const [user, setUser] = useState<ProfileAccountSettingsType>();
  const { isOpenProfile, setIsOpenProfile } = useContext(SettingsContext);

  useEffect(() => {
    const userDetail = localStorage.getItem('user');
    if (userDetail) {
      const parseData = JSON.parse(userDetail);
      setUser(parseData);
    }
  }, [isOpenProfile]);

  const handleProfileClick = () => {
    setIsOpenProfile(true);
  };

  return (
    <HStack onClick={handleProfileClick} cursor="pointer">
      <Avatar name={`${user?.first_name} ${user?.last_name}`} />
      <Heading as="h5" fontSize="16px" fontWeight="semibold">
        {user?.first_name} {user?.last_name}
      </Heading>
    </HStack>
  );
};

export default DashboardAvatar;
