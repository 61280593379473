import { Heading, Stack, Box, Text } from '@chakra-ui/react';

const LegalDeleteData = () => {
  return (
    <Box>
      <Heading>Delete Your Personal Data</Heading>
      <Stack spacing={5} marginTop="32px">
        <Text>
          You have the right to delete or request that We assist in deleting the
          Personal Data that We have collected about You.
        </Text>
        <Text>
          Our Service may give You the ability to delete certain information
          about You from within the Service.
        </Text>
        <Text>
          You may update, amend, or delete Your information at any time by
          signing in to Your Account, if you have one, and visiting the account
          settings section that allows you to manage Your personal information.
          You may also contact Us to request access to, correct, or delete any
          personal information that You have provided to Us.
        </Text>
        <Text>
          Please note, however, that We may need to retain certain information
          when we have a legal obligation or lawful basis to do so.
        </Text>
      </Stack>
    </Box>
  );
};

export default LegalDeleteData;
