import { instance } from 'http/axios';
import { Customizations } from 'interfaces/customizations';

const CUSTOMIZATIONS_ENDPOINT = '/customizations/';

export const getAllUserCustomization = async () => {
  try {
    const res = await instance(
      `${CUSTOMIZATIONS_ENDPOINT}?query={analysis_period, id, livestock, merchant, name, user, weight}`
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const createCustomizations = async (data: Customizations) => {
  try {
    const res = await instance.post(CUSTOMIZATIONS_ENDPOINT, data);
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const updateCustomizations = async (
  data: Customizations,
  id: number
) => {
  try {
    const res = await instance.patch(`${CUSTOMIZATIONS_ENDPOINT}${id}/`, data);
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const getCustomizationById = async (id: number) => {
  try {
    const res = await instance(`${CUSTOMIZATIONS_ENDPOINT}${id}/`);
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const getCustomisationIndicatorScheduleByDate = async (
  id: number,
  date: string
) => {
  try {
    const res = await instance(
      `${CUSTOMIZATIONS_ENDPOINT}${id}/?query={indicator_schedule}&indicator_schedule_date=${date}`
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const getCustomisationExpectedStorePricePercentageByDate = async (
  id: number,
  date: string
) => {
  try {
    const res = await instance(
      `${CUSTOMIZATIONS_ENDPOINT}${id}/?query{average_store_price_percentage}&average_store_price_percentage_date=${date}`
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};
