import { instance } from 'http/axios';

const FAQS_ENDPOINT = '/core/faqs/';

export const getAllFAQS = async () => {
  try {
    const res = await instance(FAQS_ENDPOINT);
    return res?.data;
  } catch (error) {
    return [];
  }
};
