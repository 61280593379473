export const SUBSCRIPTION_PLANS = [
  {
    label: 'Free',
    options: [{ value: 'free', label: 'Free Trial', price: '$0.00' }],
  },
  {
    label: 'Monthly',
    options: [
      { value: 'monthly_standard', label: 'Standard', price: '$9.99' },
      { value: 'monthly_premium', label: 'Premium', price: '$29.99' },
    ],
  },
  {
    label: 'Yearly',
    options: [
      { value: 'yearly_standard', label: 'Standard', price: '$99.99' },
      { value: 'yearly_premium', label: 'Premium', price: '$199.99' },
    ],
  },
];
