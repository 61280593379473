import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Flex,
  Button,
  Heading,
  useDisclosure,
  useBoolean,
} from '@chakra-ui/react';
import DashboardLayout from 'components/Dashboard/DashboardLayout';
import SelectionHistoryTable from 'components/SelectionHistoryTable';
import NewCalculationModal from 'components/Modal/NewCalculationModal';
import ModalContentDisplay from 'components/ModalContentDisplay';
import useIsFirstRender from 'hooks/useIsFirstRender';
import { getSelectionHistoryByPagination } from 'services/SelectionHistory';
import { MODAL_INITIAL_VALUES } from 'assets/constants/modal';
import { ISelectionHistory } from 'interfaces/selection-history';
import { NewCustomizationContext } from 'context/newCustomization';
import { renderModalContent } from 'utils';
import { SELECTION_HISTORY_TABLE_HEADERS } from 'assets/constants/selection-history';
import TableContent from 'components/SelectionHistoryTable/TableContent';
import Loader from 'components/atoms/Loader';
import useModal from 'hooks/useModal';
import useGetAllSelectionHistory from 'hooks/useGetAllSelectionHistory';

const SelectionHistory = () => {
  const newCustomizationContext = useContext(NewCustomizationContext);
  const [selectionHistories, setSelectionHistories] = useState<
    ISelectionHistory[]
  >([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const { modalValues, setModalValues, updateModalValues } = useModal();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const isFirstRender = useIsFirstRender();

  const pageState = { page, setPage };
  const limitState = { limit, setLimit };
  const tableState = { count, pageState, limitState };

  const {
    data,
    isLoading,
    error: errorSelectionHistories,
  } = useGetAllSelectionHistory(limit, page);

  const handleAddCustomization = () => {
    onOpen();
    setModalValues(MODAL_INITIAL_VALUES);
    newCustomizationContext?.dispatch({
      type: 'NAME',
      payload: 'new customization',
    });
    newCustomizationContext?.dispatch({
      type: 'UPDATE_DISABLED',
      payload: true,
    });
  };

  useEffect(() => {
    if (data) {
      setSelectionHistories(data?.results);
      setCount(data?.count);
    }
  }, [data]);

  useEffect(() => {
    if (!isFirstRender) {
      setPage(1);
    }
  }, [limit]);

  return (
    <>
      <DashboardLayout>
        {/*Dashboard Heading*/}
        <Box padding="32px 45px 16px 45px">
          <Flex justifyContent="space-between" alignItems="center">
            <Heading>Selection history</Heading>
            <Flex gap="5">
              <Button
                data-testid="new-calculation-button"
                colorScheme="teal"
                size="lg"
                onClick={handleAddCustomization}
              >
                Make new calculation
              </Button>
            </Flex>
          </Flex>
        </Box>
        {/* Improve height viewport  */}
        <Box>
          {isLoading ? (
            <Loader />
          ) : (
            <SelectionHistoryTable
              headers={SELECTION_HISTORY_TABLE_HEADERS}
              tableState={tableState}
            >
              <TableContent selectionHistories={selectionHistories} />
            </SelectionHistoryTable>
          )}
        </Box>
      </DashboardLayout>
      {/* MODALS */}
      <NewCalculationModal
        openModal={isOpen}
        closeModal={onClose}
        modalValues={modalValues}
        updateModalValues={updateModalValues}
      >
        <ModalContentDisplay
          steps={modalValues.steps}
          renderModalContent={renderModalContent}
        />
      </NewCalculationModal>
    </>
  );
};

export default SelectionHistory;
