import React, { createContext, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { instance } from 'http/axios';
import { addDays } from 'date-fns';
import format from 'date-fns/format';

import type { CreateUserData } from 'interfaces/register';

import { useLocalStorage } from 'hooks/useLocalStorage';
import { LoginUserData } from 'interfaces/login';
import { RecoverPasswordValueType } from 'pages/ForgotPassword';
import { ProfileAccountSettingsType } from 'components/Dashboard/SettingsAccount';
import { PasswordChangeSettingsType } from 'components/Modal/ModalContent/PasswordChangeForm';
import { ResetPasswordValueType } from 'pages/ResetPassword';
import {
  DEFAULT_TOKEN_EXPIRY_DAYS,
  SIGN_IN_TOKEN_EXPIRY_DAYS,
} from 'assets/constants/login';
import { DATE_FORMAT } from 'assets/constants/dashboard';

export type AuthContextType = {
  isAuthenticated: boolean;
  user: CreateUserData | null;
  isLoading: boolean;
  token: string | null;
  expiryDate: string | null;
  setUser: React.Dispatch<React.SetStateAction<null>>;
  onRegister: (data: CreateUserData, navigate: NavigateFunction) => void;
  onLogin: (
    data: LoginUserData,
    navigate: NavigateFunction,
    isChecked: boolean
  ) => void;
  onLogout: (navigate: NavigateFunction) => void;
  onSendRecoveryLink: (data: RecoverPasswordValueType) => Promise<void>;
  onUpdateProfileAccountSettings: (data: ProfileAccountSettingsType) => void;
  onUpdatePasswordChangeSettings: (data: PasswordChangeSettingsType) => void;
  onPasswordResetConfirm: (data: ResetPasswordValueType) => void;
  error: any;
  setError: React.Dispatch<any>;
};

export const AuthContext = createContext<AuthContextType | null>(null);

const getExpiryDate = (isChecked: boolean) => {
  const days = isChecked
    ? SIGN_IN_TOKEN_EXPIRY_DAYS
    : DEFAULT_TOKEN_EXPIRY_DAYS;
  return format(addDays(new Date(), days), DATE_FORMAT);
};

const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState<any>(null);
  const [token, updateToken] = useLocalStorage('token', null);
  const [expiryDate, updateExpiryDate] = useLocalStorage('expiryDate', '');
  const [userLocalStorage, updateUserLocalStorage] = useLocalStorage(
    'user',
    {}
  );

  const onRegister = async (
    data: CreateUserData,
    navigate: NavigateFunction
  ) => {
    setIsLoading(true);
    try {
      const res = await instance.post('/auth/registration/', data);
      updateToken(res.data?.key);
      setIsLoading(false);
      setError(null);
      navigate('/login');
    } catch (err: any) {
      const errorData = err?.response?.data;
      const errorMsges =
        errorData?.non_field_errors ||
        (Array.isArray(errorData) ? errorData : []);
      setError({ ...errorData, non_field_errors: errorMsges });
      setIsLoading(false);
    }
  };

  const onLogin = async (
    data: LoginUserData,
    navigate: NavigateFunction,
    isChecked: boolean
  ) => {
    setIsLoading(true);
    try {
      const res = await instance.post('/auth/login/', data);
      const userToken = res?.data?.key;
      updateToken(userToken);
      const date = getExpiryDate(isChecked);
      updateExpiryDate(date);
      const user = await instance('/auth/user/', {
        headers: { Authorization: `Token ${userToken}` },
      });
      const userData = user?.data;
      setUser(userData);
      updateUserLocalStorage(userData);
      setIsLoading(false);
      navigate('/dashboard');
    } catch (err: any) {
      const errorMsg = err?.response?.data?.non_field_errors;
      setError(errorMsg);
      setIsLoading(false);
    }
  };

  const onSendRecoveryLink = async (data: RecoverPasswordValueType) => {
    setIsLoading(true);
    try {
      const res = await instance.post('/auth/password/reset/', data);
      setIsLoading(false);
      return res?.data;
    } catch (err: any) {
      setError(err?.response?.data);
      setIsLoading(false);
    }
  };

  const onUpdateProfileAccountSettings = async (
    data: ProfileAccountSettingsType
  ) => {
    setIsLoading(true);
    try {
      const res = await instance.patch('/auth/user/', data);
      updateUserLocalStorage(res?.data);
      setUser(res?.data);
      setIsLoading(false);
      setError({});
      return res;
    } catch (err: any) {
      setError(err?.response?.data);
      setIsLoading(false);
      return err;
    }
  };

  const onPasswordResetConfirm = async (data: ResetPasswordValueType) => {
    setIsLoading(true);
    try {
      const res = await instance.post('/auth/password/reset/confirm/', data);
      setIsLoading(false);
      return res;
    } catch (err: any) {
      setError(err?.response?.data);
      setIsLoading(false);
      return err;
    }
  };

  const onUpdatePasswordChangeSettings = async (
    data: PasswordChangeSettingsType
  ) => {
    setIsLoading(true);
    try {
      const res = await instance.post('/auth/password/change/', data);
      setIsLoading(false);
      return res;
    } catch (err: any) {
      const passwordErrorData = err?.response?.data;
      const passwordErrorMsges =
        passwordErrorData?.non_field_errors ||
        (Array.isArray(passwordErrorData) ? passwordErrorData : []);
      setError({ ...passwordErrorData, non_field_errors: passwordErrorMsges });
      setIsLoading(false);
    }
  };

  const onLogout = (navigate: NavigateFunction) => {
    localStorage.clear();
    updateToken(null);
    navigate('/');
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!token,
        user,
        token,
        expiryDate,
        setUser,
        onRegister,
        onLogin,
        onLogout,
        onSendRecoveryLink,
        onUpdateProfileAccountSettings,
        onUpdatePasswordChangeSettings,
        onPasswordResetConfirm,
        isLoading,
        error,
        setError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
