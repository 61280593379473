import React from 'react';
import { Icon } from '@chakra-ui/react';

const Logo404 = () => {
  return (
    <Icon
      width="382"
      height="148"
      viewBox="0 0 382 148"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 119.74V95.9453L59.4025 1.9538H79.8287V34.8892H67.7397L30.2918 94.4102V95.5266H114.706V119.74H0ZM68.2956 144.86V112.483L68.8514 101.946V1.9538H97.0589V144.86H68.2956Z"
        fill="#026C64"
      />
      <path
        d="M190.54 148C178.59 147.953 168.307 145 159.692 139.138C151.123 133.277 144.523 124.787 139.891 113.669C135.306 102.551 133.036 89.1768 133.083 73.5464C133.083 57.9626 135.375 44.6814 139.961 33.703C144.592 22.7245 151.193 14.3744 159.762 8.65253C168.377 2.88418 178.636 0 190.54 0C202.443 0 212.68 2.88418 221.248 8.65253C229.864 14.4209 236.487 22.7943 241.119 33.7727C245.751 44.7047 248.043 57.9626 247.997 73.5464C247.997 89.2233 245.681 102.621 241.049 113.739C236.464 124.857 229.887 133.347 221.318 139.208C212.749 145.069 202.49 148 190.54 148ZM190.54 122.95C198.692 122.95 205.199 118.833 210.063 110.599C214.926 102.365 217.335 90.0141 217.288 73.5464C217.288 62.7075 216.177 53.6829 213.953 46.4724C211.776 39.262 208.673 33.8425 204.643 30.214C200.66 26.5856 195.959 24.7713 190.54 24.7713C182.434 24.7713 175.95 28.8417 171.086 36.9826C166.223 45.1234 163.768 57.3113 163.722 73.5464C163.722 84.5249 164.81 93.6891 166.987 101.039C169.21 108.343 172.337 113.832 176.366 117.507C180.396 121.135 185.121 122.95 190.54 122.95Z"
        fill="#026C64"
      />
      <path
        d="M267.294 119.74V95.9453L326.697 1.9538H347.123V34.8892H335.034L297.586 94.4102V95.5266H382V119.74H267.294ZM335.59 144.86V112.483L336.145 101.946V1.9538H364.353V144.86H335.59Z"
        fill="#026C64"
      />
      ;
    </Icon>
  );
};

export default Logo404;

