import { CustomizationActionType } from 'utils/types';
import { ICustomizationContext } from 'interfaces/customizations';
import { Reducer } from 'react';
import format from 'date-fns/format';
import { DATE_FORMAT } from 'assets/constants/dashboard';

export const CUSTOMIZATION_INITIAL_STATE: ICustomizationContext = {
  isEditCustomization: false,
  isProceedViewLatestPrice: false,
  isLoading: false,
  isDisabled: true,
  firstModalOpen: 0,
  currentCustomizationId: 0,
  currentEditedValue: '',
  currentChoice: '',
  choices: [],
  editChoices: [],
  stock: {
    livestock_type: 0,
    category: 0,
    livestock_type_name: '',
    category_name: '',
    livestock_name: '',
  },
  editStock: {
    livestock_type: 0,
    category: 0,
    livestock_type_name: '',
    category_name: '',
    livestock_name: '',
  },
  customizations: {
    name: 'new customization',
    analysis_period: '',
    merchant: '',
    // location: '', NOTE: Commented out for temporary removal of location
    livestock: 0,
    weight: '0',
  },
  editCustomizations: {
    name: 'new customization',
    analysis_period: '',
    merchant: '',
    // location: '', NOTE: Commented out for temporary removal of location
    livestock: 0,
    weight: '0',
  },
  currentEditDate: '',
  todaysDate: format(new Date(), DATE_FORMAT),
  indicatorScheduleDate: format(new Date(), DATE_FORMAT),
  expectedPriceDate: format(new Date(), DATE_FORMAT),
  currentMarketPercentage: 0,
  isCustomisationMax: false,
};

export const customizationReducer: Reducer<
  ICustomizationContext,
  CustomizationActionType
> = (state, action) => {
  const updateEditChoices = (
    choicesArray: string[],
    step: number,
    name: string
  ) => {
    const updatedArray = choicesArray.map((choice, index) => {
      if (index === step) {
        return name;
      } else {
        return choice;
      }
    });
    return updatedArray;
  };

  switch (action.type) {
    case 'NAME':
      return {
        ...state,
        customizations: {
          ...state?.customizations,
          name: action.payload,
        },
      };
    case 'TERMS':
      return {
        ...state,
        isDisabled: false,
        currentChoice: action.payload,
        customizations: {
          ...state?.customizations,
          analysis_period: action.payload,
        },
      };
    case 'ROLE':
      return {
        ...state,
        isDisabled: false,
        currentChoice: action.payload,
        customizations: {
          ...state?.customizations,
          merchant: action.payload,
        },
      };
    // NOTE: Commented out for temporary removal of location
    // case 'LOCATION':
    //   return {
    //     ...state,
    //     isDisabled: false,
    //     currentChoice: action.payload,
    //     customizations: {
    //       ...state?.customizations,
    //       location: action.payload,
    //     },
    //   };
    case 'STOCKS':
      return {
        ...state,
        isDisabled: false,
        currentChoice: action.payload.name,
        stock: {
          ...state.stock,
          category: action.payload.id,
          category_name: action.payload.name,
        },
      };
    case 'STOCK_TYPE':
      return {
        ...state,
        isDisabled: false,
        currentChoice: action.payload.name,
        stock: {
          ...state.stock,
          livestock_type: action.payload.id,
          livestock_type_name: action.payload.name,
        },
      };
    case 'AGE_CLASS':
      return {
        ...state,
        isDisabled: false,
        currentChoice: action.payload.name,
        stock: {
          ...state.stock,
          livestock_name: action.payload.name,
        },
        customizations: {
          ...state?.customizations,
          livestock: action.payload.id,
        },
      };
    case 'WEIGHT':
      if (!action.payload) {
        return { ...state, isDisabled: true };
      }
      return {
        ...state,
        isDisabled: false,
        customizations: { ...state.customizations, weight: action.payload },
      };
    case 'EDIT_NAME':
      return {
        ...state,
        editCustomizations: {
          ...state?.editCustomizations,
          name: action.payload,
        },
        customizations: {
          ...state?.customizations,
          name: action.payload,
        },
      };
    case 'EDIT_TERMS':
      return {
        ...state,
        isDisabled: false,
        currentChoice: action.payload,
        editCustomizations: {
          ...state?.editCustomizations,
          analysis_period: action.payload,
        },
      };
    case 'EDIT_ROLE':
      return {
        ...state,
        isDisabled: false,
        currentChoice: action.payload,
        editCustomizations: {
          ...state?.editCustomizations,
          merchant: action.payload,
        },
      };
    // NOTE: Commented out for temporary removal of location
    // case 'EDIT_LOCATION':
    //   return {
    //     ...state,
    //     isDisabled: false,
    //     currentChoice: action.payload,
    //     editCustomizations: {
    //       ...state?.editCustomizations,
    //       location: action.payload,
    //     },
    //   };
    case 'EDIT_STOCKS':
      return {
        ...state,
        isDisabled: false,
        currentChoice: action.payload.name,
        editStock: {
          ...state.editStock,
          category: action.payload.id,
          category_name: action.payload.name,
        },
      };
    case 'EDIT_STOCK_TYPE':
      return {
        ...state,
        isDisabled: false,
        currentChoice: action.payload.name,
        editStock: {
          ...state.editStock,
          livestock_type: action.payload.id,
          livestock_type_name: action.payload.name,
        },
      };
    case 'EDIT_AGE_CLASS':
      return {
        ...state,
        isDisabled: false,
        currentChoice: action.payload.name,
        editStock: {
          ...state.editStock,
          livestock_name: action.payload.name,
        },
        editCustomizations: {
          ...state?.editCustomizations,
          livestock: action.payload.id,
        },
      };
    case 'EDIT_WEIGHT':
      if (!action.payload && !state.isEditCustomization) {
        return { ...state, isDisabled: true };
      }
      return {
        ...state,
        isDisabled: false,
        editCustomizations: {
          ...state.editCustomizations,
          weight: action.payload,
        },
      };
    case 'ADD_CHOICES':
      return {
        ...state,
        isDisabled: true,
        choices: [...state.choices, state.currentChoice],
      };
    case 'ADD_ORIGINAL_CHOICES_ARRAY':
      return {
        ...state,
        choices: action.payload,
      };
    case 'ADD_EDIT_CHOICES':
      return {
        ...state,
        editChoices: action.payload,
      };
    case 'UPDATE_EDIT_CHOICES_BY_STEP':
      return {
        ...state,
        editChoices: action.payload.choices,
        firstModalOpen: action.payload.step,
      };
    case 'ADD_EDIT_LIVESTOCK_CHOICE':
      return {
        ...state,
        isDisabled: true,
        editChoices: [...state.editChoices, state.currentChoice],
      };
    case 'REMOVE_EDIT_LIVESTOCK_CHOICE':
      const updatedEditChoice = state.editChoices.slice(
        0,
        state.editChoices.length - 1
      );
      return { ...state, editChoices: updatedEditChoice };
    case 'REMOVE_CHOICES':
      const newArr = state.choices.slice(0, state.choices.length - 1);
      return { ...state, choices: newArr };
    case 'CLEAR_CHOICES':
      return { ...state, choices: [] };
    case 'UPDATE_DISABLED':
      return { ...state, isDisabled: action.payload };
    case 'UPDATE_PROCEED_VIEW_LATEST_PRICE':
      return { ...state, isProceedViewLatestPrice: action.payload };
    case 'UPDATE_CURRENT_CUSTOMIZATION_ID':
      return { ...state, currentCustomizationId: action.payload };
    case 'UPDATE_CUSTOMIZATION':
      return {
        ...state,
        currentChoice: action.payload.ageClass.name,
        customizations: {
          name: action.payload.name,
          analysis_period: action.payload.analysis_period,
          merchant: action.payload.merchant,
          // location: action.payload.location, NOTE: Commented out for temporary removal of location
          livestock: action.payload.ageClass.id,
          weight: action.payload.weight,
        },
        stock: {
          livestock_type: action.payload.livestockType.id,
          category: action.payload.category.id,
          livestock_type_name: action.payload.livestockType.name,
          category_name: action.payload.category.name,
          livestock_name: action.payload.ageClass.name,
        },
      };
    case 'UPDATE_EDIT_CUSTOMIZATION':
      return {
        ...state,
        currentChoice: action.payload.ageClass.name,
        editCustomizations: {
          name: action.payload.name,
          analysis_period: action.payload.analysis_period,
          merchant: action.payload.merchant,
          // location: action.payload.location, NOTE: Commented out for temporary removal of location
          livestock: action.payload.ageClass.id,
          weight: action.payload.weight,
        },
        editStock: {
          livestock_type: action.payload.livestockType.id,
          category: action.payload.category.id,
          livestock_type_name: action.payload.livestockType.name,
          category_name: action.payload.category.name,
          livestock_name: action.payload.ageClass.name,
        },
      };
    case 'UPDATE_EDIT_CUSTOMIZATION_MODE':
      return { ...state, isEditCustomization: action.payload };
    case 'UPDATE_EDIT_CUSTOMIZATION_STATUS':
      return {
        ...state,
        isEditCustomization: action.payload.mode,
        currentCustomizationId: action.payload.customisationId,
      };
    case 'UPDATE_CURRENT_EDITED_VALUE':
      return { ...state, currentEditedValue: action.payload };
    case 'UPDATE_EDIT_CHOICES':
      return {
        ...state,
        editChoices: updateEditChoices(
          state.editChoices,
          action.payload.step,
          action.payload.name
        ),
      };
    case 'UPDATE_EDIT_STOCK':
      return {
        ...state,
        editStock: action.payload,
      };
    case 'UPDATE_CURRENT_CHOICE':
      return { ...state, currentChoice: action.payload };
    case 'UPDATE_FIRST_MODAL_OPEN':
      return { ...state, firstModalOpen: action.payload };
    case 'UPDATE_CURRENT_EDIT_DATE':
      return { ...state, currentEditDate: action.payload };
    case 'UPDATE_TODAYS_DATE':
      return {
        ...state,
        todaysDate: format(action.payload, DATE_FORMAT),
      };
    case 'UPDATE_INDICATOR_SCHEDULE_DATE':
      return {
        ...state,
        indicatorScheduleDate: format(action.payload, DATE_FORMAT),
      };
    case 'UPDATE_EXPECTED_PRICE_DATE':
      return {
        ...state,
        expectedPriceDate: format(action.payload, DATE_FORMAT),
      };
    case 'RESET_DATES':
      return {
        ...state,
        indicatorScheduleDate: format(new Date(), DATE_FORMAT),
        expectedPriceDate: format(new Date(), DATE_FORMAT),
      };
    case 'UPDATE_CURRENT_MARKET_PERCENTAGE':
      return { ...state, currentMarketPercentage: action.payload };
    case 'UPDATE_IS_CUSTOMISATION_MAX':
      return { ...state, isCustomisationMax: action.payload };
    case 'IS_LOADING_CUSTOMISATION':
      return { ...state, isLoading: true };
    case 'STOP_LOADING_CUSTOMISATION':
      return { ...state, isLoading: false };
    default:
      return state;
  }
};
