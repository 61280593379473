import React from 'react';

import DashboardLayout from 'components/Dashboard/DashboardLayout';

import AgFirstDefaultView from 'components/Dashboard/AgFirstDefaultView';

const DashboardDefaultView = () => {
  return (
    <DashboardLayout>
      <AgFirstDefaultView />
    </DashboardLayout>
  );
};

export default DashboardDefaultView;
