import { FiBell, FiHelpCircle, FiLock, FiUser } from 'react-icons/fi';
import { FiHome, FiInfo, FiSettings, FiArchive } from 'react-icons/fi';
import { RiPulseFill } from 'react-icons/ri';
import { MdSupportAgent } from 'react-icons/md';

export const FAQ_VALUES = [
  {
    id: 1,
    question:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
    answer:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    id: 2,
    question:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
    answer:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    id: 3,
    question:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
    answer:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    id: 4,
    question:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
    answer:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    id: 5,
    question:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
    answer:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    id: 6,
    question:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
    answer:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
];

export const SETTINGS_ACCOUNT_ACCORDION_VALUES = [
  {
    id: 1,
    title: 'Account',
    name: 'Profile',
    content: 'brooklynsimmons@gmail.com',
    icon: FiUser,
  },
  {
    id: 2,
    name: 'Password',
    content: '********',
    icon: FiLock,
  },
];

export const SETTINGS_PEFERENCES_SELECT_VALUES = [
  {
    id: 1,
    name: 'Notification',
    options: [
      { id: 1, text: 'On', value: 'on' },
      { id: 2, text: 'Off', value: 'off' },
    ],
    icon: FiBell,
  },
];

export const SETTINGS_PREFERENCES_BUTTON_VALUES = [
  {
    id: 1,
    name: 'Support',
    icon: MdSupportAgent,
  },
];

export const SIDEBAR_NAV = [
  { id: 1, title: 'Home', path: '/dashboard', icon: FiHome },
  {
    id: 2,
    title: 'Selection History',
    path: '/dashboard/selection-history',
    icon: FiArchive,
  },
  // Hide the longterm temporarily because the current process flow doesn't navigate to long term using the sidebar.
  // {
  //   id: 3,
  //   title: 'Long Term',
  //   path: '#',
  //   icon: RiPulseFill,
  // },
  { id: 4, title: 'Information', path: '/dashboard/information', icon: FiInfo },
  { id: 5, title: 'Settings', path: '/dashboard/settings', icon: FiSettings },
];

export const CARCASS_TOOLTIP_TEXT =
  'The normal price seasonality is derived from the forecast average schedule, adjusted for the historic average premium of out-of-season supply months and the discount of main season supply.';
export const PER_HEAD_TOOLTIP_TEXT =
  'This is the price in dollars per head for a given livestock on a given month';

export const CHART_MARKS = ['Cheap', 'Average', 'Expensive'];

export const DATE_FORMAT = 'MM/dd/yyyy';
export const DATE_FORMAT_SHORT_YEAR = 'MM/dd/yy';
export const QUERY_DATE_FORMAT = 'yyyy-MM-dd';
