import { Flex, Heading, Box, Text, Stack } from '@chakra-ui/react';

const LegalTranslation = () => {
  return (
    <Flex flexDir="column">
      <Box>
        <Heading>Translation Interpretation</Heading>
        <Stack marginTop="32px">
          <Text>
            These Terms and Conditions may have been translated if We have made
            them available to You on our Service. You agree that the original
            English text shall prevail in the case of a dispute.
          </Text>
        </Stack>
      </Box>
    </Flex>
  );
};

export default LegalTranslation;
