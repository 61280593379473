import React from 'react';
import { Flex, Heading, Button, Icon, Box, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Logo404 from 'components/atoms/Icons/Logo404';
import Logo from 'assets/images/logo.png';

const PageNotFound = () => {
  return (
    // Note: Maybe can create a utility page layout
    <Flex h="100vh" maxH="100vh" flexDirection="column" gap="32px">
      <Box padding="30px 45px">
        <Image src={Logo} maxW="80px" maxH="40px" />
      </Box>
      <Flex
        h="100%"
        maxH="100%"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap="32px"
      >
        <Icon as={Logo404} />
        <Heading
          fontWeight={600}
          fontSize="32px"
          lineHeight="150%"
          color="text.secondary"
        >
          Oops! Page Not Found
        </Heading>
        <Heading fontSize="16px" lineHeight="120%" color="text.basic">
          The page you are looking for doesn’t seem to exist
        </Heading>
        <Button
          as={Link}
          to="/"
          backgroundColor="ui.primary"
          color="text.white"
        >
          Go Back Home
        </Button>
      </Flex>
    </Flex>
  );
};

export default PageNotFound;
