import React from 'react';
import DashboardLayout from 'components/Dashboard/DashboardLayout';
import LegalPage from 'components/Legals/LegalPage';
import {
  PRIVACY_DEFINITIONS,
  PRIVACY_TITLE,
  PRIVACY_DESCRIPTION,
  PRIVACY_DASHBOARD_ROUTE,
  PRIVACY_LINK_TO_OTHER_SITES,
  PRIVACY_CHANGES,
  PRIVACY_LAST_UPDATED,
  DATA_COLLECTED,
  CONTACT_EMAIL,
} from 'assets/constants/legals';
import useIsRoutePathToDashboard from 'hooks/useIsRoutePathToDashboard';
import LegalsLayout from 'components/Legals/LegalsLayout';
import LegalCollectPersonalData from 'components/Legals/LegalCollectPersonalData';
import LegalRetention from 'components/Legals/LegalRetention';
import LegalTransferData from 'components/Legals/LegalTransferData';
import LegalDeleteData from 'components/Legals/LegalDeleteData';
import LegalDisclosureData from 'components/Legals/LegalDisclosureData';
import LegalSecurityData from 'components/Legals/LegalSecurityData';
import LegalChildrensPolicy from 'components/Legals/LegalChildrensPolicy';
import LegalLinksSites from 'components/Legals/LegalLinksSites';
import LegalChanges from 'components/Legals/LegalChanges';
import LegalContactUs from 'components/Legals/LegalContactUs';
import LegalUsePersonalData from 'components/Legals/LegalUsePersonalData';

const PrivacyPolicy = () => {
  const isRoutePathToDashboard = useIsRoutePathToDashboard(
    PRIVACY_DASHBOARD_ROUTE
  );
  return (
    <>
      {isRoutePathToDashboard ? (
        <DashboardLayout>
          <LegalPage
            definitions={PRIVACY_DEFINITIONS}
            title={PRIVACY_TITLE}
            descriptions={PRIVACY_DESCRIPTION}
            lastUpdated={PRIVACY_LAST_UPDATED}
          >
            <LegalCollectPersonalData datas={DATA_COLLECTED} />
            <LegalUsePersonalData />
            <LegalRetention />
            <LegalTransferData />
            <LegalDeleteData />
            <LegalDisclosureData />
            <LegalSecurityData />
            <LegalChildrensPolicy />
            <LegalLinksSites texts={PRIVACY_LINK_TO_OTHER_SITES} />
            <LegalChanges title={PRIVACY_TITLE} texts={PRIVACY_CHANGES} />
            <LegalContactUs
              title={PRIVACY_TITLE}
              contactEmail={CONTACT_EMAIL}
            />
          </LegalPage>
        </DashboardLayout>
      ) : (
        <LegalsLayout>
          <LegalPage
            definitions={PRIVACY_DEFINITIONS}
            title={PRIVACY_TITLE}
            descriptions={PRIVACY_DESCRIPTION}
            lastUpdated={PRIVACY_LAST_UPDATED}
          >
            <LegalCollectPersonalData datas={DATA_COLLECTED} />
            <LegalUsePersonalData />
            <LegalRetention />
            <LegalTransferData />
            <LegalDeleteData />
            <LegalDisclosureData />
            <LegalSecurityData />
            <LegalChildrensPolicy />
            <LegalLinksSites texts={PRIVACY_LINK_TO_OTHER_SITES} />
            <LegalChanges title={PRIVACY_TITLE} texts={PRIVACY_CHANGES} />
            <LegalContactUs
              title={PRIVACY_TITLE}
              contactEmail={CONTACT_EMAIL}
            />
          </LegalPage>
        </LegalsLayout>
      )}
    </>
  );
};

export default PrivacyPolicy;
