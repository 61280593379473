import React, { useState } from 'react';
import { SliderSettings, SliderState } from 'interfaces/dashboard';

const useSlider = (
  maxValue: number,
  minValue: number,
  startingValue: number,
  defaultValue: number
): { sliderState: SliderState; sliderSettings: SliderSettings } => {
  const [sliderValue, setSliderValue] = useState<number>(defaultValue);

  const handleSliderValue = (value: number) => {
    setSliderValue(value);
  };

  const sliderState: SliderState = { sliderValue, handleSliderValue };

  const sliderSettings: SliderSettings = {
    maxValue,
    minValue,
    defaultValue,
    startingValue,
  };
  return { sliderState, sliderSettings };
};

export default useSlider;
