export const PRIVACY_TITLE = 'Privacy Policy';

export const PRIVACY_DESCRIPTION = [
  {
    id: 1,
    description:
      'This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.',
  },
  {
    id: 2,
    description:
      'We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the TermsFeed Privacy Policy Generator.',
  },
];

export const PRIVACY_DEFINITIONS = [
  {
    id: 1,
    heading: 'Account',
    desc: ' means a unique account created for You to access our Service or parts of our Service',
  },
  {
    id: 2,
    heading: 'Affiliate',
    desc: ' means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.',
  },
  {
    id: 3,
    heading: 'Company',
    desc: ' (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Ag First Pastoral HB Ltd, 201,Warren Street North, Hastings, New Zealand.',
  },
  {
    id: 4,
    heading: 'Cookies',
    desc: ' are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.',
  },
  { id: 5, heading: 'Country', desc: ' refers to: New Zealand' },
  {
    id: 6,
    heading: 'Device',
    desc: ' means any device that can access the Service such as a computer, a cellphone or a digital tablet.',
  },
  {
    id: 7,
    heading: 'Personal Data',
    desc: ' is any information that relates to an identified or identifiable individual.',
  },
  { id: 8, heading: 'Service', desc: ' refers to the Website.' },
  {
    id: 9,
    heading: 'Service Provider',
    desc: ' means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.',
  },
  {
    id: 10,
    heading: 'Usage Data',
    desc: ' refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).',
  },
  {
    id: 11,
    heading: 'Website',
    desc: ' refers to AgFirst, accessible from React App (livestockbarometer.com)',
  },
  {
    id: 12,
    heading: 'You',
    desc: ' means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.',
  },
];

export const COOKIE_PURPOSES = [
  {
    id: 1,
    heading: 'Necessary / Essential Cookies',
    type: 'Session Cookies',
    administered: 'Us',
    purpose:
      ' These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.',
  },
  {
    id: 2,
    heading: 'Cookies Policy / Notice Acceptance Cookies',
    type: 'Persistent Cookies',
    administered: 'Us',
    purpose:
      'These Cookies identify if users have accepted the use of cookies on the Website',
  },
  {
    id: 3,
    heading: 'Functional Cookies',
    type: 'Persistent Cookies',
    administered: 'Us',
    purpose:
      'These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.',
  },
];

export const PERSONAL_DATA_USE = [
  {
    id: 1,
    title: 'To provide and maintain our Service',
    desc: ', including to monitor the usage of our Service.',
  },
  {
    id: 2,
    title: 'To manage Your Account',
    desc: ': to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.',
  },
  {
    id: 3,
    title: 'For the performance of a contract',
    desc: ': the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.',
  },
  {
    id: 4,
    title: 'To contact You',
    desc: `: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.`,
  },
  {
    id: 5,
    title: 'To provide You with news',
    desc: ', special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.',
  },
  {
    id: 6,
    title: 'To manage Your requests',
    desc: ': To attend and manage Your requests to Us.',
  },
  {
    id: 7,
    title: 'For business transfers',
    desc: ': We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.',
  },
  {
    id: 8,
    title: 'For other purposes',
    desc: ': We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.',
  },
];

export const PERSONAL_INFO_SHARE = [
  {
    id: 1,
    title: 'With Service  Providers',
    desc: ': We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact',
  },
  {
    id: 2,
    title: 'For business transfers',
    desc: ': We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.',
  },
  {
    id: 3,
    title: 'With Affiliates',
    desc: ': We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.',
  },
  {
    id: 4,
    title: 'With business partners',
    desc: ': We may share Your information with Our business partners to offer You certain products, services or promotions.',
  },
  {
    id: 5,
    title: 'With other users',
    desc: ': when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.',
  },
  {
    id: 6,
    title: 'With Your consent',
    desc: ': We may disclose Your personal information for any other purpose with Your consent.',
  },
];

export const PRIVACY_CHANGES = [
  {
    id: 1,
    text: 'We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.',
  },
  {
    id: 2,
    text: 'We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.',
  },
  {
    id: 3,
    text: 'You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.',
  },
];

export const PRIVACY_LINK_TO_OTHER_SITES = [
  {
    id: 1,
    text: "Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.",
  },
  {
    id: 2,
    text: 'We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.',
  },
];

export const DATA_COLLECTED = [
  {
    id: 1,
    data: 'Email address',
  },
  {
    id: 2,
    data: 'First name and last name',
  },
  {
    id: 3,
    data: 'Phone number',
  },
  {
    id: 4,
    data: 'Address, State, Province, ZIP/Postal code, City',
  },
  {
    id: 5,
    data: 'Usage Data',
  },
];

export const TERMS_CONDITIONS_TITLE = 'Terms and Conditions';

export const TERMS_CONDITIONS_DESCRIPTION = [
  {
    id: 1,
    description:
      'Please read these terms and conditions carefully before using Our Service.',
  },
];

export const TERMS_DEFINITIONS = [
  {
    id: 1,
    heading: 'Affiliate',
    desc: ' means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.',
  },
  {
    id: 2,
    heading: 'Country',
    desc: ' refers to: New Zealand',
  },
  {
    id: 3,
    heading: 'Company',
    desc: ' (referred as either “the Company”, “We”, “Us” or “Our” in this Agreement) referes to Mugna Tech, 3rd floor Lot 8 Block 6, Turqouise St., Marfori Subd, Davao City, 8000, Davao del Sur, Philippines.',
  },
  {
    id: 4,
    heading: 'Device',
    desc: ' means any device that can access the Service such as a computer, a cellphone or a digital tablet.',
  },
  {
    id: 5,
    heading: 'Service',
    desc: ' refers to the Website.',
  },
  {
    id: 6,
    heading: 'Terms and Conditions',
    desc: ' (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service. This Terms and Conditions agreement has been created with the help of the TermsFeed Terms and Conditions Generator.',
  },
  {
    id: 7,
    heading: 'Third-party Social Media Service',
    desc: ' means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.',
  },
  {
    id: 8,
    heading: 'You',
    desc: ' means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.',
  },
];

export const PRIVACY_ROUTE = '/privacy-policy';
export const PRIVACY_DASHBOARD_ROUTE = '/dashboard/privacy-policy';
export const TERMS_ROUTE = '/terms-and-conditions';
export const TERMS_DASHBOARD_ROUTE = '/dashboard/terms-and-conditions';
export const CONTACT_EMAIL = 'adminhb@Agfirst.co.nz';

export const TERMS_LINK_TO_OTHER_SITES = [
  {
    id: 1,
    text: 'Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.',
  },
  {
    id: 2,
    text: 'The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.',
  },
  {
    id: 3,
    text: 'We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.',
  },
];

export const TERMS_CHANGES = [
  {
    id: 1,
    text: "We reserve the right, at Our sole discretion, to modify or replacethese Terms at any time. If a revision is material We will makereasonable efforts to provide at least 30 days' notice prior to anynew terms taking effect. What constitutes a material change will bedetermined at Our sole discretion.",
  },
  {
    id: 2,
    text: 'By continuing to access or use Our Service after those revisionsbecome effective, You agree to be bound by the revised terms. If Youdo not agree to the new terms, in whole or in part, please stopusing the website and the Service.',
  },
];

export const TERMS_LAST_UPDATED = 'July 9, 2023';
export const PRIVACY_LAST_UPDATED = 'July 11, 2023';
