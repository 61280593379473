import React, { useContext } from 'react';
import { ModalBody, ModalHeader, VStack } from '@chakra-ui/react';
import ModalContentButton from 'components/Modal/ModalContentButton';
import { NewCustomizationContext } from 'context/newCustomization';
import { TERMS_CHOICE_VALUES } from 'assets/constants/modal';

const Terms = () => {
  const newCustomizationContext = useContext(NewCustomizationContext);
  return (
    <>
      <ModalHeader
        textAlign="center"
        padding="2rem 2rem 1rem 2rem"
        fontSize="24px"
        fontWeight="bold"
        color="text.basic"
      >
        New Calculation
      </ModalHeader>
      <ModalBody>
        <VStack alignItems="center" spacing="1rem">
          {TERMS_CHOICE_VALUES.map((item) => (
            <ModalContentButton
              key={item.id}
              maxWidth="300px"
              handleClick={() =>
                newCustomizationContext?.dispatch({
                  type: 'TERMS',
                  payload: item.value,
                })
              }
            >
              {item.text}
            </ModalContentButton>
          ))}
        </VStack>
      </ModalBody>
    </>
  );
};

export default Terms;
