type RegisterInput = {
  id: number;
  label?: string;
  type: string;
  placeholder?: string;
  name:
    | 'first_name'
    | 'last_name'
    | 'phone_number'
    | 'email'
    | 'password1'
    | 'password2';
};

export const NAME_INPUTS: RegisterInput[] = [
  {
    id: 1,
    label: 'First Name:',
    type: 'text',
    placeholder: 'ex. John',
    name: 'first_name',
  },
  {
    id: 2,
    label: 'Last Name:',
    type: 'text',
    placeholder: 'ex. Doe',
    name: 'last_name',
  },
];

export const REG_INPUTS: RegisterInput[] = [
  {
    id: 3,
    label: 'Phone Number:',
    type: 'number',
    placeholder: 'ex. +64 XX XXX XXXX',
    name: 'phone_number',
  },
  {
    id: 4,
    label: 'Email address:',
    type: 'email',
    placeholder: 'ex. johndoe@gmail.com',
    name: 'email',
  },
  {
    id: 5,
    label: 'Password:',
    type: 'password',
    placeholder: 'Minimum of 8 characters',
    name: 'password1',
  },
  {
    id: 6,
    label: 'Confirm password:',
    type: 'password',
    placeholder: 'Retype password here',
    name: 'password2',
  },
];

export const REG_DEFAULT_VALUES = {
  first_name: '',
  last_name: '',
  phone_number: '',
  email: '',
  password1: '',
  password2: '',
};
