import { Flex, Heading, Box, Text, Stack } from '@chakra-ui/react';

const LegalUSCompliance = () => {
  return (
    <Flex flexDir="column">
      <Box>
        <Heading>United States Legal Compliance</Heading>
        <Stack marginTop="32px">
          <Text>
            You represent and warrant that (i) You are not located in a country
            that is subject to the United States government embargo, or that has
            been designated by the United States government as a "terrorist
            supporting" country, and (ii) You are not listed on any United
            States government list of prohibited or restricted parties.
          </Text>
        </Stack>
      </Box>
    </Flex>
  );
};

export default LegalUSCompliance;
