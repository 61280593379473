import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { FiSearch } from 'react-icons/fi';
import DashboardLayout from 'components/Dashboard/DashboardLayout';
import { getAllFAQS } from 'services/Faqs';
import { useSearch } from 'hooks/useSearch';

type FAQSType = {
  id: number;
  answer: string;
  question: string;
};

const DashboardInfo = () => {
  const [faqs, setFaqs] = useState<FAQSType[]>([]);
  const [filteredData, handleSearchQuery] = useSearch(faqs, 'question');

  const getFAQS = async () => {
    const faqsData = await getAllFAQS();
    setFaqs(faqsData);
  };

  useEffect(() => {
    getFAQS();
  }, []);

  return (
    <DashboardLayout>
      <Flex
        flexDirection="column"
        alignItems="center"
        minH="90vh"
        paddingBlock="60px"
        gap="2.5rem"
        backgroundColor="bg.neutral"
      >
        <Heading color="text.basic" fontWeight="600">
          Frequently Asked Questions
        </Heading>
        <Flex flexDir="column" w="550px" maxW="550px">
          <InputGroup mb="1.5rem">
            <Input
              placeholder="Search by keyword"
              variant="flushed"
              onChange={(event) => handleSearchQuery(event.target.value)}
            />
            <InputRightElement
              color="brand.primary"
              children={<FiSearch size="20px" />}
            />
          </InputGroup>

          {filteredData.map((item: FAQSType) => (
            <Accordion key={item?.id} allowToggle marginBottom="1rem">
              <AccordionItem border="none">
                <h2>
                  <AccordionButton
                    bg="bg.primary"
                    p="1rem"
                    borderRadius="12px"
                    boxShadow="0px 4px rgba(194, 206, 214, 0.2)"
                  >
                    <Box as="span" flex="1" textAlign="left">
                      <Text noOfLines={2} maxW="80%">
                        {item?.question}
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  {item?.answer.split('\r\n').map((line, index) => (
                    <Text key={index}>
                      {line}
                      <br />
                      &nbsp;
                    </Text>
                  ))}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          ))}
        </Flex>

        <Flex flexDir="column" justifyContent="flex-end" flex="1">
          <Text color="text.neutral">
            Can’t find an answer to your question? Feel free to contact us at{' '}
            <Text as="span" color="text.basic">
              lbsupport@agfirst.co.nz
            </Text>
          </Text>
        </Flex>
      </Flex>
    </DashboardLayout>
  );
};

export default DashboardInfo;
