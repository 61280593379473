import React, { useContext } from 'react';
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';

import { ModalProps } from 'interfaces/modal';
import CustomizationName from '../ModalContent/CustomizationName';
import { createCustomizations } from 'services/Customizations';
import { NewCustomizationContext } from 'context/newCustomization';
import { useNavigate } from 'react-router-dom';

const CustomizationNameModal = ({ openModal, closeModal }: ModalProps) => {
  const newCustomizationContext = useContext(NewCustomizationContext);
  const { customizations } = newCustomizationContext?.state;
  const navigate = useNavigate();
  const handleNext = async () => {
    try {
      const customizationData = customizations;
      await createCustomizations(customizationData);
      closeModal();
      navigate(0); // Refresh dashboard to show the new added customization
    } catch (error) {
      return error;
    }
  };

  const handleBack = () => {
    closeModal();
  };

  return (
    <Modal isOpen={openModal} onClose={closeModal} isCentered>
      <ModalOverlay />
      <ModalContent data-testid="customization-options-modal">
        <CustomizationName />
        <ModalCloseButton />
        <ModalFooter
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          gap="0.5rem"
          paddingBlock="1.5rem"
        >
          <Button colorScheme="teal" w="100%" maxW="300px" onClick={handleNext}>
            Finish
          </Button>
          <Button variant="ghost" w="100%" maxW="300px" onClick={handleBack}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CustomizationNameModal;
