import React from 'react';
import { Box, ModalBody, ModalHeader } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

type ADatePickerType = {
  startDate: Date;
  handleChangeDate: (date: Date) => void;
  minDate?: Date | null;
  maxDate?: Date | null;
};

const ADatePicker = ({
  startDate,
  handleChangeDate,
  minDate,
  maxDate,
}: ADatePickerType) => {
  return (
    <>
      <ModalHeader
        textAlign="center"
        padding="2rem 2rem 1rem 2rem"
        fontSize="24px"
        fontWeight="bold"
        color="text.basic"
      >
        Edit date
      </ModalHeader>
      <ModalBody>
        <Box display="flex" justifyContent="center" alignItems="center">
          <DatePicker
            selected={startDate}
            onChange={(date: Date) => handleChangeDate(date)}
            inline={true}
            minDate={minDate}
            maxDate={maxDate}
          />
        </Box>
      </ModalBody>
    </>
  );
};

export default ADatePicker;
