import { useContext } from 'react';
import { Box, Button, Heading } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import AuthLayout from 'components/AuthLayout';
import RegisterForm from 'components/Form/RegisterForm';
import SubscriptionPlans from 'components/molecules/SubscriptionPlans';
import { AuthContext } from 'context/auth';

const Register = () => {
  const authContext = useContext(AuthContext);

  const handleLinkClick = () => authContext?.setError(null);

  return (
    <AuthLayout>
      <SubscriptionPlans />
      <Box textAlign="center">
        <Heading fontSize="32px" fontWeight="600" color="text.basic">
          Create new account
        </Heading>
      </Box>
      <RegisterForm />
      <Link to="/login">
        <Button color="text.basic" variant="link" onClick={handleLinkClick}>
          Login
        </Button>
      </Link>
    </AuthLayout>
  );
};

export default Register;
