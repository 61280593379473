import React, { useContext, useEffect, useRef, useState } from 'react';
import { HStack, useBoolean, VStack, Wrap } from '@chakra-ui/react';
import ModalContentButton from 'components/Modal/ModalContentButton';
import ModalContentLayout from 'components/Modal/ModalContentLayout';
import { NewCustomizationContext } from 'context/newCustomization';
import { getFilteredLivestockAgeClass } from 'services/Livestock';
import { LivestockAgeClass } from 'interfaces/livestocks';
import { MODAL_ICON } from 'enums/customizations';
import { processAgeClassDisplayText } from 'utils';

const HEADING = 'What age class would you like to look at?';
const PROGRESS_BAR_VALUE = 75;

const AgeClass = () => {
  const [ageClasses, setAgeClasses] = useState([]);
  const [r1AgeClasses, setR1AgeClasses] = useState<LivestockAgeClass[]>([]);
  const [r2AgeClasses, setR2AgeClasses] = useState<LivestockAgeClass[]>([]);
  const [showSortedAgeClass, setSortedAgeClass] = useBoolean(false);

  const ref = useRef<HTMLButtonElement>(null);

  const newCustomizationContext = useContext(NewCustomizationContext);
  const { isEditCustomization, stock, editStock, editCustomizations } =
    newCustomizationContext?.state;

  const filterAgeClassByName = (types: LivestockAgeClass[]) => {
    const r1ClassList: LivestockAgeClass[] = [];
    const r2ClassList: LivestockAgeClass[] = [];

    types?.map((type: LivestockAgeClass) => {
      if (type?.name?.includes('r1')) {
        r1ClassList.push(type);
      }

      if (type?.name?.includes('r2')) {
        r2ClassList.push(type);
      }
    });

    if (r1ClassList.length !== 0) {
      setR1AgeClasses(r1ClassList);
    }

    if (r2ClassList.length !== 0) {
      setR2AgeClasses(r2ClassList);
    }
  };

  const checkContainsRClass = (types: LivestockAgeClass[]) => {
    if (
      types?.some((type: LivestockAgeClass) => type?.name?.includes('r1')) ||
      types?.some((type: LivestockAgeClass) => type?.name?.includes('r2'))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const filterAgeClasses = async () => {
    try {
      const id = isEditCustomization
        ? editStock?.livestock_type
        : stock?.livestock_type;
      const types = await getFilteredLivestockAgeClass(id);
      if (types.length > 1 && checkContainsRClass(types)) {
        filterAgeClassByName(types);
        setSortedAgeClass.on();
      } else {
        setAgeClasses(types);
        setSortedAgeClass.off();
      }
    } catch (error) {
      setAgeClasses([]);
      setR1AgeClasses([]);
      setR2AgeClasses([]);
    }
  };

  const renderAgeClassOptions = (classes: LivestockAgeClass[]) => {
    return classes
      ?.sort((a: LivestockAgeClass, b: LivestockAgeClass) =>
        a?.name.localeCompare(b?.name)
      )
      ?.map((item: LivestockAgeClass) => {
        return (
          <ModalContentButton
            data-testid="modal-content-button"
            key={item?.id}
            maxWidth="260px"
            ref={item?.id === editCustomizations.livestock ? ref : undefined}
            handleClick={() =>
              newCustomizationContext?.dispatch({
                type: isEditCustomization ? 'EDIT_AGE_CLASS' : 'AGE_CLASS',
                payload: { id: item?.id, name: item?.name },
              })
            }
          >
            {processAgeClassDisplayText(item?.name, item?.livestock_type?.name)}
          </ModalContentButton>
        );
      });
  };

  const displayAgeClass = () => {
    if ((showSortedAgeClass && r1AgeClasses.length, r2AgeClasses.length > 1)) {
      return (
        <HStack width="80%">
          <VStack width="100%">{renderAgeClassOptions(r1AgeClasses)}</VStack>
          <VStack width="100%">{renderAgeClassOptions(r2AgeClasses)}</VStack>
        </HStack>
      );
    }

    // Default
    return renderAgeClassOptions(ageClasses);
  };

  useEffect(() => {
    filterAgeClasses();

    // Useful for validating edit values when the user clicks back button without selecting new value
    // then the previously edit value should still remain and not revert back to original value
    if (isEditCustomization) {
      newCustomizationContext?.dispatch({
        type: 'UPDATE_CURRENT_EDITED_VALUE',
        payload: editStock?.livestock_name,
      });
    }
  }, []);

  useEffect(() => {
    if (isEditCustomization) {
      ref?.current?.focus();
    }
  }, [isEditCustomization, ageClasses]);

  return (
    <ModalContentLayout
      heading={HEADING}
      progressValue={PROGRESS_BAR_VALUE}
      icon={
        MODAL_ICON[
          (isEditCustomization
            ? editStock?.category_name
            : stock?.category_name) as keyof typeof MODAL_ICON
        ]
      }
    >
      <Wrap
        data-testid="age-class"
        justify="center"
        alignItems="center"
        spacing="1rem"
        maxW="80%"
        margin="auto"
      >
        {displayAgeClass()}
      </Wrap>
    </ModalContentLayout>
  );
};

export default AgeClass;
