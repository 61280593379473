import React, { createContext, useReducer } from 'react';
import {
  customizationReducer,
  CUSTOMIZATION_INITIAL_STATE,
} from 'reducers/customizationsReducer';
import { CustomizationActionType } from 'utils/types';
import { ICustomizationContext } from 'interfaces/customizations';

export type NewCustomizationContextTypes = {
  state: any; // Typescript using interface
  dispatch: React.Dispatch<CustomizationActionType>;
};

export const NewCustomizationContext =
  createContext<NewCustomizationContextTypes | null>(null);

const NewCustomizationProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(
    customizationReducer,
    CUSTOMIZATION_INITIAL_STATE
  );

  return (
    <NewCustomizationContext.Provider value={{ state, dispatch }}>
      {children}
    </NewCustomizationContext.Provider>
  );
};

export default NewCustomizationProvider;
