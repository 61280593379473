import axios from 'axios';
import { KEYS } from 'enums/localstorage-keys';
import { getLocalStorage } from 'utils';

export const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use(
  (config) => {
    const token = getLocalStorage(KEYS.TOKEN);
    if (token) {
      return {
        ...config,
        headers: {
          Authorization: `Token ${token}`,
        },
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location.replace('/');
    }
    return Promise.reject(error);
  }
);
