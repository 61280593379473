import { Flex, Heading, Box, Text, Stack } from '@chakra-ui/react';

const LegalGoverningLaw = () => {
  return (
    <Flex flexDir="column">
      <Box>
        <Heading>Governing Law</Heading>
        <Stack marginTop="32px">
          <Text>
            The laws of the Country, excluding its conflicts of law rules, shall
            govern this Terms and Your use of the Service. Your use of the
            Application may also be subject to other local, state, national, or
            international laws.
          </Text>
        </Stack>
      </Box>
    </Flex>
  );
};

export default LegalGoverningLaw;
