import React, { useContext } from 'react';
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Portal,
} from '@chakra-ui/react';
import { ModalProps } from 'interfaces/modal';
import MaxCustomization from '../ModalContent/MaxCustomization';
import { NewCustomizationContext } from 'context/newCustomization';

interface MaxCustomizationModalType extends ModalProps {
  handleAddCustomization: () => void;
}

const MaxCustomizationModal = ({
  openModal,
  closeModal,
  handleAddCustomization,
}: MaxCustomizationModalType) => {
  const newCustomizationContext = useContext(NewCustomizationContext);

  const handleNext = () => {
    newCustomizationContext?.dispatch({
      type: 'UPDATE_PROCEED_VIEW_LATEST_PRICE',
      payload: true,
    });
    handleAddCustomization();
    closeModal();
  };

  const handleBack = () => {
    closeModal();
  };
  return (
    <Portal>
      <Modal isOpen={openModal} onClose={closeModal} isCentered>
        <ModalOverlay />
        <ModalContent data-testid="customization-options-modal">
          <MaxCustomization />
          <ModalCloseButton />
          <ModalFooter
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            gap="0.5rem"
            paddingBlock="1.5rem"
          >
            <Button
              colorScheme="teal"
              w="100%"
              maxW="300px"
              onClick={handleNext}
            >
              Proceed
            </Button>
            <Button variant="ghost" w="100%" maxW="300px" onClick={handleBack}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Portal>
  );
};

export default MaxCustomizationModal;
