import React, { useState, useEffect, useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Text,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  ModalCloseButton,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
} from '@chakra-ui/react';

import {
  PROFILE_DETAILS,
  PROFILE_DEFAULT_VALUES,
  ProfileDetails,
} from 'assets/constants/profile';
import { AuthContext } from 'context/auth';
import { profileAccountSettingsSchema } from 'schema';
import { errorMessageChecker } from 'utils';

import { SettingsContext } from 'context/settingsContext';

import type { ProfileAccountSettingsType } from 'components/Dashboard/SettingsAccount';

const ProfileForm = () => {
  const { isOpenProfile, setIsOpenProfile } = useContext(SettingsContext);
  const [user, setUser] = useState<ProfileAccountSettingsType>({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
  });
  const [details, setDetails] = useState<ProfileDetails[]>();
  const authContext = useContext(AuthContext);

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm<typeof PROFILE_DEFAULT_VALUES>({
    mode: 'onChange',
    defaultValues: PROFILE_DEFAULT_VALUES,
    resolver: yupResolver(profileAccountSettingsSchema),
    reValidateMode: 'onChange',
  });

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event?.target?.name;
    const value = event?.target?.value;
    setUser({ ...user, [name]: value });
  };

  const handleProfileApplyChanges: SubmitHandler<
    typeof PROFILE_DEFAULT_VALUES
  > = async () => {
    const data: ProfileAccountSettingsType = {
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email,
      phone_number: user?.phone_number,
    };
    try {
      const response: any = await authContext?.onUpdateProfileAccountSettings(
        data
      );
      if (response?.status === 200) {
        setIsOpenProfile(false);
        clearErrors();
      }
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    const userDetail = localStorage?.getItem('user');
    if (userDetail) {
      const parseData = JSON?.parse(userDetail);
      if (parseData) {
        setUser(parseData);
        setDetails(() => PROFILE_DETAILS(parseData));
      }
    }
  }, [isOpenProfile]);

  useEffect(() => {
    reset(user);
  }, [user, reset]);

  return (
    <>
      <form onSubmit={handleSubmit(handleProfileApplyChanges)}>
        <ModalHeader
          fontSize="24px"
          fontWeight="700"
          marginTop="1rem"
          textAlign="center"
          color="text.basic"
        >
          Profile
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {details?.map((item) => (
            <FormControl
              key={item?.id}
              isInvalid={
                errorMessageChecker(item?.name, errors)?.title !== undefined ||
                (authContext?.error !== null &&
                  authContext?.error?.[item?.name])
              }
              mb="1rem"
            >
              <FormLabel>{item?.label}</FormLabel>
              {item?.type === 'email' ? (
                <FormControl key={item?.id} mb="1rem">
                  <Text pl="1rem">{item?.value}</Text>
                </FormControl>
              ) : (
                <Input
                  {...register(item?.name)}
                  type={item?.type}
                  value={user[item?.name]}
                  onChange={handleInput}
                />
              )}
              {errorMessageChecker(item?.name, errors)?.title !== undefined && (
                <FormErrorMessage>
                  {errorMessageChecker(item?.name, errors)?.msg}
                </FormErrorMessage>
              )}
              {authContext?.error &&
                authContext?.error?.[item?.name]?.map(
                  (err: string, i: number) => (
                    <FormErrorMessage key={i + 1}>{err}</FormErrorMessage>
                  )
                )}
            </FormControl>
          ))}
        </ModalBody>
        <ModalFooter flexDirection="column">
          {/* Apply Changes Button & Cancel Button here */}
          <Button
            type="submit"
            variant="solid"
            colorScheme="teal"
            size="md"
            w="334px"
            p="24px"
            mr={3}
            mt={6}
            isLoading={authContext?.isLoading}
          >
            Apply Changes
          </Button>
          <Button
            color="text.basic"
            variant="link"
            onClick={() => setIsOpenProfile(false)}
            mt={6}
          >
            Cancel
          </Button>
        </ModalFooter>
      </form>
    </>
  );
};

export default ProfileForm;
