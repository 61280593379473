import React, { createRef, useContext, useRef, useState } from 'react';
import { Box, Flex, ModalBody, ModalHeader, VStack } from '@chakra-ui/react';
import ModalContentButton from 'components/Modal/ModalContentButton';
import { PRICE_CALC_VIEW_CHOICE_VALUES } from 'assets/constants/modal';
import { NewCustomizationContext } from 'context/newCustomization';

type PriceCalcViewType = {
  updateUserChoice: (choice: string) => void;
};

const PriceCalculationView = ({ updateUserChoice }: PriceCalcViewType) => {
  const newCustomizationContext = useContext(NewCustomizationContext);
  const { isProceedViewLatestPrice } = newCustomizationContext?.state;
  const [isHover, setIsHover] = useState<boolean>(false);
  const mouseMoveRef = createRef<HTMLDivElement>();

  const checkHover = (e: any) => {
    if (mouseMoveRef.current) {
      const mouseOver = mouseMoveRef.current.contains(e.target);
      if (isProceedViewLatestPrice && !isHover && mouseOver) {
        setIsHover(true);
      }

      if (isHover && !mouseOver) {
        setIsHover(false);
      }
    }
  };

  return (
    <>
      <ModalHeader
        textAlign="center"
        padding="2rem 2rem 1rem 2rem"
        fontSize="24px"
        fontWeight="bold"
        color="text.basic"
      >
        View price calculations
      </ModalHeader>
      <ModalBody>
        <VStack alignItems="center" spacing="1rem">
          {PRICE_CALC_VIEW_CHOICE_VALUES?.map((item) => (
            <Flex
              key={item.id}
              ref={mouseMoveRef}
              width="320px"
              paddingY="2px"
              justifyContent="center"
              onMouseEnter={() => {
                setIsHover(
                  isProceedViewLatestPrice && item.value === 'customized'
                );
              }}
              onMouseLeave={() => {
                setIsHover(false);
              }}
              onMouseMove={(e) => checkHover(e)}
            >
              <ModalContentButton
                maxWidth="300px"
                key={item.id}
                handleClick={updateUserChoice}
                value={item.value}
              >
                {item.text}
              </ModalContentButton>
            </Flex>
          ))}
        </VStack>
        {isHover && (
          // Error component
          <Box
            position="absolute"
            color="status.red"
            p="8px 16px"
            textAlign="center"
            fontWeight="600"
            left="60px"
          >
            max amount of customisations reached
          </Box>
        )}
      </ModalBody>
    </>
  );
};

export default PriceCalculationView;
