import React, { createContext, useState } from 'react';

export const SettingsContext = createContext<{
  isOpenProfile: boolean;
  setIsOpenProfile: (value: boolean) => void;
}>({
  isOpenProfile: false,
  setIsOpenProfile: () => {},
});

export const SettingsProvider = ({ children }: { children: any }) => {
  const [isOpenProfile, setIsOpenProfile] = useState(false);

  return (
    <SettingsContext.Provider value={{ isOpenProfile, setIsOpenProfile }}>
      {children}
    </SettingsContext.Provider>
  );
};
