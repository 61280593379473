import React from 'react';

import DashboardLayout from 'components/Dashboard/DashboardLayout';

import CustomizedView from 'components/Dashboard/CustomizedView';

const DashboardCustomizedView = () => {
  return (
    <DashboardLayout>
      <CustomizedView />
    </DashboardLayout>
  );
};

export default DashboardCustomizedView;
