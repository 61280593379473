import { Heading, Stack, Box, Text } from '@chakra-ui/react';

const LegalRetention = () => {
  return (
    <Box>
      <Heading>Retention of Your Personal Data</Heading>
      <Stack spacing={5} marginTop="32px">
        <Text>
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </Text>
        <Text>
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </Text>
      </Stack>
    </Box>
  );
};

export default LegalRetention;
