import React from 'react';
import { Center, HStack } from '@chakra-ui/react';
import { FiInfo } from 'react-icons/fi';
import Tooltip from 'components/atoms/Tooltip';

type ChartQuartilesType = {
  name: string;
  quartile: number;
};

const ChartQuartiles = ({ name, quartile }: ChartQuartilesType) => {
  const isAverage = name === 'Average';

  return (
    <HStack justifyContent="center">
      <Center color={!isAverage ? 'text.neutral' : ''}>
        {name} {!isAverage && 'Quartile'}
      </Center>
      <Center>
        {quartile === null && (
          <Tooltip label="No Data Available" icon={FiInfo} />
        )}
      </Center>
    </HStack>
  );
};

export default ChartQuartiles;
