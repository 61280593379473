import React from 'react';
import { Icon } from '@chakra-ui/react';

const Dollar = () => {
  return (
    <Icon
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 0C10.3137 0 0 10.3137 0 23C0 35.6863 10.3137 46 23 46C35.6863 46 46 35.6863 46 23C46 10.3137 35.6863 0 23 0ZM27.4547 31.861L27.3095 32.0063C26.4863 32.7326 25.4695 33.2168 24.3558 33.4589V36.6063H21.0632V33.3621C20.1432 33.12 19.32 32.6842 18.5937 32.1032C17.48 31.1832 16.8021 29.9242 16.6084 28.5684L19.9979 28.0842C20.0463 28.6168 20.3368 29.1011 20.8211 29.4884C22.0316 30.4569 23.9684 30.4568 25.0821 29.44C25.76 28.8105 26.0989 27.939 26.0505 27.0674C26.0021 26.2442 25.6147 25.5179 24.9368 24.9853C24.1137 24.4042 23.3389 24.2105 22.4189 24.0168C21.2084 23.7263 19.8042 23.3874 18.5453 22.1284C17.3347 20.8695 16.7053 19.32 16.7537 17.8189C16.8021 16.4147 17.4316 15.1074 18.5453 14.0905L18.5937 14.0421C18.6421 13.9937 18.6421 13.9937 18.6421 13.9937C19.32 13.4126 20.1916 13.0253 21.0632 12.7347V9.44211H24.3558V12.6863C25.421 12.9284 26.341 13.4611 27.1642 14.139C27.9874 14.8653 28.5684 15.7853 28.8589 16.8021L25.4211 17.3347C25.3242 17.0926 25.1305 16.8989 24.9368 16.7053C23.8232 15.7368 21.9347 15.7369 20.8211 16.6569C20.4821 16.9958 20.1431 17.4316 20.1431 18.0126C20.0947 18.5937 20.4337 19.2232 20.9663 19.8042C21.4989 20.3853 22.08 20.5305 23.1937 20.7726C24.259 21.0147 25.6147 21.3053 26.9705 22.3221C28.4232 23.3874 29.3432 25.0821 29.44 26.9221C29.4884 28.7137 28.8105 30.5053 27.4547 31.861Z"
        fill="#394C75"
      />
    </Icon>
  );
};

export default Dollar;
