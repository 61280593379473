import { Heading, Stack, UnorderedList, ListItem, Box, Text } from "@chakra-ui/react";
import { PERSONAL_DATA_USE, PERSONAL_INFO_SHARE } from "assets/constants/legals";


const LegalUsePersonalData = () => {
  return (
    <Box>
      <Heading>Use of Your Personal Data</Heading>
      <Stack spacing={5} marginTop="32px">
        <Text>
          The Company may use Personal Data for the following purposes:
        </Text>
        <Box>
          <UnorderedList spacing={5}>
            {PERSONAL_DATA_USE.map((use) => (
              <ListItem key={use.id}>
                <Text as="span" fontWeight="bold">
                  {use.title}
                </Text>
                <Text as="span">{use.desc}</Text>
              </ListItem>
            ))}
          </UnorderedList>
        </Box>
        <Text>
          We may share Your personal information in the following situations:
        </Text>
        <Box>
          <UnorderedList spacing={5}>
            {PERSONAL_INFO_SHARE.map((use) => (
              <ListItem key={use.id}>
                <Text as="span" fontWeight="bold">
                  {use.title}
                </Text>
                <Text as="span">{use.desc}</Text>
              </ListItem>
            ))}
          </UnorderedList>
        </Box>
      </Stack>
    </Box>
  );
};

export default LegalUsePersonalData;
