import { Heading, Stack, Box, Text } from '@chakra-ui/react';
import { PRIVACY_LINK_TO_OTHER_SITES } from 'assets/constants/legals';

type LegalLinksSitesType = {
  texts: typeof PRIVACY_LINK_TO_OTHER_SITES;
};

const LegalLinksSites = ({ texts }: LegalLinksSitesType) => {
  return (
    <Box>
      <Heading>Links to Other Websites</Heading>
      <Stack marginTop="32px" spacing={5}>
        {texts.map((text) => {
          return <Text key={text?.id}>{text?.text}</Text>;
        })}
      </Stack>
    </Box>
  );
};

export default LegalLinksSites;
