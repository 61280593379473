import { instance } from 'http/axios';
import { ISelectionHistoryData } from 'interfaces/selection-history';

const SELECTION_HISTORY_ENDPOINT = '/selection-histories/';

export const getSelectionHistoryByPagination = async (
  limit: number,
  page: number
) => {
  try {
    const res = await instance(
      `${SELECTION_HISTORY_ENDPOINT}?query={id, date, merchant, livestock, weight, analysis_period, current_store_price, indicator_schedule,quartiles}&limit=${limit}&page=${page}`
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const addSelectionHistory = async (data: ISelectionHistoryData) => {
  try {
    const res = await instance.post(SELECTION_HISTORY_ENDPOINT, data);
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const getSelectionHistoryById = async (
  id: number,
  analysis_period: string
) => {
  try {
    let query = '';
    if (analysis_period === 'short') {
      query = `${SELECTION_HISTORY_ENDPOINT}${id}/?query={id,analysis_period,merchant,livestock,weight,current_store_price,indicator_schedule,average_store_price_percentage,quartiles}`;
    } else {
      query = `${SELECTION_HISTORY_ENDPOINT}${id}/?query={id,analysis_period,merchant,livestock,weight,five_year_average_schedule, carcass_prices, per_head }`;
    }

    const res = await instance(query);
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const getIndicatorScheduleByDate = async (id: number, date: string) => {
  try {
    const res = await instance(
      `${SELECTION_HISTORY_ENDPOINT}${id}/?query={indicator_schedule}&indicator_schedule_date=${date}`
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const getExpectedStorePricePercentageByDate = async (
  id: number,
  date: string
) => {
  try {
    const res = await instance(
      `${SELECTION_HISTORY_ENDPOINT}${id}/?query{average_store_price_percentage}&average_store_price_percentage_date=${date}`
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const getUpdatedForecastAverageScheduleById = async (
  id: number,
  forecast: number
) => {
  try {
    const query = `${SELECTION_HISTORY_ENDPOINT}${id}/?query={carcass_prices}&forecast_average_schedule=${forecast}`;

    const res = await instance(query);
    return res?.data;
  } catch (error) {
    return error;
  }
};
