import React from 'react';
import { Heading, UnorderedList, ListItem, Box, Text } from '@chakra-ui/react';
import { PRIVACY_DEFINITIONS } from 'assets/constants/legals';

type LegalDescriptionType = {
  definitions: typeof PRIVACY_DEFINITIONS;
  title: string;
};

const LegalDefinitions = ({ definitions, title }: LegalDescriptionType) => {
  return (
    <Box>
      <Heading size="lg">Definitions</Heading>
      <Text marginY="16px">
        For the purposes of {title === 'Privacy Policy' ? 'this' : 'these'}{' '}
        {title}:
      </Text>
      <UnorderedList>
        {definitions.map((definition) => (
          <ListItem marginBottom="16px" key={definition.id}>
            <Text as="span" fontWeight="bold">
              {definition.heading}
            </Text>
            <Text as="span">{definition.desc}</Text>
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  );
};

export default LegalDefinitions;
