type ResetPasswordInput = {
  id: number;
  label?: string;
  type: string;
  placeholder?: string;
  name: 'new_password1' | 'new_password2';
};

export const RESET_PASSWORD_INPUTS: ResetPasswordInput[] = [
  {
    id: 1,
    label: 'New Password',
    type: 'password',
    placeholder: 'Your New Password',
    name: 'new_password1',
  },
  {
    id: 2,
    label: 'Confirm New Password',
    type: 'password',
    placeholder: 'Confirm Your New Password',
    name: 'new_password2',
  },
];

export const RESET_PASSWORD_VALUES = {
  new_password1: '',
  new_password2: '',
};
