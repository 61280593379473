import React from 'react';
import { Tag as ChakraTag, TagLabel } from '@chakra-ui/react';

const Tags = ({ children }: { children: any }) => {
  return (
    <ChakraTag
      color="text.basic"
      fontSize="14px"
      fontWeight="bold"
      size="lg"
      borderRadius="full"
      variant="outline"
      bgColor="bg.primary"
      boxShadow="inset 0 0 0px 1px #EFEFEF"
    >
      <TagLabel>{children}</TagLabel>
    </ChakraTag>
  );
};

export default Tags;
