import { Box } from '@chakra-ui/react';
import React from 'react'

const MaxError = () => { 
  return (
    <Box color="status.red" p="8px 16px" textAlign="center" fontWeight="600">
      max amount of customisations reached
    </Box>
  );
}

export default MaxError