import React from 'react'
import { HStack } from '@chakra-ui/layout';
import { BsFillCheckSquareFill } from 'react-icons/bs';
import { CgClose } from 'react-icons/cg';

type InputButtonsType = {
  onClose: () => void;
  onClick: () => void;
};

const InputButtons = ({ onClose,onClick }: InputButtonsType) => {
  return (
    <HStack padding="10px" spacing="0.5rem">
      <CgClose size="100%" cursor="pointer" onClick={() => onClose()} />
      <BsFillCheckSquareFill
        size="100%"
        color="#263042"
        cursor="pointer"
        onClick={() => onClick()}
      />
    </HStack>
  );
};

export default InputButtons