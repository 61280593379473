import React, { useContext, useReducer } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { NewCustomizationContext } from 'context/newCustomization';
import { EDIT_STEP } from 'enums/customizations';
import useModal from './useModal';
import {
  Customizations,
  CustomizationsData,
  ICustomizationQuartiles,
} from 'interfaces/customizations';
import { getChoicesFromCustomization } from 'utils';
import { INITIAL_EDIT_CUSTOMIZATION_DATA } from 'assets/constants/customizations';
import { updateCustomizations } from 'services/Customizations';
import {
  customizationOptionReducer,
  CUSTOMIZATION_OPTION_INITIAL_STATE,
} from 'reducers/customizationOptionReducer';

const useEditCustomisation = () => {
  const newCustomizationContext = useContext(NewCustomizationContext);
  const { editChoices, isEditCustomization } = newCustomizationContext?.state;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { modalValues, setModalValues, updateModalValues } = useModal();

  const [state, dispatch] = useReducer(
    customizationOptionReducer,
    CUSTOMIZATION_OPTION_INITIAL_STATE
  );

  const initializeChoiceBasedOnModal = (step: number) => {
    newCustomizationContext?.dispatch({
      type: 'UPDATE_CURRENT_CHOICE',
      payload: editChoices[step],
    });
  };

  const removeEditChoiceItems = (itemsToRemove: number) => {
    return editChoices.filter(
      (value: string, i: number) => i + itemsToRemove < editChoices.length
    );
  };

  const updateInitialLivestockChoices = (step: number) => {
    let updatedEditChoices: string[] = [];
    if (step === EDIT_STEP.STOCKS) {
      // initializeChoiceBasedOnModal(step);
      updatedEditChoices = removeEditChoiceItems(2);
    } else if (step === EDIT_STEP.STOCK_TYPE) {
      // initializeChoiceBasedOnModal(step);
      updatedEditChoices = removeEditChoiceItems(1);
    } else {
      updatedEditChoices = editChoices;
    }

    return updatedEditChoices;
  };

  const handleEditCustomization = (step: number) => {
    // Validation where if step is for livestock then set first modal value
    if (
      [EDIT_STEP.STOCKS, EDIT_STEP.STOCK_TYPE, EDIT_STEP.AGE_CLASS].includes(
        step
      )
    ) {
      // Reconstruct editChoices breadcrumbs depending on the step
      newCustomizationContext?.dispatch({
        type: 'UPDATE_EDIT_CHOICES_BY_STEP',
        payload: { choices: updateInitialLivestockChoices(step), step: step },
      });
    }

    if (
      [
        EDIT_STEP.ROLE,
        // EDIT_STEP.LOCATION NOTE: Commented out for temporary removal of location
      ].includes(step)
    ) {
      initializeChoiceBasedOnModal(step);
    }

    setModalValues((prevValues) => ({
      ...prevValues,
      modalSize: '4xl',
      returnBtnText: 'Back',
      steps: step,
    }));
    onOpen();
  };

  const populateEditCustomizationContext = (
    customizationContext: CustomizationsData
  ) => {
    const {
      name,
      analysis_period,
      merchant,
      // location, NOTE: Commented out for temporary removal of location
      weight,
    } = customizationContext;
    const stock = customizationContext?.livestock?.livestock_type?.category;
    const stockType = customizationContext?.livestock?.livestock_type;
    const ageClass = customizationContext?.livestock;
    newCustomizationContext?.dispatch({
      type: 'UPDATE_EDIT_CUSTOMIZATION',
      payload: {
        name: name,
        analysis_period: analysis_period,
        merchant: merchant,
        // location: location, NOTE: Commented out for temporary removal of location
        category: {
          id: stock.id,
          name: stock.name,
        },
        livestockType: {
          id: stockType.id,
          name: stockType.name,
        },
        ageClass: {
          id: ageClass.id,
          name: ageClass.name,
        },
        weight: weight,
      },
    });
  };

  const populateCustomizationContext = (
    customizationContext: CustomizationsData
  ) => {
    const {
      name,
      analysis_period,
      merchant,
      // location, NOTE: Commented out for temporary removal of location
      weight,
    } = customizationContext;
    const stock = customizationContext?.livestock?.livestock_type?.category;
    const stockType = customizationContext?.livestock?.livestock_type;
    const ageClass = customizationContext?.livestock;
    newCustomizationContext?.dispatch({
      type: 'UPDATE_CUSTOMIZATION',
      payload: {
        name: name,
        analysis_period: analysis_period,
        merchant: merchant,
        // location: location, NOTE: Commented out for temporary removal of location
        category: {
          id: stock.id,
          name: stock.name,
        },
        livestockType: {
          id: stockType.id,
          name: stockType.name,
        },
        ageClass: {
          id: ageClass.id,
          name: ageClass.name,
        },
        weight: weight,
      },
    });
  };

  const editCustomization = (
    editValues: CustomizationsData,
    customizationValues: CustomizationsData
  ) => {
    const customizationChoices =
      getChoicesFromCustomization(customizationValues);
    const editChoices = getChoicesFromCustomization(editValues);

    newCustomizationContext?.dispatch({
      type: 'UPDATE_EDIT_CUSTOMIZATION_STATUS',
      payload: { mode: !isEditCustomization, customisationId: editValues?.id },
    });

    // Populate context variables to prepare for editing
    populateCustomizationContext(customizationValues);
    populateEditCustomizationContext(editValues);

    // Populate the choices and editChoices array to prepare for editing
    newCustomizationContext?.dispatch({
      type: 'ADD_ORIGINAL_CHOICES_ARRAY',
      payload: customizationChoices,
    });
    newCustomizationContext?.dispatch({
      type: 'ADD_EDIT_CHOICES',
      payload: editChoices,
    });
  };

  const cancelEditCustomization = (
    customizationValues: CustomizationsData,
    resetEditValues: React.Dispatch<React.SetStateAction<CustomizationsData>>
  ) => {
    const customizationChoices =
      getChoicesFromCustomization(customizationValues);
    newCustomizationContext?.dispatch({
      type: 'UPDATE_EDIT_CUSTOMIZATION_STATUS',
      payload: { mode: !isEditCustomization, customisationId: 0 },
    });

    resetEditValues(customizationValues); // reset the editValues back to original

    // Reset customization back to initial values for preparation if a user wants to add new customization
    populateCustomizationContext(INITIAL_EDIT_CUSTOMIZATION_DATA);
    // Reset editCustomization back to original values
    populateEditCustomizationContext(customizationValues);

    // Clear choices in preparation incase the user will add new customization
    newCustomizationContext?.dispatch({
      type: 'CLEAR_CHOICES',
    });
    // Reset edit choices back to original
    newCustomizationContext?.dispatch({
      type: 'ADD_EDIT_CHOICES',
      payload: customizationChoices,
    });
  };

  const handleUpdateCustomization = async (
    editCustomizations: Customizations,
    id: number,
    resetEditValues: React.Dispatch<React.SetStateAction<CustomizationsData>>,
    updateCustomizationValues: React.Dispatch<
      React.SetStateAction<ICustomizationQuartiles>
    >
  ) => {
    try {
      const updatedCustomization = await updateCustomizations(
        editCustomizations,
        id
      );

      const categoryId =
        updatedCustomization?.livestock?.livestock_type?.category?.id;
      const categoryName =
        updatedCustomization?.livestock?.livestock_type?.category?.name;
      const livestockTypeName =
        updatedCustomization?.livestock?.livestock_type?.name;
      const ageClass = updatedCustomization?.livestock?.name;
      const livestockTypeId =
        updatedCustomization?.livestock?.livestock_type?.id;

      // Update both after saving
      newCustomizationContext?.dispatch({
        type: 'UPDATE_EDIT_STOCK',
        payload: {
          livestock_type: livestockTypeId,
          category: categoryId,
          livestock_type_name: livestockTypeName,
          category_name: categoryName,
          livestock_name: ageClass,
        },
      });

      updateCustomizationValues(updatedCustomization);
      resetEditValues(updatedCustomization);
      // Reset customization back to initial values for preparation if a user wants to add new customization
      populateCustomizationContext(INITIAL_EDIT_CUSTOMIZATION_DATA);
      // Set editCustomization to the updated customization data
      populateEditCustomizationContext(updatedCustomization);

      // Clear choices array after saving
      newCustomizationContext?.dispatch({
        type: 'CLEAR_CHOICES',
      });

      newCustomizationContext?.dispatch({
        type: 'UPDATE_EDIT_CUSTOMIZATION_MODE',
        payload: !isEditCustomization,
      });
    } catch (error) {
      return error;
    }
  };

  return {
    isOpen,
    onOpen,
    onClose,
    modalValues,
    setModalValues,
    updateModalValues,
    handleEditCustomization,
    editCustomization,
    cancelEditCustomization,
    handleUpdateCustomization,
  };
};

export default useEditCustomisation;
