import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import {
  Checkbox,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  InputRightElement,
  FormErrorMessage,
  Flex,
} from '@chakra-ui/react';
import styles from './styles.module.css';

import { LOGIN_DEFAULT_VALUES, LOGIN_INPUTS } from 'assets/constants/login';
import { AuthContext } from 'context/auth';
import { loginSchema } from 'schema';
import { errorMessageChecker } from 'utils';

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<typeof LOGIN_DEFAULT_VALUES>({
    mode: 'onTouched',
    defaultValues: LOGIN_DEFAULT_VALUES,
    resolver: yupResolver(loginSchema),
    reValidateMode: 'onSubmit',
  });

  // Toggle show password
  const handleShowPassword = () => setShowPassword(!showPassword);

  // Checkbox Handler
  const handleCheckbox = () => setIsChecked(!isChecked);

  // Form Submit Handler
  const onSubmit: SubmitHandler<typeof LOGIN_DEFAULT_VALUES> = (data) => {
    authContext?.onLogin(data, navigate, isChecked);
  };

  return (
    <form className={styles.login_form} onSubmit={handleSubmit(onSubmit)}>
      {LOGIN_INPUTS.map((item) => (
        <FormControl
          key={item.id}
          isInvalid={
            errorMessageChecker(item.name, errors)?.title !== undefined
          }
          mb="1rem"
        >
          <FormLabel fontSize="12px" color="text.neutral" htmlFor={item.name}>
            {item.label}
          </FormLabel>
          {item.type === 'password' ? (
            <InputGroup>
              <Input
                {...register(item.name)}
                type={showPassword ? 'text' : item.type}
                placeholder={item.placeholder}
              />
              <InputRightElement width="4.5rem">
                <Button
                  h="1.75rem"
                  size="sm"
                  onClick={handleShowPassword}
                  background="transparent"
                  color="#80818B"
                >
                  {showPassword ? <FiEyeOff /> : <FiEye />}
                </Button>
              </InputRightElement>
            </InputGroup>
          ) : (
            <Input
              {...register(item.name)}
              type={item.type}
              placeholder={item.placeholder}
            />
          )}
          {errorMessageChecker(item.name, errors)?.title !== undefined && (
            <FormErrorMessage>
              {errorMessageChecker(item.name, errors)?.msg}
            </FormErrorMessage>
          )}
        </FormControl>
      ))}
      <Flex fontSize="14px" justifyContent="space-between">
        <Checkbox size="sm" isChecked={isChecked} onChange={handleCheckbox}>
          Stay signed in
        </Checkbox>
        <Link to="/forgot-password">Forgot Password</Link>
      </Flex>
      <Button
        type="submit"
        variant="solid"
        colorScheme="teal"
        size="md"
        w="100%"
        mt="40px"
        isLoading={authContext?.isLoading}
      >
        Login
      </Button>
    </form>
  );
};

export default LoginForm;
