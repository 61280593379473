import { Heading, Stack, Box, Text } from '@chakra-ui/react';

const LegalTransferData = () => {
  return (
    <Box>
      <Heading>Transfer of Your Personal Data</Heading>
      <Stack spacing={5} marginTop="32px">
        <Text>
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction.
        </Text>
        <Text>
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </Text>
        <Text>
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </Text>
      </Stack>
    </Box>
  );
};

export default LegalTransferData;
