import { CustomizationsData } from 'interfaces/customizations';
import { CustomizationOptionActionTypes } from 'utils/types';

export const CUSTOMIZATION_OPTION_INITIAL_STATE = {
  isLoading: false,
  userCustomizations: [],
  choice: '',
};

export const customizationOptionReducer = (
  state: any,
  action: CustomizationOptionActionTypes
) => {
  const updateCustomisation = (customisation: CustomizationsData) => {
    const updatedCustomisations = state?.userCustomizations.map(
      (prevCustomisation: CustomizationsData) =>
        prevCustomisation.id === customisation.id
          ? customisation
          : prevCustomisation
    );
    return updatedCustomisations;
  };

  switch (action.type) {
    case 'GET_CUSTOMIZATIONS':
      return { ...state, isLoading: true, userCustomizations: action.payload };
    case 'UPDATE_CUSTOMIZATIONS':
      return {
        ...state,
        userCustomizations: updateCustomisation(action.payload),
      };
    case 'SET_CHOICE':
      return { ...state, choice: action.payload };
    case 'STOP_LOADING':
      return { ...state, isLoading: false };
    case 'NEXT':
      return { ...state, isLoading: true };
    case 'BACK':
      return state;
  }
};
