import React from 'react';
import {
  Alert as ChakraAlert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';

type AlertPropType = {
  children: React.ReactNode | null;
  title?: string | null;
  status: 'error' | 'success' | 'warning' | 'info';
  variant?: 'subtle' | 'solid' | 'left-accent' | 'top-accent';
};

const Alert = ({ children, title, status, variant }: AlertPropType) => {
  return (
    <ChakraAlert status={status} variant={variant}>
      <AlertIcon />
      {title && <AlertTitle>{title}</AlertTitle>}
      <AlertDescription>{children}</AlertDescription>
    </ChakraAlert>
  );
};

export default Alert;
