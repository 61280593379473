import React, { createContext, useReducer } from 'react';
import { selectionHistoryReducer, SELECTION_HISTORY_INITIAL_STATE } from 'reducers/selectionHistoryReducer';
import { SelectionHistoryActionTypes } from 'utils/types';
import { ISelectionHistoryContext } from 'interfaces/selection-history';

export type SelectionHistoryContextTypes = {
  state: any; // Typescript using interface
  dispatch: React.Dispatch<SelectionHistoryActionTypes>;
};

export const SelectionHistoryContext =
  createContext<SelectionHistoryContextTypes | null>(null);

const SelectionHistoryProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(
    selectionHistoryReducer,
    SELECTION_HISTORY_INITIAL_STATE
  );

  return (
    <SelectionHistoryContext.Provider value={{ state, dispatch }}>
      {children}
    </SelectionHistoryContext.Provider>
  );
};

export default SelectionHistoryProvider;
