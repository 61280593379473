import React, { useContext, useEffect, useState } from 'react';
import { format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Box,
  Flex,
  Container,
  Center,
  Button,
  Heading,
  Text,
  HStack,
  Card,
  useBoolean,
  Portal,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import {
  getCentsPerKilo,
  getExpectedStorePrice,
  getPerHead,
  getSelectedLivestock,
  processAgeClassDisplayText,
  renderModalContent,
} from 'utils';
import { NewCustomizationContext } from 'context/newCustomization';
import { SelectionHistoryContext } from 'context/selectionHistoryContext';
import { useNavigate } from 'react-router';
import { createCustomizations } from 'services/Customizations';
import {
  getIndicatorScheduleByDate,
  getExpectedStorePricePercentageByDate,
} from 'services/SelectionHistory';
import InputButtons from '../InputButtons';
import useEditCustomisation from 'hooks/useEditCustomisation';
import { CardTitleType } from 'interfaces/dashboard';
import { CustomizationsData } from 'interfaces/customizations';
import { QUERY_DATE_FORMAT } from 'assets/constants/dashboard';
import { CHART_MARKS } from 'assets/constants/dashboard';
import { DEFAULT_NAME } from 'assets/constants/selection-history';
import Charts from 'components/molecules/Charts';
import DatePickerModal from 'components/Modal/DatePickerModal';
import CardContent from 'components/molecules/CardContent';
import ChartQuartiles from 'components/molecules/Charts/ChartQuartiles';
import ChartMarks from 'components/molecules/Charts/ChartMarks';
import EditTags from 'components/molecules/EditTags';
import NewCalculationModal from 'components/Modal/NewCalculationModal';
import ModalContentDisplay from 'components/ModalContentDisplay';
import MaxError from 'components/molecules/MaxError';

const placeholderStyle = {
  color: '#263042',
  fontWeight: 600,
  fontSize: '32px',
  lineHeight: '150%',
};

const AgFirstDefaultView = () => {
  const navigate = useNavigate();
  const selectionHistoryContext = useContext(SelectionHistoryContext);
  const newCustomizationContext = useContext(NewCustomizationContext);
  const {
    todaysDate,
    isCustomisationMax,
    isEditCustomization,
    editCustomizations,
    editStock,
  } = newCustomizationContext?.state;
  const { selectionHistory } = selectionHistoryContext?.state;
  const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useBoolean();
  const [isCustomizationName, setIsCustomizationName] = useBoolean();
  const [isLoading, setIsLoading] = useBoolean();
  const [isHover, setIsHover] = useBoolean();

  const [customisationName, setCustomisationName] = useState(DEFAULT_NAME);
  const [editCustomisationName, setEditCustomisationName] =
    useState(DEFAULT_NAME);
  const [editHistoryCustomisation, setEditHistoryCustomisation] =
    useState<CustomizationsData>(selectionHistory);
  const [newIndicatorScheduleByDate, setNewIndicatorScheduleByDate] = useState<
    number | undefined
  >(undefined);

  const {
    isOpen,
    onClose,
    modalValues,
    updateModalValues,
    handleEditCustomization,
    editCustomization,
    cancelEditCustomization,
  } = useEditCustomisation();

  const stockType = selectionHistory?.livestock?.livestock_type;
  const ageClass = selectionHistory?.livestock;

  const {
    merchant,
    weight,
    indicator_schedule,
    current_store_price,
    average_store_price_percentage,
    id,
  } = selectionHistory;
  const isQuartilesEmpty =
    selectionHistory?.quartiles &&
    Object.keys(selectionHistory?.quartiles).length === 0 &&
    Object.getPrototypeOf(selectionHistory?.quartiles) === Object.prototype;

  const { upper, lower } = selectionHistory?.quartiles;

  const expectedStorePrice = getExpectedStorePrice(
    indicator_schedule,
    average_store_price_percentage
  );
  const priceVariance = getPerHead(
    expectedStorePrice - current_store_price,
    weight
  );

  const CARD_TITLES: CardTitleType[] = [
    {
      id: 'indicatorSchedule',
      name: 'Indicator schedule',
      value: newIndicatorScheduleByDate
        ? getCentsPerKilo(newIndicatorScheduleByDate)
        : getCentsPerKilo(indicator_schedule),
    },
    {
      id: 'currentStorePrice',
      name: 'Current store price',
      value: getCentsPerKilo(current_store_price),
    },
    {
      id: 'expectedPrice',
      name: 'Long term average store price',
      value: getCentsPerKilo(expectedStorePrice),
    },
    // NOTE: Commented because might return this in the future
    // {
    //   id: 'indicatorScheduleHead',
    //   name: 'Indicator schedule',
    //   value: getPerHead(
    //     newIndicatorScheduleByDate
    //       ? newIndicatorScheduleByDate
    //       : indicator_schedule,
    //     weight
    //   ),
    // },
    {
      id: 'currentStorePriceHead',
      name: 'Current store price',
      value: getPerHead(current_store_price, weight),
    },
    {
      id: 'priceVariance',
      name: 'Price variance',
      value: priceVariance,
    },
  ];

  const QUARTILE_VALUES = [
    { name: 'Lower', quartile: lower },
    {
      name: 'Average',
      quartile: average_store_price_percentage,
    },
    {
      name: 'Upper',
      quartile: upper,
    },
  ];

  const formattedAgeClass = processAgeClassDisplayText(
    ageClass?.name,
    stockType?.name
  );

  const handleSaveEditSelectionHistory = () => {
    const selectedLivestock = getSelectedLivestock(
      editStock,
      editCustomizations?.livestock
    );

    setEditHistoryCustomisation((prevHistory) => {
      return {
        ...prevHistory,
        merchant: editCustomizations?.merchant,
        // location: editCustomizations?.location, NOTE: Commented out for temporary removal of location
        livestock: selectedLivestock,
        weight: editCustomizations?.weight,
      };
    });
    selectionHistoryContext?.dispatch({
      type: 'UPDATE_SELECTION_HISTORY',
      payload: {
        ...selectionHistory,
        name: customisationName,
        merchant: editCustomizations?.merchant,
        // location: editCustomizations?.location, NOTE: Commented out for temporary removal of location
        livestock: selectedLivestock,
        weight: editCustomizations?.weight,
      },
    });

    newCustomizationContext?.dispatch({
      type: 'UPDATE_EDIT_CUSTOMIZATION_MODE',
      payload: !isEditCustomization,
    });
  };

  const handleOpenDatePickerModal: React.MouseEventHandler<
    HTMLParagraphElement | HTMLSpanElement
  > = (event) => {
    const editDateIndicator = event?.currentTarget?.id;
    newCustomizationContext?.dispatch({
      type: 'UPDATE_CURRENT_EDIT_DATE',
      payload: editDateIndicator,
    });
    setIsDatePickerModalOpen.on();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEditCustomisationName(event.target.value);

  const handleConfirmName = () => {
    selectionHistoryContext?.dispatch({
      type: 'UPDATE_SELECTION_HISTORY_NAME',
      payload: editCustomisationName,
    });
    newCustomizationContext?.dispatch({
      type: 'EDIT_NAME',
      payload: customisationName,
    });
    setCustomisationName(editCustomisationName);
    setIsCustomizationName.off();
  };

  const handleAddCustomisation = async () => {
    try {
      setIsLoading.on();
      const customizationData = editCustomizations;
      await createCustomizations(customizationData);
      setIsLoading.off();
      navigate('/dashboard/');
    } catch (error) {
      setIsLoading.off();
      return error;
    }
  };

  const handleCloseInput = () => {
    //Reset edit customisation state to confirmed name
    setEditCustomisationName(customisationName);
    setIsCustomizationName.off();
  };

  const getNewIndicatorScheduleValue = async (
    newIndicatorScheduleDate: Date
  ) => {
    const date = format(new Date(newIndicatorScheduleDate), QUERY_DATE_FORMAT);
    const indicatorScheduleData = await getIndicatorScheduleByDate(id, date);
    setNewIndicatorScheduleByDate(indicatorScheduleData?.indicator_schedule); // TODO: Refactor code to use context
  };

  const getNewExpectedStorePriceValue = async (newExpectedPriceDate: Date) => {
    const date = format(new Date(newExpectedPriceDate), QUERY_DATE_FORMAT);
    const expectedPricePercentageData =
      await getExpectedStorePricePercentageByDate(id, date);
    selectionHistoryContext?.dispatch({
      type: 'UPDATE_EXPECTED_PRICE_PERCENTAGE',
      payload: expectedPricePercentageData?.average_store_price_percentage,
    });
  };

  const displayViewCards = () => {
    return CARD_TITLES.map((title: CardTitleType, index: number) => {
      return (
        <Card flex="1 0 25%" key={title?.id}>
          <CardContent
            title={title}
            index={index}
            handleOpenDatePickerModal={handleOpenDatePickerModal}
          />
        </Card>
      );
    });
  };

  const handleOnKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleConfirmName();
    }

    if (event.key === 'Escape') {
      handleCloseInput();
    }
  };

  useEffect(() => {
    newCustomizationContext?.dispatch({
      type: 'EDIT_NAME',
      payload: customisationName,
    });
  }, [customisationName]);

  return (
    <>
      {/*Dashboard Heading*/}
      <Box padding="32px 45px 16px 45px">
        <Flex justifyContent="space-between" alignItems="center">
          {!isCustomizationName && (
            <Heading onClick={() => setIsCustomizationName.on()}>
              {customisationName}
            </Heading>
          )}
          {isCustomizationName && (
            <InputGroup>
              <Input
                onKeyDown={handleOnKeyDown}
                autoFocus={true}
                style={{ ...placeholderStyle }}
                placeholder={customisationName}
                variant="flushed"
                _placeholder={{ ...placeholderStyle }}
                onChange={handleChange}
                value={editCustomisationName}
              />
              <InputRightElement
                width="100px"
                children={
                  <InputButtons
                    onClose={handleCloseInput}
                    onClick={handleConfirmName}
                  />
                }
              />
            </InputGroup>
          )}
          <HStack>
            {isHover && isCustomisationMax && <MaxError />}
            <Button
              data-testid="new-calculation-button"
              colorScheme="teal"
              size="lg"
              marginLeft="60px"
              onClick={handleAddCustomisation}
              disabled={isCustomisationMax}
              isLoading={isLoading}
              onPointerEnter={() => setIsHover.on()}
              onPointerLeave={() => setIsHover.off()}
            >
              Save changes
            </Button>
          </HStack>
        </Flex>
      </Box>
      <Box padding="0px 45px 16px 45px">
        <Flex
          alignItems="center"
          paddingBottom="22px"
          justifyContent="space-between"
        >
          <Flex alignItems="center" gap={4}>
            <Text fontSize="12px">Today’s date</Text>
            <Text
              color="teal"
              fontWeight="500"
              cursor="pointer"
              onClick={(e) => handleOpenDatePickerModal(e)}
            >
              {todaysDate}
            </Text>
          </Flex>
          <EditTags
            customisation={selectionHistory}
            editValues={editHistoryCustomisation}
            handleEditCustomization={handleEditCustomization}
          />
          <Box>
            {isEditCustomization && (
              <Button
                colorScheme="teal"
                variant="ghost"
                size="lg"
                fontWeight="700"
                onClick={() => handleSaveEditSelectionHistory()}
              >
                Save
              </Button>
            )}
            {isEditCustomization ? (
              <Button
                name="cancel"
                colorScheme="gray"
                variant="ghost"
                size="lg"
                fontWeight="700"
                onClick={() =>
                  cancelEditCustomization(
                    selectionHistory,
                    setEditHistoryCustomisation
                  )
                }
              >
                Cancel
              </Button>
            ) : (
              <Button
                bgColor="#394C75"
                variant="solid"
                size="lg"
                fontWeight="700"
                borderRadius="12px"
                color="#FFF"
                gap="2px"
                onClick={() =>
                  editCustomization(selectionHistory, selectionHistory)
                }
              >
                Customize
              </Button>
            )}
          </Box>
        </Flex>
      </Box>
      {/* Card Container */}
      <Flex
        flexWrap="wrap"
        padding="0px 45px 26px 45px"
        justifyContent="space-between"
        gap="16px"
      >
        {displayViewCards()}
      </Flex>
      {/* Chart Container */}
      <Flex bgColor="#F8F9F9" padding="26px 45px" gap="1rem">
        <Box flex=" 1" bgColor="#FFF" padding="40px 24px">
          <Container maxW="100%" marginRight={0}>
            {isQuartilesEmpty ? (
              <Center p="32px">
                <Text fontSize="4xl">No Data Available</Text>
              </Center>
            ) : (
              <>
                <HStack>
                  <Text fontWeight="600">
                    Store price relative to current schedule
                  </Text>
                  <Text color="#6B6C75">
                    {`${formattedAgeClass} store ${stockType?.name} price`}
                  </Text>
                </HStack>
                <Charts
                  height={400}
                  role={merchant}
                  quartile={QUARTILE_VALUES}
                  indicatorSchedule={indicator_schedule}
                />
                <Flex
                  position="relative"
                  display="grid"
                  gridTemplateColumns="25% 10% 25%"
                  justifyContent="center"
                  gap="5px"
                  fontSize="12px"
                >
                  {QUARTILE_VALUES.map((quartileValue) => (
                    <ChartQuartiles
                      key={quartileValue?.name}
                      name={quartileValue?.name}
                      quartile={quartileValue?.quartile}
                    />
                  ))}
                </Flex>
              </>
            )}
          </Container>
        </Box>
      </Flex>
      <Portal>
        <DatePickerModal
          openModal={isDatePickerModalOpen}
          closeModal={() => setIsDatePickerModalOpen.off()}
          handleGetIndicatorSchedule={getNewIndicatorScheduleValue}
          handleGetExpectedStorePrice={getNewExpectedStorePriceValue}
        />
      </Portal>
      <NewCalculationModal
        openModal={isOpen}
        closeModal={onClose}
        modalValues={modalValues}
        updateModalValues={updateModalValues}
      >
        <ModalContentDisplay
          steps={modalValues.steps}
          renderModalContent={renderModalContent}
        />
      </NewCalculationModal>
    </>
  );
};

export default AgFirstDefaultView;
