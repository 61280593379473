import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { DashboardLayoutProps } from 'interfaces/dashboard';
import { useProtectedRoute } from 'hooks/useProtectedRoute';
import LegalSidebar from '../LegalSidebar';

const LegalsLayout = ({ children }: DashboardLayoutProps) => {
  return (
    <>
      <Flex h="100vh" maxH="100vh">
        <LegalSidebar />
        <Box flex="1" h="100vh" overflow="auto">
          {children}
        </Box>
      </Flex>
    </>
  );
};

export default LegalsLayout;
